import { getField, updateField } from 'vuex-map-fields'
import { doFilterItems } from '@/services/search.service'
import { criteoForProductList } from "@/services/util.service";
import Fuse from 'fuse.js'
export const catalogBrowser = {
    namespaced: true,
    state: {
        inProgress: true,
        fuseWithIndex: null,
        categoryMap: {},
        productMap: {},
        searchTerms: {
            keywords: '',
            pageNumber: 1,
            pageSize: 9,
            sortBy: 'relevance',
            filters: []
        },
        searchResults: { page: [] },
        enabledFilters: [],
        availalableFilters: [],
        totalItems: 0,
    },
    getters: {
        getField
    },
    mutations: {
        updateField,
        inProgress(state, yesOrNo) {
            state.inProgress = yesOrNo
        },
        setCategoryMapList(state, map) {
            state.categoryMap = map
        },
        setProductMap(state, map) {
            state.productMap = map
        },
        setSearchTerms(state, terms) {
            state.searchTerms = Object.assign(state.searchTerms, terms);
        },
        setEnabledFilters(state, list) {
            state.enabledFilters = list
        },
        setAvailableFilters(state, list) {
            list.filter(attribute => {
                if (attribute.counts.length) {
                    attribute.counts.sort((a, b) => {
                        let fa = a.value.toLowerCase(),
                            fb = b.value.toLowerCase();
                        if (fa < fb) {
                            return -1;
                        }
                        if (fa > fb) {
                            return 1;
                        }
                        return 0;
                    });
                    return attribute;
                }
            });
            state.availalableFilters = list;
        },
        setSearchResults(state, results) {
            state.searchResults.page = [];
            state.searchResults.page = results;
            criteoForProductList(results);
        },
        setFuseWithIndex(state, index) {
            state.fuseWithIndex = index
        },
        setTotalItems(state, totalItems) {
            state.totalItems = totalItems
        },
        setNewFilter(state, newFilterObj) {
            let filtertoNormal = state.searchTerms.filters;
            if (filtertoNormal.length) {
                let flag = true;
                for (let i = 0; i < filtertoNormal.length; i++) {
                    if (filtertoNormal[i].prop == newFilterObj.prop) {
                        filtertoNormal[i].value = newFilterObj.value;
                        flag = false;
                        break;
                    } else {
                        flag = true;
                    }
                }
                if (flag) {
                    filtertoNormal.push(newFilterObj);
                }
            } else {
                filtertoNormal.push(newFilterObj);
            }
            state.searchTerms.filters = filtertoNormal;
        },
        deleteSelectedFilter(state, index) {
            state.searchTerms.filters.splice(index, 1);
        },
        clearCurrentFilter(state) {
            let categoryObj = {};
            let filters = state.searchTerms.filters;
            if (filters.length) {
                for (let i = 0; i < filters.length; i++) {
                    if ((filters[i].label).toLowerCase() == "category") {
                        categoryObj = filters[i];
                        break;
                    }
                }
                filters = [];
                if (Object.keys(categoryObj).length !== 0) {
                    filters.push(categoryObj);
                }
            }
            state.searchTerms.filters = filters;
        },
    },
    actions: {
        index: ({ commit }, { productsCatalog }) => {
            const options = {
                keys: ['n', 'sku'],
                useExtendedSearch: true,
                includeScore: true,
                includeMatches: true,
                threshold: 0.2,
                ignoreLocation: true,
                ignoreFieldNorm: true,
                findAllMatches: true
            }
            const productsArray = []
            for (const [, value] of Object.entries(productsCatalog)) {
                productsArray.push(value)
            }
            const fuse = new Fuse(productsArray, options)
            commit('setFuseWithIndex', fuse)
        },
        search: ({ commit, state }, { fullCatalog, keywords, filters, pageNumber, pageSize, sortBy }) => {

            commit('inProgress', true)
            console.log('searching:', pageNumber)
            console.log('pageSize:', pageSize)
            commit('setEnabledFilters', filters)

            const products = []
            if (filters !== undefined && filters.length) {
                const categoryFilter = filters.find((f => f.prop == 'ci'))
                if (categoryFilter !== undefined) {
                    console.log('filtering by category')
                    const category = fullCatalog.allCategoryDetails.find(c => c.id == categoryFilter.value)
                    if (category) {
                        for (var pi = 0; pi < category.p.length; pi++) {
                            const pid = category.p[pi]
                            const product = fullCatalog.products[pid]
                            if (product !== undefined) {
                                products.push(product)
                            }
                        }
                    }
                }
            }
            if (keywords !== null && keywords != '') {
                keywords = keywords.replaceAll("-", " ");
                keywords = keywords.replaceAll("/", " ");
                let searchWords = keywords.replaceAll(' ', '|')
                //   keywords = keywords.replaceAll(' ', '|')
                products.length = 0
                const relevantResults = state.fuseWithIndex.search(searchWords)
                for (var i = 0; i < relevantResults.length; i++) {
                    if (relevantResults[i].score < 0.4) {
                        var product = fullCatalog.products[relevantResults[i].item.id]
                        products.push(product)
                    }
                }
            }
            /*  else {
                  if (products.length < 1) {
                      for (const [, product] of Object.entries(fullCatalog.products)) {
                          products.push(product)
                      }
                  }
              }*/
            const availableFilters = [];
            let filteredItems = [];
            let pageData = [];
            if (products.length > 0) {
                filteredItems = doFilterItems({
                    items: products, enabledFilters: state.enabledFilters,
                    availableFilters, attributesMap: window.attributesMap
                })

                switch (sortBy) {
                    case 'name':
                        filteredItems.sort((a, b) => {
                            if (a.n < b.n) return -1
                            else if (a.n > b.n) return 1
                            else return 0
                        })
                        break
                    case 'price':
                        filteredItems.sort((a, b) => {
                            if (a.p < b.p) return -1
                            else if (a.p > b.p) return 1
                            else return 0
                        })
                        break
                    case 'color':
                        filteredItems.sort((a, b) => {
                            if (a.dc < b.dc) return -1
                            else if (a.dc > b.dc) return 1
                            else return 0
                        })
                        break
                }
                if (pageSize != 'ALL') {
                    pageSize = Number(pageSize)
                    pageData = filteredItems.slice((pageNumber - 1) * pageSize, pageNumber * pageSize)
                }
                else {
                    pageData = filteredItems;
                }
            }
            commit('setSearchResults', pageData);
            commit('setAvailableFilters', availableFilters);
            commit('setSearchTerms', { keywords: keywords, pageNumber: pageNumber, pageSize: pageSize, sortBy: sortBy, filters: filters }
            );
            commit('setTotalItems', filteredItems.length);//Total Product
            commit('inProgress', false);
        },

        deleteSelectedFilter: async ({ commit }, { index }) => {
            await commit('deleteSelectedFilter', index);
        },
        setNewFilter: async ({ commit }, newFilter) => {
            await commit('setNewFilter', newFilter);

        },
        clearFilter: async ({ commit }) => {
            await commit('clearCurrentFilter');

        },
    },
}