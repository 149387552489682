import { doFetchBasicInfo,  doFetchEstimatedShippingMethods, doShippingInformation, doFetchCheckEmailAvailable, doProductsOrder, doProductsOrderOnCC } from '@/services/guest.service';
import { doFetchQuote } from '@/services/guest_cart.service';
import { getCookieByName, setCookieByName } from '@/services/auth';
import { invalidPoBoxAddress,listrakEmailCapture, snakeToCamel } from '@/services/util.service';
import { getField, updateField } from 'vuex-map-fields';
const crGuestQuoteId = import.meta.env.VITE_GUEST_QUOTE_ID;

const addresses = {
    "regionId": "",
    "region": [],
    "countryId": "",
    "postcode": "",
    "city": "",
    "telephone": "",
    "firstname": "",
    "lastname": "", 
}
const defaultAddress = {
    "defaultShipping": 0,
    "defaultBilling": 0
}
const streetNew = {
    "streetNoOne": "",
    "streetNoTwo": "",
}
export const guestInfo = {
    namespaced: true,
    state: {
        one: {},
        address: {},
        oneAddress: {},
        shippingMethods: null,
        estimatedShipping: [],
        shippingInformation: null, 
        inProgress: false,
        fetchInProgress: false,
        checkEmail: false,
        emailCheckInProgress: false,
        paymentInProgress: false,
        guestQuoteId: getCookieByName(crGuestQuoteId),
        errorMessage: null,
        shippingInformationsInProgress: false,
        addressContainPOBox: null,
        suggestedAddress: {}
    },
    getters: {
        getField,
    },
    mutations: {
        updateField,
        setOne(state, one) {
            state.one = Object.assign({}, state.one, defaultAddress, one);
            state.one.defaultShipping = Number(state.one.defaultShipping);
            state.one.defaultBilling = Number(state.one.defaultBilling);
        },
        setAddress(state, address) {
            if (address) {
                state.address = Object.assign({}, state.address, address, streetNew);
                state.address.streetNoOne = address.street[0];
                state.address.streetNoTwo = address.street[1];
                state.address.countryId= state.address.countryId ? state.address.countryId :"US" ;
            } else {
                state.address = Object.assign({}, state.address, addresses, streetNew);
                state.address.firstname = state.one.firstname;
                state.address.lastname = state.one.lastname;
                state.address.countryId= state.address.countryId ? state.address.countryId :"US" ;
            }
        },
        setSuggestedAddress(state, addressNew) {
            state.suggestedAddress = Object.assign({}, state.suggestedAddress, addressNew, streetNew);
            state.suggestedAddress.streetNoOne = addressNew.street[0];
            state.suggestedAddress.streetNoTwo = addressNew.street[1];
        },
        setEstimatedShipping(state, shipping) {
            state.estimatedShipping = shipping;
        },
        resetEstimatedShippingValue(state) {
            state.estimatedShipping = [];
        },
        
        inProgress(state, yesOrNo) {
            state.inProgress = yesOrNo
        },
        shippingInformationsInProgress(state, yesOrNo) {
            state.shippingInformationsInProgress = yesOrNo
        },
        emailCheckInProgress(state, yesOrNo) {
            state.emailCheckInProgress = yesOrNo
        },
        setEmailCheck(state, checkEmail) {
            state.checkEmail = checkEmail
        },
        errorMessage(state, message) {
            state.errorMessage = message
        },
        fetchInProgress(state, yesOrNo) {
            state.fetchInProgress = yesOrNo
        },
        setShippingInformation(state, shippingInformation) {
            state.shippingInformation = shippingInformation;
        },
        formatOneAddress(state) {
            let streetAddress = [];
            let streetArray = [];
            if (state.address.streetNoTwo == " " || state.address.streetNoTwo == undefined) {
                streetAddress[0] = state.address.streetNoOne;
                streetArray = streetAddress.filter(v => v != '');
            } else {
                streetAddress[0] = state.address.streetNoOne
                streetAddress[1] = state.address.streetNoTwo;
                streetArray = streetAddress.filter(v => v != '');
            }
            let formatedAddress = {
                "region_id": state.address.regionId,
                "country_id": state.address.countryId,
                "street": streetArray,
                "postcode": state.address.postcode,
                "city": state.address.city,
                "telephone": state.address.telephone,
                "firstname": state.address.firstname,
                "lastname": state.address.lastname,
            }
            state.oneAddress = formatedAddress;
        },
        paymentInProgress(state, yesOrNo) {
            state.paymentInProgress = yesOrNo
        },
        checkPOBoxAddress(state, address) {
            let streetAddress = address.street;
            if (streetAddress.length) {
                if (streetAddress[0]) {
                    if (invalidPoBoxAddress(streetAddress[0])) {
                        state.addressContainPOBox = true;
                    } else {
                        state.addressContainPOBox = false;
                    }
                }
                if (streetAddress[1]) {
                    if (invalidPoBoxAddress(streetAddress[1])) {
                        state.addressContainPOBox = true;
                    } else {
                        state.addressContainPOBox = false;
                    }
                }
            }
        },
        setcrGuestQuoteId(state, quoteId) {
            state.guestQuoteId = quoteId
        },
    },
    actions: {
        basicInfo: async({ state, commit }) => {
            try {
                const basicInfo = await doFetchBasicInfo();
                if (basicInfo) {
                    commit('setOne', snakeToCamel(basicInfo));
                    commit('setAddress', snakeToCamel(basicInfo.addresses[0]));                 
                    commit('checkPOBoxAddress', snakeToCamel(basicInfo.addresses[0]));
                }
            } catch (err) {
                state.errorMessage = err.message;
            }
        },
        updateAddress: async({ state, commit }) => {
            commit('inProgress', true);
            try {
                const basicInfo = await doUpdateAddress(state.one, state.address);
                if (basicInfo) { 
                    commit('inProgress', false);
                    commit('setOne', snakeToCamel(basicInfo));
                    commit('setAddress', snakeToCamel(basicInfo.addresses[0]));
                    commit('checkPOBoxAddress', snakeToCamel(basicInfo.addresses[0]));
                }
            } catch (err) {
                state.errorMessage = err.message;
                commit('inProgress', false);
            }
        },
        setEstimatedShipping: async({ state, commit }, email) => {
            commit('inProgress', true);
            try {
                commit('checkPOBoxAddress', snakeToCamel(state.address));
                let guestQuoteId = state.guestQuoteId ? state.guestQuoteId : getCookieByName(crGuestQuoteId);
                const shippMethods = await doFetchEstimatedShippingMethods(state.address, email, guestQuoteId);
                listrakEmailCapture(email);   
                commit('setEstimatedShipping', snakeToCamel(shippMethods));
                commit('inProgress', false);
            } catch (err) {
                commit('inProgress', false);

                throw err; 
            }
        },
        setShippingInformation: async({ state, commit }, data) => {
            commit('shippingInformationsInProgress', true);
            try {
                commit('checkPOBoxAddress', snakeToCamel(state.address));
                let guestQuoteId = state.guestQuoteId ? state.guestQuoteId : getCookieByName(crGuestQuoteId);
                const shippingInformation = await doShippingInformation(state.address, data.method, data.email, guestQuoteId, state.suggestedAddress);
                localStorage.setItem("cr_guestShippingAddress", JSON.stringify(snakeToCamel(shippingInformation)));
                commit('shippingInformationsInProgress', false);
                commit('setShippingInformation', snakeToCamel(shippingInformation));
                localStorage.setItem("cr_checkOutTab", 'reviewTab');
            } catch (err) {
                commit('shippingInformationsInProgress', false);
                throw err;
            }
        },
        setShippingAddress: async({ state, commit }) => {
            commit('setAddress', state.address);
            commit('checkPOBoxAddress', snakeToCamel(state.address));
        },
        checkPOBoxAddress: async({ commit }, address) => {
            commit('checkPOBoxAddress', address);
        },

        checkEmailAvailable: async({ commit }, email) => {
            try {
                commit('emailCheckInProgress', true);
                const checkEmail = await doFetchCheckEmailAvailable(email);
                if (checkEmail) {
                    commit('setEmailCheck', checkEmail);
                    commit('emailCheckInProgress', false);
                    return ({ exist: true });
                } else {
                    commit('emailCheckInProgress', false);
                    return ({ exist: false });
                }
            } catch (err) {
                commit('emailCheckInProgress', false);
            }
        },
        setOrder: async({ state, commit }, { userEmail, paymentMethod }) => {
            try {
                commit('paymentInProgress', true);
                commit('formatOneAddress');
                commit('checkPOBoxAddress', snakeToCamel(state.oneAddress));
                let guestQuoteId = state.guestQuoteId ? state.guestQuoteId : getCookieByName(crGuestQuoteId);
                if (guestQuoteId) {
                    let res = await doProductsOrder(userEmail, guestQuoteId, paymentMethod, state.oneAddress);
                    const guestQuote = await doFetchQuote();
                    commit('paymentInProgress', false);
                    setCookieByName(crGuestQuoteId, guestQuote);
                    commit('setcrGuestQuoteId', guestQuote);
                    
                    return res;
                }

            } catch (error) {
                commit('errorMessage', error.message);
                commit('paymentInProgress', false);
                throw error;
            }
        },
        setPaymentInProgress: async({ commit }, flag) => {
            commit('paymentInProgress', flag);
        },
        setCCOrder: async({ commit }, { orderNumber, shippingAddress, webOrderNumber }) => {
            try {
                await doProductsOrderOnCC(orderNumber, shippingAddress, webOrderNumber);
            } catch (error) {
                commit('errorMessage', error.message);
            }
        }, 
        setUserAddress: async({ commit }, address) => {
            commit('setSuggestedAddress', address);
        },
        resetEstimatedShipping: async({ commit }) => {
            commit('resetEstimatedShippingValue');
        },
    }



}