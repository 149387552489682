import { 
    dofetchCategoryListIdWise
} from '@/services/product.service';

import { getField, updateField } from 'vuex-map-fields';
import { snakeToCamel } from "@/services/util.service";
import {
    doFetchProductList
} from '@/services/cache.service';

export const headerCategoryList = {
    namespaced: true,
    state: {
        inProgress: false,
        categoryList: {},
        categoryListIdWise: {},
        categoryId: null,
        categoryDetails: []
    },
    getters: {
        getField
    },
    mutations: {
        updateField,

        inProgress(state, yesOrNo) {
            state.inProgress = yesOrNo
        },
        setCategoryList(state, list) {
            state.categoryList = list
            console.log('categoryList:', list);
        },
        setCategoryListIdWise(state, list) {
            state.categoryListIdWise = list
        },
        setCategoryId(state, id) {
            state.categoryId = id
        },
        setCategoryDetails(state, details) {
            state.categoryDetails = details
            state.categoryListIdWise = details
        },
    }, 
    actions: {
         
        fetchCategoryList: ({ commit }) => {
            try {
                commit('inProgress', true);
                const doFetchProduct = doFetchProductList();
                if (doFetchProduct) {
                    commit('inProgress', false);
                    commit('setCategoryList', snakeToCamel(
                        {id:1, parent_id:0, name:'Root Catalog',
                        is_active:true,
                        position:0, level:0, product_count:0,
                        children_data: [doFetchProduct.categories]
                    }));
                    commit('setCategoryDetails', snakeToCamel(doFetchProduct.allCategoryDetails));
                }
            } catch (err) {
                commit('inProgress', false);
                throw err
            }
        },
        fetchCategoryListIdWise: async({ commit }) => {
            const categoryList = await dofetchCategoryListIdWise();
            if (categoryList) {
                commit('setCategoryListIdWise', snakeToCamel(categoryList));
            }
        },
        setCategoryId: async({ commit }, id) => {
            commit('setCategoryId', id);
        },
    },

}