  import { wrapRequest } from './nav';
const imageLibApiUrl = import.meta.env.VITE_IMAGELIB_API_URL;

export const doFetchUserBuilderItemImages = wrapRequest((ownerId) => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-type': 'application/json'},
    };
    let res = fetch(`${imageLibApiUrl}/builder_item?owner_id=eq.${ownerId}`, requestOptions);
    return res;
});

export const doPatchOneBuilder = wrapRequest((image) => {    
    if(!image.s3_id){
        let s3Id=image.s_3_id;
        delete image.s_3_id;  
        image.s3_id=s3Id;
    }
    const requestOptions = {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json', 'Accept': 'application/vnd.pgrst.object+json',
            'Prefer': 'return=representation'
        },
        body: JSON.stringify(image)
    };
    return fetch(`${imageLibApiUrl}/builder_item?id=eq.${image.id}`, requestOptions)
})
 