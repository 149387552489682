 import { wrapRequest } from './nav';
const vueAppMagentoApiUrl=import.meta.env.VITE_MAGENTO_API_URL;

export const doLogin = wrapRequest((loginDetail) => {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
        },
        body: JSON.stringify({
            username: loginDetail.email,
            password: loginDetail.password
        })
    };
    return fetch(`${vueAppMagentoApiUrl}/rest/default/V1/integration/customer/token`, requestOptions);
});

 