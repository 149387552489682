<template>
  <div
    class="productpage-backgorund d-flex flex-column productpage-height"
    ref="divOne"
  >
    <div class="productpage-backgorund">
      <div>
        <div v-if="this.productDetails.typeId == 'giftcard'">
          <GiftCardDetailsComponent
            v-bind:productDetails="productDetails"
            :stockFlag="stockFlag"
            :categoryId="categoryId"
          ></GiftCardDetailsComponent>
        </div>
        <div
          v-else-if="
            this.productDetails.typeId !== 'giftcard' &&
            this.productDetails.typeId !== 'configurable'
          "
        >
          <ProductDetailComponent
            v-bind:productDetails="productDetails"
            :stockFlag="stockFlag"
            :categoryId="categoryId"
          />
        </div>
        <div v-else-if="this.productDetails.typeId == 'configurable'">
          <ConfiguredProductDetailComponent
            v-bind:productDetails="productDetails"
            :stockFlag="stockFlag"
            :categoryId="categoryId"
          />
        </div>
      </div>
      <RelatedProductsComponent
        v-bind:relatedProducts="productDetails.relatedLinksProduct"
        v-if="
          productDetails.relatedLinksProduct &&
          productDetails.relatedLinksProduct.length > 0
        "
      />
      <RecentlyViewedComponent v-bind:recentlyViewed="recentlyViewedList" />
    </div>
  
    <FooterComponent />
  </div>
</template>
<script>
const baseUrl = import.meta.env.VITE_BASE_URL;
import * as easings from "vuetify/lib/services/goto/easing-patterns";
import RelatedProductsComponent from "@/components/crane/product-detail/RelatedProductsComponent.vue";
import RecentlyViewedComponent from "@/components/crane/product-detail/RecentlyViewedComponent.vue";
import ProductDetailComponent from "@/components/crane/product-detail/ProductDetailComponent.vue";
import ConfiguredProductDetailComponent from "@/components/crane/product-detail/ConfiguredProductDetailComponent.vue";
import GiftCardDetailsComponent from "@/components/crane/product-detail/GiftCardDetailsComponent.vue";
import FooterComponent from "@/components/layout/footer/FooterComponent.vue";
import { getMeta, googleAanalyticsEventPDP } from "@/services/util.service";
const partnerId = import.meta.env.VITE_ZINRELO_PARTNER_ID;

export default {
  name: "ProductPage",
  components: {
    RelatedProductsComponent,
    RecentlyViewedComponent,
    ProductDetailComponent,
    FooterComponent,
    GiftCardDetailsComponent,
    ConfiguredProductDetailComponent,
  },
  data: () => ({
    easings: Object.keys(easings),
    stockFlag: true,
  }),
  metaInfo() {
    return {
      titleTemplate: `${
        this.metaData && this.metaData.title != undefined
          ? this.metaData.title
          : "Crane Stationery"
      }`,
      title: `${
        this.metaData && this.metaData.title != undefined
          ? this.metaData.title
          : "Crane Stationery"
      }`,
      description: `${
        this.metaData && this.metaData.description != undefined
          ? this.metaData.description
          : ""
      }`,
      keywords: `${
        this.metaData && this.metaData.keywords != undefined
          ? this.metaData.keywords
          : ""
      }`,
     
      link: [
        {
          rel: "canonical",
          href: `${
            this.canonical ? window.location.href.split("?")[0] : baseUrl
          }`,
        },
      ],
    };
  },
  computed: {
    categoryListIdWise: function () {
      return this.$store.state.headerCategoryList.categoryListIdWise;
    },
    canonical: function () {
      return this.$route.meta.canonical;
    },
    sku: function () {
      return this.$route.meta.sku
        ? this.$route.meta.sku
        : this.$route.params.sku;
    },
    productId: function () {
      return this.$route.meta.id ? this.$route.meta.id : this.$route.params.id;
    },
    productDetails: function () {
      return this.$store.state.productOne.productDetails;
    },
    recentlyViewedList() {
      return this.$store.state.productList.recentlyViewed;
    },
    metaData() {
      return getMeta(this.productDetails.customAttribute);
    },
    categoryId: function () {
      return this.$route.query.categoryId;
    },
    fetchInProgress: function () {
      return this.$store.state.productOne.fetchInProgress;
    },
    zinreloToken() {
      return this.$store.state.accountOne.zinreloToken;
    },
  },
  watch: {
    sku() {
      this.$store.dispatch("productOne/fetchProductDetails", {
        sku: this.sku,
        productId: this.productId,
      });
      this.recentlyViewed();
    },
    productDetails() {
     
      if (
        this.productDetails &&
        this.productDetails.customAttribute &&
        this.productDetails.customAttribute.length
      ) {
        if (this.productDetails.configurableProductLinks) {
          this.$store.dispatch(
            "productOne/fetchConfiguredProductDetails",
            this.productDetails.configurableProductLinks[0]
          );
        }
        googleAanalyticsEventPDP(
          this.productDetails,
          "view_item",
          this.categoryId,
          this.categoryListIdWise
        );
        if (
          this.productDetails.stockDetails.backorders == 0 &&
          this.productDetails.stockDetails.qty <= 0
        ) {
          this.stockFlag = false;
          if (
            import.meta.env.NODE_ENV == "production" &&
            window.craneCatalog.products.hasOwnProperty(this.productDetails.id)
          ) {
            this.$store.dispatch("productOne/updateCatlog");
            console.log("updateCatlog called");
          }
        }
      }
    },
  },
  methods: {
    recentlyViewed() {
      let productId = this.$route.meta.id ? this.$route.meta.id.toString() : "";

      let checkPoint = localStorage.getItem("cr_recently_viewed_product");
      if (checkPoint) {
        let newVal = localStorage
          .getItem("cr_recently_viewed_product")
          .split(",");
        if (newVal.indexOf(productId) !== -1) {
          newVal.splice(newVal.indexOf(productId), 1);
          this.$store.dispatch(
            "productList/fetchRecentlyViewedList",
            newVal.toString()
          );
          newVal.splice(0, 0, productId);
          localStorage.setItem("cr_recently_viewed_product", newVal);
        } else {
          this.$store.dispatch(
            "productList/fetchRecentlyViewedList",
            newVal.toString()
          );
          newVal.splice(0, 0, productId);
          localStorage.setItem("cr_recently_viewed_product", newVal);
        }
      } else {
        localStorage.setItem("cr_recently_viewed_product", productId);
      }
    },
  },
  mounted() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  },
  created() {
    this.$store.dispatch("productOne/fetchProductDetails", {
      sku: this.sku,
      productId: this.productId,
    });
    this.$store.dispatch("productList/fetchAttributeList");

    this.recentlyViewed();
 
  },
};
</script>
<style src="./product_page.scss" lang="scss" scoped />