import { wrapRequest } from "./nav";

const imageLibApiUrl = import.meta.env.VITE_IMAGELIB_API_URL;

export const doFetchPriceBundle = wrapRequest(({ skuList, availableAt }) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-type": "application/json" },
    body: JSON.stringify({ sku_list: skuList, available_at: availableAt }),
  };
  return fetch(`${imageLibApiUrl}/rpc/pricer_asset_get_bundle`, requestOptions);
});
