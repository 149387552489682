import { doFetchOrderProductsList} from '@/services/product.service';
import { getField, updateField } from 'vuex-map-fields';
import { snakeToCamel } from '@/services/util.service';

export const meProductList = {
    namespaced: true,
    state: {
        inProgress: true,
        orderProductsList: [],
    },
    getters: {
        getField
    },
    mutations: {
        updateField,

        inProgress(state, yesOrNo) {
            state.inProgress = yesOrNo
        },
        setOrderProductsList(state, list) {
            state.orderProductsList = list
        },
    },
    actions: {
        fetchOrderProductsList: async ({ commit },emailId) => {
            try{
            commit('inProgress', true);    
            let orderProductsList = await doFetchOrderProductsList(emailId);
            if (orderProductsList) {
                commit('inProgress', false);
                commit('setOrderProductsList', snakeToCamel(orderProductsList));
            }
        }
        catch(error){
            commit('inProgress', false);
            throw error
        }
        },
    },

}
