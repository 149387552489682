  import { wrapRequest } from './nav';
const imageLibApiUrl = import.meta.env.VITE_IMAGELIB_API_URL;

const vueAppNodeslsApiUrl = import.meta.env.VITE_NODESLS_API_URL;

export const doFetchUploadSignatureAndPolicy = wrapRequest((bucketName, bucketRegion, fileName, fileType) => {

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify(
            {
                s3_bucket: bucketName,
                region: bucketRegion,
                name: fileName,
                type: fileType,
            }
        )
    };
    return fetch(`${vueAppNodeslsApiUrl}/s3-sign`, requestOptions);
});


export const doS3Upload = async (file, signatureAndPolicy, contentType) => {
    try {
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': contentType },
            body: file
        };
        let response = await fetch(signatureAndPolicy, requestOptions);
        if (!response.ok)
            throw response;
        else
            return await response.text();
    }
    catch (error) {
        throw await error.text();
    }

}

export const doFetchList = wrapRequest(() => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-type': 'application/json'},
    };
    return fetch(`${imageLibApiUrl}/image`, requestOptions);
});

export const doFetchImages = wrapRequest((tags) => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-type': 'application/json'},
    };
    let res = fetch(`${imageLibApiUrl}/image?tags=ov.{${tags}}`, requestOptions);
    return res;
});

export const doFetchUserImages = wrapRequest((ownerId) => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-type': 'application/json'},
    };
    let res = fetch(`${imageLibApiUrl}/image?owner_id=eq.${ownerId}`, requestOptions);
    return res;
});
export const doFetchOne = wrapRequest((id) => {
    const requestOptions = {
        method: 'GET',   
        headers: { 'Content-type': 'application/json'},  
    };
    return fetch(`${imageLibApiUrl}/image?id=eq.${id}`, requestOptions);
});

export const dolookUpList = wrapRequest((search) => {           
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-type': 'application/json'},
        body:JSON.stringify({ name:search.name||'',brand:search.brand||'' , organization_id:search.organizationId,tags:search.tags, _limit:10000})
    };
    return fetch(`${imageLibApiUrl}/rpc/lookup_image`, requestOptions);
   
})


export const doPostOne = wrapRequest((image) => {     
    if(!image.s3_id){
        let s3Id=image.s_3_id;
        delete image.s_3_id;  
        image.s3_id=s3Id;
    }
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json', 'Accept': 'application/vnd.pgrst.object+json',
            'Prefer': 'return=representation'
        },
        body: JSON.stringify(image)
    };

    return fetch(`${imageLibApiUrl}/image`, requestOptions)

})

export const doPatchOne = wrapRequest((image) => {    
    if(!image.s3_id){
        let s3Id=image.s_3_id;
        delete image.s_3_id;  
        image.s3_id=s3Id;
    }
    const requestOptions = {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json', 'Accept': 'application/vnd.pgrst.object+json',
            'Prefer': 'return=representation'
        },
        body: JSON.stringify(image)
    };
    return fetch(`${imageLibApiUrl}/image?id=eq.${image.id}`, requestOptions)
})
export const doDeleteSelected = wrapRequest((imageIds) => {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Prefer': 'return=representation'
        },        
    };

    return fetch(`${imageLibApiUrl}/image?id=in.(${imageIds})`, requestOptions)
    
})
