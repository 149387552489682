
import { wrapRequest } from './nav';

const vueAppImageApiUrl = import.meta.env.VITE_IMAGELIB_API_URL;

export const doFetchList = wrapRequest(() => {
  const requestOptions = {
      method: 'GET',
      headers: { 'Content-type': 'application/json'},
  };
   return fetch(`${vueAppImageApiUrl}/pdfdoc?type=eq.final`, requestOptions);
});

export const doFetchOne = wrapRequest((id) => {
  const requestOptions = {
      method: 'GET',   
      headers: { 'Content-type': 'application/json'},  
  };
  return fetch(`${vueAppImageApiUrl}/pdfdoc?id=eq.${id}`, requestOptions);
});

export const dolookUpList = wrapRequest((search) => {
  const requestOptions = {
      method: 'POST',
      headers: { 'Content-type': 'application/json'},
      body:JSON.stringify({ oms_order_id:search.omsOrderId||'',organization_id:search.organizationId,tags:search.tags, _limit:100})
  };
  return fetch(`${vueAppImageApiUrl}/rpc/lookup_pdfdoc`, requestOptions);
})


export const doPostOne = wrapRequest((pdfdoc) => {

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json','Prefer': 'return=representation' },
    body: JSON.stringify(pdfdoc),
  };
 
  return fetch(`${vueAppImageApiUrl}/pdfdoc`, requestOptions);

});

export const doPatchOne = wrapRequest((pdfdoc) => {    
  if(!pdfdoc.s3_id){
      let s3Id=pdfdoc.s_3_id;
      delete pdfdoc.s_3_id;  
      pdfdoc.s3_id=s3Id;
  }
  const requestOptions = {
      method: 'PATCH',
      headers: {
          'Content-Type': 'application/json', 'Accept': 'application/vnd.pgrst.object+json',
          'Prefer': 'return=representation'
      },
      body: JSON.stringify(pdfdoc)
  };
  return fetch(`${vueAppImageApiUrl}/pdfdoc?id=eq.${pdfdoc.id}`, requestOptions)
})




