<template>
  <v-container class="background-white" ref="divOne">
    <v-row class="mb-md-10" v-if="!fetchErrorMessage">
      <v-col
        cols="1"
        class="d-none d-sm-block pr-4"
        v-if="productDetails.mediaGalleryEntries.length"
      >
        <div
          v-for="(productImage, index) in productDetails.mediaGalleryEntries"
          v-bind:key="index"
        >
          <div v-if="productImage.mediaType === 'image'">
            <v-col class="pb-2 pt-0 pl-0 pr-0">
              <v-hover v-slot="{ isHovering, props }">
                <v-card
                  :variant="
                    index == selected || isHovering ? 'outlined' : 'plain'
                  "
                  color="primary"
                  v-bind="props"
                  @click="setSelected(productImage.file, index)"
                  v-if="productImage.file"
                  rounded="0"
                  class="productpage-mediatype-image"
                >
                  <v-img
                    :src="
                      mediaImageUrl +
                      '/' +
                      fetchProductImageName(productImage.file) +
                      '_360.webp'
                    "
                    @error="setAltImg()"
                    :alt="productDetails.name"
                    :title="productDetails.name"
                  ></v-img>
                </v-card>
              </v-hover>
            </v-col>
          </div>
        </div>
      </v-col>
      <v-col
        cols="1"
        class="d-none d-sm-block pr-4"
        v-else-if="productDetails.mediaGalleryEntries.length == 0"
      >
        <v-col class="pb-2 pt-0 px-0">
          <v-hover v-slot="{ isHovering, props }">
            <v-card
              v-bind="props"
              variant="outlined"
              :color="isHovering ? 'primary' : 'white'"
              class="bg-primary text-white"
              tile
              rounded="0"
            >
              <picture>
                <template v-for="imgWidth in variants" :key="imgWidth">
                  <source
                    :media="`(min-width:${imgWidth}px)`"
                    :srcset="
                      mediaImageUrl +
                      '/' +
                      fetchProductImageName(imageBasePathUrl) +
                      '_' +
                      imgWidth +
                      '.webp'
                    "
                    style="width: 100%"
                  />
                </template>
                <img
                  :src="
                    mediaImageUrl +
                    '/' +
                    fetchProductImageName(imageBasePathUrl) +
                    '.webp'
                  "
                  style="width: 100%"
                  :alt="productDetails.name"
                  :title="productDetails.name"
                  onerror="this.onerror=null;this.parentNode.children[0].srcset=this.parentNode.children[1].srcset=this.parentNode.children[2].srcset='https://cdn.crane.com/stg-images/cc12011-1.webp';"
                />
              </picture>
            </v-card>
          </v-hover>
        </v-col>
      </v-col>
      <v-col cols="12" sm="7" md="7" class="pr-md-8 pb-0" v-if="imagepath">
        <v-row class="ma-0">
          <v-col cols="10" class="d-block d-sm-none pa-0 ma-0 pb-5">
            <h1
              class="text-uppercase font-weight-300 h3 ma-0 pa-0 ls-n01 title-color-black"
            >
              {{ productDetails.name }}
            </h1>
          </v-col>
        </v-row>
        <picture>
          <template v-for="imgWidth in variants" :key="imgWidth">
            <source
              :media="`(min-width:${imgWidth}px)`"
              :srcset="
                mediaImageUrl +
                '/' +
                fetchProductImageName(imagepath) +
                '_' +
                imgWidth +
                '.webp'
              "
              style="width: 100%"
            />
          </template>
          <img
            :src="
              mediaImageUrl + '/' + fetchProductImageName(imagepath) + '.webp'
            "
            style="width: 100%"
            :alt="productDetails.name"
            :title="productDetails.name"
            onerror="this.onerror=null;this.parentNode.children[0].srcset=this.parentNode.children[1].srcset=this.parentNode.children[2].srcset='https://cdn.crane.com/stg-images/cc12011-1.webp';"
          />
        </picture>
      </v-col>
      <v-col cols="12" sm="7" md="7" class="pr-md-8 pb-0" v-else>
        <v-row class="mx-0">
          <v-col cols="11" class="d-block d-sm-none pa-0 ma-0 pb-5">
            <h1
              class="text-uppercase font-weight-300 h3 ma-0 pa-0 ls-n01 title-color-black"
            >
              {{ productDetails.name }}
            </h1>
          </v-col>
        </v-row>
        <picture>
          <template v-for="imgWidth in variants" :key="imgWidth">
            <source
              :media="`(min-width:${imgWidth}px)`"
              :srcset="
                mediaImageUrl +
                '/' +
                fetchProductImageName(
                  productDetails.mediaGalleryEntries.length
                    ? productDetails.mediaGalleryEntries[0].file
                    : ''
                ) +
                '_' +
                imgWidth +
                '.webp'
              "
              style="width: 100%"
            />
          </template>
          <img
            :src="
              mediaImageUrl +
              '/' +
              fetchProductImageName(
                productDetails.mediaGalleryEntries[0].file
              ) +
              '.webp'
            "
            style="width: 100%"
            :alt="productDetails.name"
            :title="productDetails.name"
            onerror="this.onerror=null;this.parentNode.children[0].srcset=this.parentNode.children[1].srcset=this.parentNode.children[2].srcset='https://cdn.crane.com/stg-images/cc12011-1.webp';"
          />
        </picture>
      </v-col>
      <v-row
        align="center"
        class="d-flex d-sm-none mx-auto px-1"
        v-if="productDetails.mediaGalleryEntries.length"
      >
        <v-col
          v-for="(productImage, index) in productDetails.mediaGalleryEntries"
          v-bind:key="index"
          v-show="productImage.mediaType === 'image'"
          class="px-2"
          cols="3"
        >
          <v-hover v-slot="{ isHovering, props }">
            <v-card
              max-width="70"
              max-height="70"
              class="mx-auto mt-2"
              v-bind="props"
              @click="setSelected(productImage.file, index)"
              :variant="index == selected || isHovering ? 'outlined' : 'plain'"
              color="primary"
              tile
              v-if="productImage.file"
              rounded="0"
            >
              <picture>
                <source
                  :media="`(min-width:100px)`"
                  :srcset="
                    mediaImageUrl +
                    '/' +
                    fetchProductImageName(productImage.file) +
                    '_360.webp'
                  "
                  style="width: 100%"
                />
                <img
                  :src="
                    mediaImageUrl +
                    '/' +
                    fetchProductImageName(productImage.file) +
                    '.webp'
                  "
                  style="width: 100%"
                  :alt="productImage.name"
                  :title="productImage.name"
                  onerror="this.onerror=null;this.parentNode.children[0].srcset=this.parentNode.children[1].srcset=this.parentNode.children[2].srcset='https://cdn.crane.com/stg-images/cc12011-1.webp';"
                />
              </picture>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
      <v-row
        align="center"
        class="d-flex d-sm-none mx-auto px-1"
        v-else-if="productDetails.mediaGalleryEntries.length == 0"
      >
        <v-col class="px-2" cols="3">
          <v-hover v-slot="{ isHovering, props }">
            <v-card
              max-width="70"
              max-height="70"
              class="mx-auto bg-primary text-white"
              v-bind="props"
              :variant="index == selected || isHovering ? 'outlined' : 'plain'"
              color="primary"
              tile
            >
              <v-img
                v-bind:src="imageBasePathUrl + ''"
                max-width="70"
                max-height="70"
                @error="setAltImg()"
                :alt="productDetails.name"
                :title="productDetails.name"
              ></v-img>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>

      <v-col cols="12" sm="4" md="4" class="pl-md-12">
        <v-row>
          <v-col md="12" cols="12" class="pr-0 pt-0">
            <h1
              class="font-weight-300 d-none d-sm-block fontsize-28 regular-font line-height-36 title-color-black text-uppercase ma-0 pa-0 ls-n01"
            >
              {{ productDetails.name }}
            </h1>
            <p
              class="d-block caption line-height-21 mb-0 body-font ls-n008 fontcolor-grey-darken d-sm-none text-uppercase"
            >
              {{ productDetails.sku }}
            </p>
          </v-col>
        </v-row>

        <p
          class="d-none d-sm-block body-font caption line-height-21 ls-n008 fontcolor-grey-darken text-uppercase pb-1"
        >
          sku {{ productDetails.sku }}
        </p>
        <p
          class="fontsize-16 mt-4 line-height-21 body-font ls-n01 fontcolor-black-darken"
          v-html="productDetails.description"
        ></p>

        <div v-if="showZinreloRewards && showZinreloRewards == 'on'">
          <div
            v-if="rewardsPoints"
            class="my-2 fontsize-16 mt-4 line-height-21 body-font ls-n01 fontcolor-black-darken"
          >
            You will earn a minimum of
            <span class="text-primary text-underline"
              >{{ rewardsPoints }}
            </span>
            Reward Points with this purchase.
            <v-icon
              :icon="mdiAccountCircleOutline"
              class="ml-2"
              @click="signInPop()"
              size="small"
              v-if="!userAuth"
            />
          </div>
          <div
            v-else
            class="my-2 fontsize-16 mt-4 line-height-21 body-font ls-n01 fontcolor-black-darken"
          >
            You will earn Reward Points with this purchase.
            <v-icon
              :icon="mdiAccountCircleOutline"
              class="ml-2"
              @click="signInPop()"
              size="small"
              v-if="!userAuth"
            />
          </div>
        </div>

        <span v-if="productDetails && productDetails.customAttribute.length">
          <v-row class="pt-6">
            <v-col cols="6" sm="12" md="12" lg="5" class="pr-0 pb-0">
              <p
                class="body-font mb-0 caption ls-n008 fontcolor-grey-darken pb-1"
              >
                {{ productDetails.unitDescription }}
              </p>

              <p
                v-if="
                  getCustomAttributeValue(
                    productDetails.customAttribute,
                    'lowest_price'
                  )
                "
                class="body title-color-black"
              >
                <span
                  v-if="
                    getCustomAttributeValue(
                      productDetails.customAttribute,
                      'lowest_price'
                    ).includes('price:')
                  "
                >
                  From ${{
                    replacePriceString(
                      getCustomAttributeValue(
                        productDetails.customAttribute,
                        "lowest_price"
                      )
                    )
                  }}
                  {{ productDetails.uom }}
                </span>
                <span v-else>
                  From ${{
                    priceFormatter(
                      getCustomAttributeValue(
                        productDetails.customAttribute,
                        "lowest_price"
                      ),
                      true
                    )
                  }}
                  {{ productDetails.uom }}
                </span>
              </p>
              <div
                v-else
                class="fontsize-14 body ls-n009 fontcolor-black-darken font-weight-300"
              >
                <div
                  v-if="
                    getCustomAttributeValue(
                      productDetails.customAttribute,
                      'special_price'
                    )
                  "
                >
                  <s>${{ priceFormatter(productDetails.price, true) }}</s>
                  &nbsp;
                  <span class="text-red mb-0"
                    >${{
                      priceFormatter(
                        getCustomAttributeValue(
                          productDetails.customAttribute,
                          "special_price"
                        ),
                        true
                      )
                    }}</span
                  >
                  / {{ productDetails.uom }}
                </div>
                <p v-else class="body title-color-black mb-4">
                  ${{ priceFormatter(productDetails.price, true) }} /
                  {{ productDetails.uom }}
                </p>
              </div>
            </v-col>
            <v-col
              align="right"
              justify="right"
              cols="6"
              sm="12"
              md="12"
              lg="7"
              class="d-none d-sm-block pb-0"
            >
              <div
                v-if="
                  getCustomAttributeValue(
                    productDetails.customAttribute,
                    'is_personalize'
                  ) === '1' &&
                  productDetails.status == '1' &&
                  qtyPriceOptions.length > 0
                "
              >
                <v-hover v-slot="{ isHovering, props }">
                  <v-select
                    :items="qtyPriceOptions"
                    class="word-break fontsize-16 line-height-21 ls-n009 font-weight-400 productpage-select-button select-change-icon"
                    v-bind="props"
                    item-title="price"
                    item-value="qty"
                    single-line
                    v-model="selectedQty"
                    ref="selectedQty1"
                    background-color="transparent"
                    :menu-icon="mdiPlus"
                    density="compact"
                    :variant="isHovering ? 'outlined' : ''"
                    :color="isHovering ? 'primary' : 'black'"
                    @update:modelValue="productReward(selectedQty)"
                  >
                    <template v-slot:selection="{ item }">
                      <span
                        class="fontsize-16 line-height-21 ls-n009 regular-font font-weight-400"
                        :class="
                          isHovering || !isActive
                            ? 'text-primary'
                            : 'text-black'
                        "
                      >
                        {{ item.value }} ( $ {{ item.title }})</span
                      >
                    </template>

                    <template v-slot:item="{ props, item }">
                      <v-list-item-title
                        v-bind="props"
                        class="fontsize-16 line-height-21 ls-n009 regular-font font-weight-400 pl-4 mb-2 inputHoverEffect"
                        >{{ item.value }} ($
                        {{ item.title }} )</v-list-item-title
                      >
                    </template>
                  </v-select>
                </v-hover>
              </div>
              <div
                v-else-if="
                  productDetails.status == '1' &&
                  getCustomAttributeValue(
                    productDetails.customAttribute,
                    'is_personalize'
                  ) === '0'
                "
              >
                <v-hover v-slot="{ isHovering, props }">
                  <v-select
                    :items="numberOptions"
                    class="word-break fontsize-16 line-height-21 ls-n009 font-weight-400 productpage-select-button select-change-icon"
                    v-bind="props"
                    flat
                    v-model="selectedQty"
                    ref="selectedQty2"
                    background-color="transparent"
                    :menu-icon="mdiPlus"
                    density="compact"
                    :variant="isHovering ? 'outlined' : ''"
                    @update:modelValue="productReward(selectedQty)"
                  ></v-select>
                </v-hover>
              </div>
            </v-col>

            <v-col
              align="right"
              justify="right"
              cols="6"
              class="d-block d-sm-none pl-4 pb-0"
            >
              <div
                v-if="
                  getCustomAttributeValue(
                    productDetails.customAttribute,
                    'is_personalize'
                  ) === '1' &&
                  productDetails.status == '1' &&
                  qtyPriceOptions.length > 0
                "
              >
                <v-hover v-slot="{ isHovering, props }">
                  <v-select
                    :items="qtyPriceOptions"
                    class="word-break fontsize-14 line-height-21 ls-n009 font-weight-400 productpage-select-button select-change-icon"
                    v-bind="props"
                    attach
                    flat
                    solo
                    item-title="price"
                    item-value="qty"
                    single-line
                    v-model="selectedQty"
                    ref="selectedQty3"
                    background-color="transparent"
                    :menu-icon="mdiPlus"
                    no-data-text
                    density="compact"
                    :variant="isHovering ? 'outlined' : ''"
                    @update:modelValue="productReward(selectedQty)"
                  >
                    <template v-slot:selection="{ item }">
                      <span
                        class="fontsize-16 line-height-21 ls-n009 regular-font font-weight-400"
                        :class="
                          isHovering || !isActive
                            ? 'text-primary'
                            : 'text-black'
                        "
                      >
                        {{ item.value }} ( $ {{ item.title }})</span
                      >
                    </template>

                    <template v-slot:item="{ props, item }">
                      <v-list-item-title
                        v-bind="props"
                        class="fontsize-16 line-height-21 ls-n009 regular-font font-weight-400 pl-4 mb-2 inputHoverEffect"
                        >{{ item.value }} ($
                        {{ item.title }} )</v-list-item-title
                      >
                    </template>
                  </v-select>
                </v-hover>
              </div>
              <div
                v-else-if="
                  productDetails.status == '1' &&
                  getCustomAttributeValue(
                    productDetails.customAttribute,
                    'is_personalize'
                  ) === '0'
                "
              >
                <v-hover v-slot="{ isHovering, props }">
                  <v-select
                    :items="numberOptions"
                    class="word-break fontsize-16 line-height-21 ls-n009 font-weight-400 productpage-select-button select-change-icon"
                    v-bind="props"
                    flat
                    solo
                    :class="isHovering ? 'text-primary' : 'text-black'"
                    v-model="selectedQty"
                    background-color="transparent"
                    ref="selectedQty4"
                    :menu-icon="mdiPlus"
                    no-data-text
                    density="compact"
                    :variant="isHovering ? 'outlined' : ''"
                    @update:modelValue="productReward(selectedQty)"
                  ></v-select>
                </v-hover>
              </div>
            </v-col>
          </v-row>

          <p
            class="py-0 my-0 text-red"
            v-if="
              productDetails.stockDetails.manageStock === 1 &&
              productDetails.stockDetails.qty <= 0
            "
          >
            {{
              getCustomAttributeValue(
                productDetails.customAttribute,
                "backorder_status"
              )
            }}
          </p>
          <p class="py-0 my-0 text-red">{{ errorMessageCart }}</p>
          <v-row v-if="!itemId">
            <v-col
              class="pt-4"
              v-if="
                productDetails.status == '1' &&
                getCustomAttributeValue(
                  productDetails.customAttribute,
                  'is_personalize'
                ) === '0' &&
                stockFlag
              "
            >
              <v-hover v-slot:default="{ isHovering, props }">
                <v-btn
                  v-bind="props"
                  :disabled="cartProductInProgress"
                  block
                  size="x-large"
                  :variant="
                    cartProductInProgress || isHovering ? 'outlined' : 'flat'
                  "
                  :color="isHovering ? 'white' : 'primary'"
                  :class="isHovering ? 'text-primary' : 'text-white'"
                  class="pa-6 text-uppercase ls-0 body productpage-button rounded-0"
                  tile
                  depressed
                  @click="addToCart(productDetails.sku, productDetails)"
                >
                  <v-progress-circular
                    align="center"
                    :size="40"
                    block
                    color="primary"
                    indeterminate
                    v-if="cartProductInProgress"
                  ></v-progress-circular>
                  <span v-if="!cartProductInProgress">ADD TO CART</span>
                </v-btn>
              </v-hover>
            </v-col>
            <v-col
              v-else-if="
                getCustomAttributeValue(
                  productDetails.customAttribute,
                  'is_personalize'
                ) === '1' &&
                productDetails.status == '1' &&
                qtyPriceOptions.length > 0 &&
                stockFlag
              "
            >
              <v-hover v-slot:default="{ isHovering, props }">
                <v-btn
                  v-bind="props"
                  block
                  size="x-large"
                  class="mt-4 text-uppercase ls-0 body pb-1 productpage-button rounded-0"
                  :variant="isHovering ? 'flat' : 'outlined'"
                  :color="isHovering ? 'primary' : 'white'"
                  :class="isHovering ? 'text-white' : 'text-primary'"
                  tile
                  depressed
                  @click="gotoPersonalize(productDetails)"
                  >PERSONALIZE</v-btn
                >
              </v-hover>
            </v-col>
            <v-col
              class="pt-4"
              v-if="productDetails.status == '2' || !stockFlag"
            >
              <v-btn
                block
                size="x-large"
                class="text-uppercase ls-0 body pb-1 text-white"
                rounded="0"
                tile
                color="#97ABB4"
                depressed
                >UNAVAILABLE</v-btn
              >
            </v-col>
          </v-row>

          <v-row v-else>
            <v-col
              class="pt-4"
              v-if="
                itemId &&
                getCustomAttributeValue(
                  productDetails.customAttribute,
                  'is_personalize'
                ) === '0'
              "
            >
              <v-hover v-slot:default="{ isHovering, props }">
                <v-btn
                  v-bind="props"
                  :disabled="cartProductInProgress"
                  block
                  size="x-large"
                  depressed
                  :variant="
                    cartProductInProgress || isHovering ? 'outlined' : 'flat'
                  "
                  :color="isHovering ? 'white' : 'primary'"
                  :class="isHovering ? 'text-primary' : 'text-white'"
                  class="text-uppercase pa-6 ls-0 body productpage-button rounded-0"
                  tile
                  @click="updateToCart(productDetails.name, productDetails.sku)"
                >
                  <v-progress-circular
                    align="center"
                    :size="40"
                    color="primary"
                    indeterminate
                    v-if="cartProductInProgress"
                  ></v-progress-circular>
                  <span v-if="!cartProductInProgress">UPDATE CART</span>
                </v-btn>
              </v-hover>
            </v-col>
          </v-row>
          <v-row> </v-row>
          <v-row>
            <v-col class="pt-5">
              <v-tabs
                v-model="tab"
                slider-color="primary"
                color="black"
                class="product-overview-tabs"
              >
                <v-tab
                  value="overview"
                  class="fontsize-14 regular-font ls-n008 text-capitalize"
                >
                  Overview
                </v-tab>

                <v-tab
                  value="materials"
                  class="fontsize-14 regular-font ls-n008 text-capitalize"
                  >Materials</v-tab
                >
                <v-tab
                  value="shipping"
                  class="fontsize-14 regular-font ls-n008 text-capitalize"
                  >Shipping</v-tab
                >
              </v-tabs>
              <v-card-text>
                <v-window v-model="tab" class="pl-6 pl-xl-10">
                  <v-window-item value="overview">
                    <ul class="py-3 fontsize-16 body-font ls-n008">
                      <li
                        v-if="
                          getCustomAttributeLable(
                            productDetails.customAttribute,
                            'brand'
                          )
                        "
                      >
                        {{
                          getCustomAttributeLable(
                            productDetails.customAttribute,
                            "brand"
                          )
                        }}
                      </li>
                      <li
                        v-if="
                          getCustomAttributeLable(
                            productDetails.customAttribute,
                            'product_def_type'
                          )
                        "
                      >
                        {{
                          getCustomAttributeLable(
                            productDetails.customAttribute,
                            "product_def_type"
                          )
                        }}
                      </li>
                      <li
                        v-if="
                          getCustomAttributeLable(
                            productDetails.customAttribute,
                            'unit_description'
                          )
                        "
                      >
                        {{
                          getCustomAttributeLable(
                            productDetails.customAttribute,
                            "unit_description"
                          )
                        }}
                      </li>
                      <li
                        v-if="
                          getCustomAttributeLable(
                            productDetails.customAttribute,
                            'product_dimensions'
                          )
                        "
                      >
                        {{
                          getCustomAttributeLable(
                            productDetails.customAttribute,
                            "product_dimensions"
                          )
                        }}
                      </li>
                      <li
                        v-if="
                          getCustomAttributeLable(
                            productDetails.customAttribute,
                            'printing_process'
                          )
                        "
                      >
                        Printing Process -
                        {{
                          getCustomAttributeLable(
                            productDetails.customAttribute,
                            "printing_process"
                          )
                        }}
                      </li>
                      <li
                        v-if="
                          getCustomAttributeLable(
                            productDetails.customAttribute,
                            'processing_time'
                          )
                        "
                      >
                        {{
                          getCustomAttributeLable(
                            productDetails.customAttribute,
                            "processing_time"
                          )
                        }}
                      </li>
                      <li
                        v-if="
                          getCustomAttributeLable(
                            productDetails.customAttribute,
                            'additional_feature_1'
                          )
                        "
                      >
                        {{
                          getCustomAttributeLable(
                            productDetails.customAttribute,
                            "additional_feature_1"
                          )
                        }}
                      </li>
                      <li
                        v-if="
                          getCustomAttributeLable(
                            productDetails.customAttribute,
                            'additional_feature_2'
                          )
                        "
                      >
                        {{
                          getCustomAttributeLable(
                            productDetails.customAttribute,
                            "additional_feature_2"
                          )
                        }}
                      </li>
                      <li
                        v-if="
                          getCustomAttributeLable(
                            productDetails.customAttribute,
                            'additional_feature_3'
                          )
                        "
                      >
                        {{
                          getCustomAttributeLable(
                            productDetails.customAttribute,
                            "additional_feature_3"
                          )
                        }}
                      </li>
                      <li
                        v-if="
                          getCustomAttributeLable(
                            productDetails.customAttribute,
                            'additional_feature_4'
                          )
                        "
                      >
                        {{
                          getCustomAttributeLable(
                            productDetails.customAttribute,
                            "additional_feature_4"
                          )
                        }}
                      </li>
                      <li
                        v-if="
                          getCustomAttributeLable(
                            productDetails.customAttribute,
                            'additional_feature_5'
                          )
                        "
                      >
                        {{
                          getCustomAttributeLable(
                            productDetails.customAttribute,
                            "additional_feature_5"
                          )
                        }}
                      </li>
                      <li
                        v-if="
                          getCustomAttributeLable(
                            productDetails.customAttribute,
                            'additional_feature_6'
                          )
                        "
                      >
                        {{
                          getCustomAttributeLable(
                            productDetails.customAttribute,
                            "additional_feature_6"
                          )
                        }}
                      </li>
                    </ul>
                  </v-window-item>
                  <v-window-item value="materials">
                    <ul class="py-3 fontsize-16 body-font ls-n008">
                      <li
                        v-if="
                          getCustomAttributeLable(
                            productDetails.customAttribute,
                            'cotton_paper'
                          )
                        "
                      >
                        {{
                          getCustomAttributeLable(
                            productDetails.customAttribute,
                            "cotton_paper"
                          )
                        }}
                      </li>
                      <li
                        v-if="
                          getCustomAttributeLable(
                            productDetails.customAttribute,
                            'paper_weight'
                          )
                        "
                      >
                        {{
                          getCustomAttributeLable(
                            productDetails.customAttribute,
                            "paper_weight"
                          )
                        }}
                      </li>
                      <li
                        v-if="
                          getCustomAttributeLable(
                            productDetails.customAttribute,
                            'made_usa'
                          )
                        "
                      >
                        {{
                          getCustomAttributeLable(
                            productDetails.customAttribute,
                            "made_usa"
                          )
                        }}
                      </li>
                    </ul>
                  </v-window-item>
                  <v-window-item value="shipping">
                    <ul class="py-3 fontsize-16 body-font ls-n008">
                      <li
                        v-if="
                          getCustomAttributeLable(
                            productDetails.customAttribute,
                            'processing_time'
                          )
                        "
                      >
                        {{
                          getCustomAttributeLable(
                            productDetails.customAttribute,
                            "processing_time"
                          )
                        }}
                      </li>
                      <li>Standard shipping available</li>
                      <li>Expedited shipping available</li>
                      <li>Ships from Cohoes, NY</li>
                    </ul>
                  </v-window-item>
                </v-window>
              </v-card-text>
            </v-col>
          </v-row>
        </span>

        <span v-else>
          <v-skeleton-loader
            type="list-item-three-line"
            class="ml-0 pl-0"
          ></v-skeleton-loader
          ><br />
          <v-skeleton-loader
            type="list-item-two-line"
            class="ml-0 pl-0"
          ></v-skeleton-loader
          ><br />
          <v-skeleton-loader
            type="actions"
            class="ml-0 pl-0"
          ></v-skeleton-loader>
        </span>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col class="text-center">
        <v-alert type="error" text>{{ fetchErrorMessage }}</v-alert>
      </v-col>
    </v-row>
    <SnackbarComponent :snackbar="snackbar"></SnackbarComponent>
    <component type="application/ld+json" :is="'script'" v-html="productLdJson">
    </component>

    <v-dialog v-model="signInForm" persistent flat max-width="500">
      <v-card flat>
        <v-card-title class="d-flex justify-space-between">
          <p class="h3 mb-0 whyte-regular-font">Sign In</p>
          <v-btn
            @click="closeSignInForm()"
            density="comfortable"
            variant="text"
            :ripple="false"
            icon
          >
            <v-icon :icon="mdiWindowClose" />
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-3 pb-0">
          <v-alert class="word-break" type="error" v-if="!!errorMessage">{{
            errorMessage
          }}</v-alert>
          <v-alert class="word-break" type="success" v-if="!!signInMessage">{{
            signInMessage
          }}</v-alert>
          <v-form ref="loginForm" v-model="validLogin">
            <v-col class="pt-3 pb-1">
              <v-text-field
                v-model="emailLogin"
                type="email"
                label="Email Address"
                ref="email"
                name="email"
                variant="outlined"
                density="comfortable"
                :rules="emailRules"
              ></v-text-field>
            </v-col>
            <v-col class="pt-1 pb-0">
              <v-text-field
                v-model="passwordLogin"
                label="Password"
                ref="password"
                type="password"
                name="password"
                variant="outlined"
                density="comfortable"
                :rules="passwordRules"
              ></v-text-field>
            </v-col>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-row no-gutters>
            <v-col cols="12" class="px-7">
              <v-btn
                variant="flat"
                block
                color="primary"
                class="text-white"
                @click="signIn(productDetails.sku)"
              >
                <v-progress-circular
                  :size="20"
                  color="white"
                  indeterminate
                  v-if="inProgress"
                ></v-progress-circular>
                &nbsp;SIGN IN
              </v-btn>
            </v-col>
            <v-col cols="6" class="py-5 pl-7" align="left">
              <span
                class="text-decoration-none cursor-pointer text-primary"
                @click="openSignUpDialog()"
                >Sign Up</span
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="signUpForm" persistent flat max-width="500">
      <v-card flat>
        <v-card-title class="d-flex justify-space-between">
          <p class="h3 mb-0 whyte-regular-font">Sign Up</p>
          <v-btn
            @click="closeSignUpForm()"
            density="comfortable"
            variant="text"
            :ripple="false"
            icon
          >
            <v-icon :icon="mdiWindowClose" />
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-3 pb-0">
          <v-alert class="word-break" type="error" v-if="!!errorMessage">{{
            errorMessage
          }}</v-alert>
          <v-alert class="word-break" type="success" v-if="!!signUpMessage">{{
            signUpMessage
          }}</v-alert>
          <v-form ref="signUpForm" id="signup-form" @submit.prevent="signUp">
            <v-text-field
              class="pt-2"
              label="First Name"
              type="text"
              ref="firstName"
              name="firstName"
              variant="outlined"
              density="comfortable"
              v-model="firstname"
              :rules="firstNameRules"
            ></v-text-field>
            <v-text-field
              class="pt-2"
              label="Last Name"
              type="text"
              ref="lastName"
              name="lastName"
              variant="outlined"
              density="comfortable"
              v-model="lastname"
              :rules="lastNameRules"
            ></v-text-field>

            <v-text-field
              class="pt-2"
              label="Email Id"
              type="email"
              ref="email"
              name="email"
              id="userEmail"
              variant="outlined"
              density="comfortable"
              v-model="email"
              :rules="signUpEmailRules"
            ></v-text-field>

            <v-text-field
              class="pt-2 mt-0"
              type="password"
              label="Password"
              ref="password"
              name="password"
              variant="outlined"
              density="comfortable"
              v-model="password"
              :rules="commonValidationRules.passwordRule"
            ></v-text-field>
            <v-checkbox
              v-model="newsletter"
              label="Subscribe to our newsletter"
              hide-details="true"
              color="indigo"
            ></v-checkbox>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-row no-gutters>
            <v-col cols="12" class="px-7">
              <v-btn
                variant="flat"
                block
                color="primary"
                class="text-white"
                @click="signUp(productDetails.sku)"
              >
                <v-progress-circular
                  :size="20"
                  color="white"
                  indeterminate
                  v-if="inProgress"
                ></v-progress-circular>
                &nbsp;SIGN UP
              </v-btn>
            </v-col>
            <v-col cols="6" class="py-5 pl-7" align="left">
              <span
                class="text-decoration-none cursor-pointer text-primary"
                @click="openSignInDialog()"
                >Sign In</span
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import {
  mdiWindowClose,
  mdiPlus,
  mdiHeartOutline,
  mdiHeart,
  mdiAccountCircleOutline,
} from "@mdi/js";
const personlizeUrl = import.meta.env.VITE_PERSONALIZE_URL;
const retailorId = import.meta.env.VITE_RETAILOR_ID;
import * as easings from "vuetify/lib/services/goto/easing-patterns";
import SnackbarComponent from "@/components/common/SnackbarComponent.vue";
import { createHelpers } from "vuex-map-fields";
const mediaImageUrl = import.meta.env.VITE_APP_MEDIA_IMAGES_API_URL;
const baseUrl = import.meta.env.VITE_BASE_URL;

const { mapFields: mapFieldsAuth } = createHelpers({
  getterType: "auth/getField",
  mutationType: "auth/updateField",
});
const { mapFields: registerInfo } = createHelpers({
  getterType: "registration/getField",
  mutationType: "registration/updateField",
});

import {
  priceFormatter,
  getLable,
  customAttributeValue,
  googleAanalytics,
  googleAanalyticsEvent,
  criteoForCurrentProduct,
  setCategoryNameInLocal,
  commonValidationRules,
} from "@/services/util.service";
import productDefaultImage from "@/assets/defaultProduct.jpg";

export default {
  name: "ProductDetailsComponent",
  props: ["stockFlag", "categoryId"],
  components: { SnackbarComponent },
  data: () => ({
    mdiWindowClose,
    mdiPlus,
    mdiHeartOutline,
    mdiHeart,
    mediaImageUrl,
    mdiAccountCircleOutline,
    variants: [1366, 768, 360],
    attrs: {
      boilerplate: false,
      elevation: 0,
    },
    productDefaultImage: productDefaultImage,
    updateProgress: "",

    dropDownIcon: "mdiPlus",
    iconColor: "text-black",
    isActive: true,
    easings: Object.keys(easings),
    snackbar: {
      show: false,
      message: null,
      color: null,
      timeout: 0,
    },
    fillHeart: "mdiHeart",
    outlineHeart: "mdiHeartOutline",
    myBtnColor: null,
    isOutlineHeart: true,
    isFillHeart: false,
    tab: null,
    selectedQty: "",
    blue: "primary",
    white: "white",
    errorMessageCart: "",
    itemNumber: "193C",
    quantity: "25",
    savFromKey: "Ub07GxNhS3yyoDfO",
    ccId: "766a5cef-6b51-4ab4-9f25-6446c4c8baeb",
    selected: "",
    imagepath: "",
    fav: true,
    menu: false,
    message: false,
    returnedSkuItem: null,
    hints: true,
    priceOptions: [
      { text: "31 ($110.00)", callback: () => "31 ($110.00)" },
      { text: "32 ($110.00)", callback: () => "32 ($110.00)" },
      { text: "33 ($110.00)", callback: () => "33 ($110.00)" },
    ],
    priceFormatter: priceFormatter,

    signInForm: false,
    passwordFields: false,
    emailRules: [
      (v) => !!v || "Email is required",
      (v) => /.+@.+\..+/.test(v) || "Email must be valid",
    ],
    passwordRules: [
      (v) => !!v || "Password is required",
      (v) => (v && v.length >= 8) || "Password must be at least 8 characters",
    ],
    validLogin: false,
    lazy: false,
    signUpForm: false,
    valid: true,
    commonValidationRules: commonValidationRules,
    firstNameRules: [
      (v) => !!v || "First Name is required",
      (v) => (v && v.length >= 3) || "First Name must be at least 3 characters",
    ],
    lastNameRules: [
      (v) => !!v || "Last Name is required",
      (v) => (v && v.length >= 3) || "Last Name must be at least 3 characters",
    ],

    signUpEmailRules: [
      (v) => !!v || "Email Id is required",
      (v) => /.+@.+\..+/.test(v) || "Email Id must be valid",
    ],
  }),
  computed: {
    savedForLaterList: function () {
      return this.$store.state.meSavedForLaterList.savedForLaterList;
    },
    productDetails: function () {
      return this.$store.state.productOne.productDetails;
    },
    categoryListIdWise: function () {
      return this.$store.state.headerCategoryList.categoryListIdWise;
    },
    imageBasePathUrl() {
      return import.meta.env.VITE_IMAGE_PATH_URL;
    },
    staticProduct: function () {
      return this.$store.state.productOne.staticDataOnProduct;
    },
    sku: function () {
      return this.$route.meta.sku
        ? this.$route.meta.sku
        : this.$route.params.sku;
    },
    itemId: function () {
      this.pageScrollUp();
      return this.$route.query.itemId;
    },
    qty: function () {
      return this.$route.query.qty;
    },
    qtyPriceOptions() {
      let vm = this;
      let qtyPriceArray = this.getCustomAttributeLable(
        this.productDetails.customAttribute,
        "qty_price"
      );
      if (qtyPriceArray) {
        let list = JSON.parse(
          this.getCustomAttributeLable(
            this.productDetails.customAttribute,
            "qty_price"
          )
        );
        if (this.qty) {
          vm.selectedQty = this.qty.toString();
        } else {
          vm.selectedQty = list[0].qty;
        }
        vm.validPersonalisedProduct = true;
        return list;
      } else {
        vm.validPersonalisedProduct = false;
      }
      return [];
    },
    numberOptions: function () {
      let vm = this;
      if (this.productDetails.customAttribute) {
        let minSalesQty = this.getCustomAttributeLable(
          this.productDetails.customAttribute,
          "ba_min_sales_qty"
        );
        let qtyIncrements = this.getCustomAttributeLable(
          this.productDetails.customAttribute,
          "ba_qty_increments"
        );
        minSalesQty = Number(minSalesQty) ? Number(minSalesQty) : 1;
        qtyIncrements = Number(qtyIncrements) ? Number(qtyIncrements) : 1;

        let nums = [];
        nums[0] = minSalesQty;
        if (this.qty) {
          vm.selectedQty = Number(this.qty);
        } else {
          vm.selectedQty = minSalesQty;
        }
        for (let i = 1; i <= 98; i++) {
          minSalesQty = minSalesQty + qtyIncrements;
          nums[i] = minSalesQty;
        }
        if (nums.length > 0) return nums;
        else return "";
      } else {
        return "";
      }
    },
    userAuth: function () {
      return this.$store.state.auth.userAuth;
    },
    guestQuoteId: function () {
      return this.$store.state.guestInfo.guestQuoteId;
    },
    cartProductInProgress: function () {
      if (this.userAuth) {
        return this.$store.state.userCartProduct.inProgress;
      } else {
        return this.$store.state.cartProduct.inProgress;
      }
    },
    fetchInProgress: function () {
      return this.$store.state.productOne.fetchInProgress;
    },
    fetchErrorMessage: function () {
      return this.$store.state.productOne.errorMessage;
    },
    productLdJson: function () {
      try {
        let product = null;
        let productsList = window.craneCatalog.products;
        Object.keys(productsList).forEach((key) => {
          if (productsList[key].sku === this.$route.meta.sku) {
            product = productsList[key];
          }
        });
        console.log("product details", product);
        if (product) {
          let data = {
            "@context": "https://schema.org",
            "@type": "Product",
            description: product.d ? product.d : product.n,
            name: product.n,
            sku: product.sku,
            image: this.imageBasePathUrl + product.i,
            brand: {
              "@type": "Brand",
              name: "Crane",
            },
            offers: {
              "@type": "Offer",
              url: baseUrl + "/" + product.uk,
              availability: "https://schema.org/InStock",
              priceCurrency: "USD",
            },
          };

          if (product.sp) {
            data.offers.price = Number(product.sp).toFixed(2);
            return JSON.stringify(data);
          } else if (product.qp) {
            data.offers.price = Number(product.qp).toFixed(2);
            return JSON.stringify(data);
          } else if (product.lp) {
            data.offers.price = Number(product.lp).toFixed(2);
            return JSON.stringify(data);
          } else if (product.p) {
            data.offers.price = Number(product.p).toFixed(2);
            return JSON.stringify(data);
          }
        }
      } catch (error) {
        this.snackbar = {
          message: error.message,
          color: "error",
          show: true,
          timeout: 3000,
        };
      }
      return null;
    },
    ...mapFieldsAuth({
      oneLogin: "one",
      emailLogin: "one.email",
      passwordLogin: "one.password",
      errorMessage: "errorMessage",
      inProgress: "inProgress",
      signInMessage: "signInMessage",
    }),
    ...registerInfo([
      "one",
      "one.firstname",
      "one.lastname",
      "one.newsletter",
      "one.email",
      "one.password",
      "signUpMessage",
    ]),
    rewardsPoints: function () {
      return this.$store.state.productOne.rewardsPoints;
    },
    showZinreloRewards:function () {
      return this.$store.state.layout.showZinreloRewards;
    },
  },
  watch: {
    productDetails() {
      this.$store.dispatch("productOne/resetReward");
      this.imagepath =
        this.productDetails.mediaGalleryEntries &&
        this.productDetails.mediaGalleryEntries.length
          ? this.productDetails.mediaGalleryEntries[0].file
          : "";
      criteoForCurrentProduct(this.productDetails.id);

      if (this.productDetails && this.productDetails.id) {
        this.productReward(this.qty);
      }
    },
    savedForLaterList() {
      this.heartButtonActive();
    },
  },

  methods: {
    productReward(qty) {
      try {
        let price = this.productDetails.price;
        let quantity = qty ? qty : 1;
        let isPersonalize = this.getCustomAttributeValue(
          this.productDetails.customAttribute,
          "is_personalize"
        );
        if (isPersonalize == "1") {
          if (this.qtyPriceOptions.length > 0) {
            if (!qty) {
              quantity = this.qtyPriceOptions[0].qty;
              price = Number(this.qtyPriceOptions[0].price) / Number(quantity);
            } else {
              let priceOption = this.qtyPriceOptions.filter(
                (q) => q.qty == quantity
              )[0];
              price = Number(priceOption.price) / Number(quantity);
            }
          }
        }

        if (qty) {
          this.$store.dispatch("productOne/fetchRewardsPoints", [
            {
              price: price,
              product_id: this.productDetails.sku,
              category: this.productDetails.typeId,
              quantity: quantity,
            },
          ]);
        } else {
          this.$store.dispatch("productOne/fetchRewardsPoints", [
            {
              price: price,
              product_id: this.productDetails.sku,
              category: this.productDetails.typeId,
              quantity: quantity,
            },
          ]);
        }
      } catch (error) {
        console.log(error);
      }
    },

    replacePriceString(str) {
      let val = str.replace('price:"', "");
      val = val.replace('"}', "");
      return val;
    },
    fetchProductImageName(imageFullName) {
      let fileExtention = imageFullName.split("/");
      let filename = fileExtention[fileExtention.length - 1].split(".")[0];
      return filename;
    },
    heartButtonActive() {
      if (
        this.savedForLaterList &&
        this.savedForLaterList.wishlistItems.length
      ) {
        for (let i = 0; i < this.savedForLaterList.wishlistItems.length; i++) {
          if (
            this.productDetails.sku ===
            this.savedForLaterList.wishlistItems[i].product.sku
          ) {
            this.isFillHeart = true;
            this.isOutlineHeart = false;
            this.myBtnColor = "red";
          }
        }
      }
    },

    async addToSaveToLater(sku) {
      if (this.userAuth) {
        await this.$store
          .dispatch("savedForLaterOne/addToSavedForLater", {
            sku: sku,
          })
          .then(() => {
            this.$store.dispatch("meSavedForLaterList/fetchSavedForLaterList");

            this.snackbar = {
              message: "Product added in wishlist successfully",
              color: "success",
              show: true,
              timeout: 3000,
            };
          })
          .catch((error) => {
            this.snackbar = {
              message: error.message,
              color: "error",
              show: true,
              timeout: 3000,
            };
          });
      } else {
        this.signInForm = true;
      }
    },
    async signInPop() {
      this.signInForm = true;
    },
    async signIn(sku) {
      const { valid } = await this.$refs.loginForm.validate();
      if (valid) {
        this.$store.dispatch("auth/changeRedirectFlag", false);
        this.$store.dispatch("auth/login").then((status) => {
          if (status) {
            this.$store.dispatch("auth/changeRedirectFlag", true);
            this.$store.dispatch("accountOne/basicInfo");
            this.$store.dispatch("userCartProduct/cartProductList");

            this.signInForm = false;
          }
        });
      }
    },
    async signUp(sku) {
      const { valid } = await this.$refs.signUpForm.validate();
      if (valid) {
        try {
          this.$store.dispatch("auth/changeRedirectFlag", false);
          await this.$store.dispatch("registration/signUp").then((status) => {
            if (status) {
              this.$store.dispatch("auth/changeRedirectFlag", false);
              this.$store.dispatch("accountOne/basicInfo");
              this.$store.dispatch("userCartProduct/cartProductList");

              this.signUpForm = false;
              this.signInForm = false;
            }
          });
        } catch (err) {
          console.log(err);
        }
      }
    },
    closeSignInForm() {
      this.signInForm = false;
      this.$store.dispatch("auth/changeRedirectFlag", true);
    },
    closeSignUpForm() {
      this.signUpForm = false;
      this.$store.dispatch("auth/changeRedirectFlag", true);
    },
    openSignInDialog() {
      this.signInForm = true;
      this.signUpForm = false;
    },
    openSignUpDialog() {
      this.signInForm = false;
      this.signUpForm = true;
    },
    goToForgetPassword() {
      window.open(`/gate/forgot-password`, "_self");
    },
    setAltImg() {
      event.target.src = this.productDefaultImage;
    },
    getCustomAttributeValue(customAttributes, attributeCode) {
      return customAttributeValue(customAttributes, attributeCode);
    },
    addToCart(sku, productDetails) {
      try {
        let vm = this;
        let qtyNew = this.selectedQty;
        if (this.selectedQty) {
          this.errorMessageCart = "";
          if (this.userAuth) {
            this.$store
              .dispatch("userCartProduct/addToCart", {
                sku: sku,
                qty: this.selectedQty,
                ccid: null,
                price: null,
                personalizeDetails: null,
              })
              .then((res) => {
                googleAanalytics(
                  vm,
                  res,
                  productDetails,
                  qtyNew,
                  "add_to_cart",
                  "Ecommerce",
                  vm.categoryListIdWise
                );
                if (this.categoryId) {
                  setCategoryNameInLocal(this.categoryId, res);
                }

                this.$store.dispatch("userCartProduct/cartProductList");

                this.$store.dispatch("userCartProduct/cartDrawer", true);
                this.snackbar = {
                  message: "Product Added Successfully",
                  color: "success",
                  show: true,
                  timeout: 2000,
                };
              })
              .catch((error) => {
                this.snackbar = {
                  message: error.message,
                  color: "error",
                  show: true,
                  timeout: 2000,
                };
              });
          } else {
            this.$store
              .dispatch("cartProduct/addToCart", {
                sku: sku,
                qty: this.selectedQty,
                ccid: null,
                price: null,
                personalizeDetails: null,
              })
              .then((res) => {
                googleAanalytics(
                  vm,
                  res,
                  productDetails,
                  qtyNew,
                  "add_to_cart",
                  "Ecommerce",
                  vm.categoryListIdWise
                );
                if (this.categoryId) {
                  setCategoryNameInLocal(this.categoryId, res);
                }
                this.snackbar = {
                  message: "Product Added Successfully",
                  color: "success",
                  show: true,
                  timeout: 2000,
                };
              })
              .catch((error) => {
                this.snackbar = {
                  message: error.message,
                  color: "error",
                  show: true,
                  timeout: 2000,
                };
              });
          }
        } else {
          this.errorMessageCart = "Please Select Quantity";
        }
      } catch (error) {
        console.log("error", error);
      }
    },
    updateToCart(productName, sku) {
      try {
        if (this.selectedQty) {
          this.errorMessageCart = "";
          if (this.userAuth) {
            this.$store
              .dispatch("userCartProduct/updateToCart", {
                sku: sku,
                qty: this.selectedQty,
                itemId: this.itemId,
                ccid: null,
                price: null,
                personalizeDetails: null,
              })
              .then((res) => {
                this.snackbar = {
                  message: "Product Updated Successfully",
                  color: "success",
                  show: true,
                  timeout: 3000,
                };
                this.$router.replace({
                  query: { qty: this.selectedQty, itemId: res.item_id },
                });
              })
              .catch((error) => {
                this.snackbar = {
                  message: error.message,
                  color: "error",
                  show: true,
                  timeout: 3000,
                };
              });
          } else {
            this.$store
              .dispatch("cartProduct/updateToCart", {
                sku: sku,
                qty: this.selectedQty,

                itemId: this.itemId,
                ccid: null,
                price: null,
                personalizeDetails: null,
              })
              .then((res) => {
                this.snackbar = {
                  message: "Product Updated Successfully",
                  color: "success",
                  show: true,
                  timeout: 3000,
                };

                this.$router.replace({
                  query: { qty: this.selectedQty, itemId: res.item_id },
                });
              })
              .catch((error) => {
                this.snackbar = {
                  message: error.message,
                  color: "error",
                  show: true,
                  timeout: 3000,
                };
              });
          }
        } else {
          this.errorMessageCart = "Please Select Quantity";
        }
      } catch (error) {
        console.log("error", error);
      }
    },

    getCustomAttributeLable(customAttributes, attributeCode) {
      return getLable(customAttributes, attributeCode);
    },

    setValue(color) {
      return (this.selected = color) && (this.errorMessageCart = "");
    },
    submit() {
      if (this.selected !== "") {
        return (this.selected = "") && (this.errorMessageCart = "");
      } else {
        this.errorMessageCart = "Please Select Color";
      }
    },
    gotoPersonalize(productDetails) {
      try {
        if (
          this.getCustomAttributeLable(
            this.productDetails.customAttribute,
            "builder_version"
          ) === "1.0" ||
          this.getCustomAttributeLable(
            this.productDetails.customAttribute,
            "builder_version"
          ) === ""
        ) {
          googleAanalyticsEvent("personalize_cc", "ecommerce", productDetails);
          this.$router.push({
            name: "CraneConnection",
            params: {
              retailorId: retailorId,
              quantity: this.selectedQty,
              itemNumber: this.sku,
            },
          });
        } else {
          let itemCode = this.itemId ? this.itemId : "new";
          googleAanalyticsEvent(
            "personalize_builder",
            "ecommerce",
            productDetails
          );
          if (this.categoryId) {
            window.open(
              `${personlizeUrl}/builder/session/start/${this.sku}/${this.selectedQty}?categoryId=${this.categoryId}`,
              "_self"
            );
          } else {
            window.open(
              `${personlizeUrl}/builder/session/start/${this.sku}/${this.selectedQty}`,
              "_self"
            );
          }
        }
      } catch (error) {
        console.log("error", error);
      }
    },
    setSelected(part, index) {
      this.imagepath = part;
      this.selected = index;
    },
    pageScrollUp() {
      const data = this.$refs.divOne;
      if (data) {
        this.$smoothScroll({
          scrollTo: data.$el.scrollTop,
          duration: 300,
          easingFunction: "easeInOutCubic",
          updateHistory: false,
        });
      }
    },
  },
  mounted() {
    this.pageScrollUp();
  },
  async created() {
    if (this.itemId) {
      this.updateProgress = true;
      setTimeout(() => {
        this.updateProgress = false;
      }, 3000);
    }
  },
};
</script>
<style lang="scss">
.overflow-button .v-select__slot {
  border-left: 1px solid #dcdddd !important;
  border-right: 1px solid #dcdddd !important;
}
.product-overview-tabs-layout .v-tab__slider {
  height: 4px;
}
.product-overview-tabs-layout .v-tabs-slider-wrapper {
  color: #2850da;
  height: 4px !important;
}
.overflow-button.v-overflow-btn.v-input--is-focused .v-input__slot {
  box-shadow: none !important;
  border-top: 1px solid #dcdddd !important;
  border-bottom: 1px solid #dcdddd !important;
  border-radius: 0px !important;
}

.v-menu__content {
  box-shadow: none !important;
  outline: 1px solid #2850da !important;

  border-radius: 0px !important;
}
.product-overview-tabs {
  color: rgba(77, 70, 70, 0.54);
}
.product-overview-tabs .v-tabs-items {
  background-color: transparent !important;
}
.product-overview-tabs .v-tabs-bar {
  background-color: transparent !important;
  height: 35px !important;
}
.product-overview-tabs .v-slide-group__prev {
  display: none !important;
}
.v-text-field .v-input__control {
  border-radius: unset !important;
}
.productpage-select-button .v-input__control .v-input__slot {
  border: thin solid #8b8b8b !important;
}
.productpage-select-button:hover .v-input__control .v-input__slot {
  border: thin solid #2850da !important;
}
.productpage-select-button.v-input--is-label-active.v-input--is-focused
  .v-select__selection.v-select__selection--comma {
  color: #2850da !important;
}
.productpage-select-button:hover
  .v-select__selection.v-select__selection--comma {
  color: #2850da !important;
}
.productpage-select-button .v-input__control {
  border: thin solid rgba(0, 0, 0, 0.87) !important;
}
.productpage-select-button .v-input__control:hover {
  border: thin solid #2850da !important;
}
.productpage-select-button:hover .v-input__append-inner .v-input__icon .v-icon {
  color: #2850da !important;
}
.productpage-select-button .v-field__outline {
  display: none;
}
.productpage-select-button .v-input__append-inner .v-input__icon .v-icon {
  font-size: 20px !important;
}
.productpage-select-button .v-field__append-inner .v-icon {
  color: #000000 !important;
}
.productpage-select-button .v-field__append-inner .v-icon:hover {
  color: #2850da !important;
}
.productpage-select-button.v-input--is-label-active.v-input--is-focused
  .v-input__control
  .v-input__slot {
  border: thin solid #2850da !important;
}
.productpage-select-button.v-select .v-field .v-field__input {
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.productpage-select-button.v-select--active-menu
  .v-input__control
  .v-field__append-inner {
  -ms-flex-item-align: center;
  align-self: center;
  padding-top: 5px;
  border-left: 1px solid #2850da;
  padding-left: 10px;
  height: 34px;
}
.productpage-select-button
  .v-input__control
  .v-select__slot
  .v-select__selections {
  justify-content: center;
}
.productpage-select-button.v-select.v-text-field input {
  display: none;
}

.productpage-select-button .v-input__control .v-input__slot {
  padding-left: 0px !important;
}
.productpage-part-select-menu.v-menu > .v-overlay__content > .v-list {
  border: thin solid #2850da !important;
  border-radius: 0px !important;
}
</style>
<style src="./ProductDetailComponent.scss" lang="scss"/>