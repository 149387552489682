import { doFetchList } from '@/services/builder_session.service';
import { snakeToCamel } from "@/services/util.service";
export const sessionList = {
    namespaced: true,
    state: {
        list: [],
        inProgress: false
    },
    mutations: {
        setList(state, list) {
            state.list = list;
        },
        inProgress(state, yesOrNo) {
            state.inProgress = yesOrNo
        }
    },
    actions: {
        fetchSessionList: async ({ commit }, { sku, guestId, userId }) => {
            commit('inProgress', true);
            try {
                let response = await doFetchList({ sku, guestId, userId });
                commit('setList', snakeToCamel(response));
                commit('inProgress', false);
                return ({ response: snakeToCamel(response) });
            }
            catch (err) {
                commit('inProgress', false);
                throw err;
            }
        },
    },

}
