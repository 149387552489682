import {
    doFetchList,
    doFetchOne,
    doFetchPageContent
} from '@/services/block.service';
import { getField, updateField } from 'vuex-map-fields';
import { snakeToCamel } from "@/services/util.service";
export const block = {
    namespaced: true,
    state: {
        inProgress: false,
        list: [],
        oneInProgress: false,
        one: {},
        footerBlock: {},
        headerHeroBanner: {},
        callOutsBlock: {},
        heroBlock: {},
        categorySeoContent: {},
        pageContent: {},
        postScriptContent:{}
    },
    getters: {
        getField
    },
    mutations: {
        updateField,

        inProgress(state, yesOrNo) {
            state.inProgress = yesOrNo
        },
        setList(state, list) {
            state.list = list
        },
        oneInProgress(state, yesOrNo) {
            state.oneInProgress = yesOrNo;
        },
        setOne(state, one) {
            state.one = one;
        },
        setFooterBlock(state, block) {
            state.footerBlock = block;
        },
        setHeaderHeroBanner(state, content) {
            state.headerHeroBanner = content;
        },
        setCallOutsBlock(state, content) {
            state.callOutsBlock = content;
        },
        setHeroBlock(state, content) {
            state.heroBlock = content;
        },
        setCategorySeoContent(state, content) {
            state.categorySeoContent = content;
        },
        setPageContent(state, content) {
            state.pageContent = content;
        },
        setPostScriptContent(state, content) {
            state.postScriptContent = content;
        },
    },
    actions: {
        fetchList: async({ commit }) => {
            try {
                commit('inProgress', true);
                const list = await doFetchList();
                if (list) {
                    commit('setList', snakeToCamel(list));
                }
                commit('inProgress', false);
            } catch (error) {
                commit('inProgress', false);
                throw error;
            }
        },
        fetchOne: async({ commit }, blockId) => {
            try {
                commit('inProgress', true);
                const block = await doFetchOne(blockId);
                if (block) {
                    commit('setFooterBlock', snakeToCamel(block));
                }
                commit('inProgress', false);
            } catch (error) {
                commit('inProgress', false);
                throw error;
            }
        },
        fetchEtiquetteGuide: async({ commit }, blockId) => {
            try {
                commit('inProgress', true);
                const one = await doFetchOne(blockId);
                if (one) {
                    commit('setOne', snakeToCamel(one));
                }
                commit('inProgress', false);
            } catch (error) {
                commit('inProgress', false);
                throw error;
            }
        },
        fetchHeaderPromoBanner: async({ commit }, blockId) => {
            try {
                const headerHeroBanner = await doFetchOne(blockId);
                if (headerHeroBanner) {
                    commit('setHeaderHeroBanner', snakeToCamel(headerHeroBanner));
                }
            } catch (error) {
                throw error;
            }
        },
        fetchCalloutsBlock: async({ commit }, callOutsblockId) => {
            try {
                const callOutsBlock = await doFetchOne(callOutsblockId);
                if (callOutsBlock) {
                    commit('setCallOutsBlock', snakeToCamel(callOutsBlock));
                }
            } catch (error) {
                throw error;
            }
        },
        fetchHeroBlock: async({ commit }, heroBlockId) => {
            try {
                const heroBlock = await doFetchOne(heroBlockId);
                if (heroBlock) {
                    commit('setHeroBlock', snakeToCamel(heroBlock));
                }
            } catch (error) {
                throw error;
            }
        },
        fetchPostScriptBlock: async({ commit }, blockId) => {
            try {
                const postScriptContent = await doFetchOne(blockId);
                if (postScriptContent) {
                    commit('setPostScriptContent', snakeToCamel(postScriptContent));
                }
            } catch (error) {
                throw error;
            }
        },
        fetchCategorySeoContent: async({ commit }, blockId) => {
            if (blockId) {
                try {
                    const categorySeoContent = await doFetchOne(blockId);
                    if (categorySeoContent) {
                        commit('setCategorySeoContent', snakeToCamel(categorySeoContent));
                    }
                } catch (error) {
                    throw error;
                }
            } else {
                commit('setCategorySeoContent', { 'content': null });
            }
        },
        fetchPageContent: async({ commit }, pageUrlKey) => {
            try {
                const pageContent = await doFetchPageContent(pageUrlKey);
                if (pageContent) {
                    commit('setPageContent', snakeToCamel(pageContent));
                }
            } catch (error) {
                throw error;
            }
        },
    },

}