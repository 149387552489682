 import { wrapRequest } from './nav';


const vueAppMagenotApiUrl = import.meta.env.VITE_MAGENTO_API_URL;

export const doPostGiftCartItem = wrapRequest((data,quoteId,giftCartDetails) => {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-type': 'application/json',
            'Access-Control-Allow-Origin': '*',
        },
        body: JSON.stringify({
            "cartItem": {
                "sku": data.sku,
                "qty":  data.qty,
                "quoteId": quoteId,
                 "product_option": {
                "extension_attributes": {
                  "giftcard_item_option": {
                   "giftcard_sender_name": giftCartDetails.senderName,
            "giftcard_sender_email": giftCartDetails.senderEmail,
            "giftcard_recipient_name": giftCartDetails.recipientName,
            "giftcard_recipient_email": giftCartDetails.recipientEmail
                  }
                } 
              }  
              }
        })
    };
    return fetch(`${vueAppMagenotApiUrl}/rest/V1/guest-carts/${quoteId}/items`, requestOptions)
});
export const doFetchQuote = wrapRequest(() => {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-type': 'application/json',
            'Access-Control-Allow-Origin': '*',

        }
    };
    return fetch(`${vueAppMagenotApiUrl}/rest/V1/guest-carts`, requestOptions)
});

export const doFetchCartProducts = wrapRequest((quoteId) => {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-type': 'application/json',
            'Access-Control-Allow-Origin': '*',

        }
    };
    return fetch(`${vueAppMagenotApiUrl}/rest/V1/guest-carts/${quoteId}/items`, requestOptions)

});
export const doFetchTotalAmt = wrapRequest(() => {

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-type': 'application/json',
            'Access-Control-Allow-Origin': '*',

        }
    };

    return fetch(`${vueAppMagenotApiUrl}/rest/V1/carts/mine/totals`, requestOptions)

});
export const doDeleteCartProduct = wrapRequest((productId) => {

    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-type': 'application/json',
            'Access-Control-Allow-Origin': '*',
        }
    };
    return fetch(`${vueAppMagenotApiUrl}/rest/V1/carts/mine/items/${productId}`, requestOptions)

});
export const doPutCartItem = wrapRequest((sku, qty, quoteId, itemId) => {

    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-type': 'application/json',
            'Access-Control-Allow-Origin': '*',
        },
        body: JSON.stringify({
            "cartItem": {
                "quote_id": quoteId,
                "sku": sku,
                "qty": qty,
                "item_id": itemId
            }
        })
    };

    return fetch(`${vueAppMagenotApiUrl}/rest/V1/carts/mine/items/${sku}`, requestOptions)

});