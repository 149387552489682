import { authHeader } from './auth';
import { wrapRequest } from './nav';

const vueAppMagenotApiUrl = import.meta.env.VITE_MAGENTO_API_URL;

export const doFetchGiftCardBalance = wrapRequest((giftCardCode) => {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            ...authHeader()
        }
    };
    return fetch(`${vueAppMagenotApiUrl}/rest/V1/carts/mine/checkGiftCard/${giftCardCode}`, requestOptions)
});
export const doPostRedeemGiftCard = wrapRequest((giftCardCode) => {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            ...authHeader()
        },
        body: JSON.stringify({
            
                "code": giftCardCode
            
        })
    };
    return fetch(`${vueAppMagenotApiUrl}/rest/V2/gift-card/redeem`, requestOptions)
});
export const doFetchGiftCardCreditBalance = wrapRequest((customerId) => {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            ...authHeader()
        }
    };
    return fetch(`${vueAppMagenotApiUrl}/rest/V2/customer-balance/${customerId}`, requestOptions)
});
export const doPostStoreCredit = wrapRequest(() => {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            ...authHeader()
        }
    };
    return fetch(`${vueAppMagenotApiUrl}/rest/V1/carts/mine/balance/apply`, requestOptions)
});
export const doFetchBalanceHistory = wrapRequest((customerId) => {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            ...authHeader()
        }
    };
    return fetch(`${vueAppMagenotApiUrl}/rest/V2/balance-history/${customerId}`, requestOptions)
});