
export default [{
        path: '/gate',
        name: 'LoginPage',
        redirect: 'gate/sign-in',
        component: () => import(/* webpackChunkName: "gate" */ '@/components/gate/GateLayout.vue'),
        children: [{
            path: 'sign-in',
            name: 'SignInPage',
            component: () => import(/* webpackChunkName: "gate" */ '@/routes/gate/signin.vue'),
            meta: {
                breadcrumb: {
                    label: 'sign in',
                },
            }
        },
        {
            path: 'sign-up',
            name: 'SignUpPage',
            component: () => import(/* webpackChunkName: "gate" */ '@/routes/gate/signup.vue'),
            meta: {
                breadcrumb: {
                    label: 'sign up',
                },
            }
        },
        {
            path: 'forgot-password',
            name: 'forgot-password',
            component: () => import(/* webpackChunkName: "gate" */ '@/routes/gate/forgot.password.vue'),
            meta: {
                breadcrumb: {
                    label: 'Forgot Password',
                },
            }
        },
        {
            path: 'reset-password/:token/:emailId?',
            name: 'reset-password',
            component: () => import(/* webpackChunkName: "gate" */ '@/routes/gate/reset.password.vue'),
            meta: {
                breadcrumb: {
                    label: 'Reset Password',
                },
            }
        },
        ]
    },
    {
        path: '/:pathMatch(.*)',
        redirect: '/gate/sign-in'
    }]
