
import CategoryPage from '@/routes/crane/category_page.vue';
import LandingPage from '@/routes/crane/landing_page.vue';
import ProductPage from '@/routes/crane/product_page.vue';

let seoRoutes = window.seoRoutes ? window.seoRoutes({ cp: CategoryPage , pp: ProductPage, lp: LandingPage }) : [];

let seoUrls = window.urlRewrites ? window.urlRewrites : [];


const vueHomePageUrl = import.meta.env.VITE_HOME_PAGE_URL;
let defaultRoutes = [{
    path: '',
    name: 'HomePage',
    component: () => import( /* webpackChunkName: "craneMain" */'@/routes/crane/home_page.vue'),
    meta: {
        breadcrumb: {
            label: 'Home',
        },
    }
},
{
    path: 'search',
    name: 'SearchPage',
    component: () => import( /* webpackChunkName: "craneMain" */'@/routes/crane/search_page.vue'),
    meta: {
        breadcrumb: {
            label: 'SearchPage Page',
        },
    }
},
{
    path: 'frequently-asked-questions',
    name: 'FaqPage',
    component: () => import( /* webpackChunkName: "craneMain" */'@/routes/crane/faq_page.vue'),
    meta: {
        "canonical": true,
        breadcrumb: {
            label: 'FAQ Page',
        },
    }
},
{
    path: 'shortcuts',
    name: 'shortcutsPage',
    component: () => import( /* webpackChunkName: "craneMain" */'@/routes/crane/shortcuts_page.vue'),
    meta: {
        "canonical": true,
        breadcrumb: {
            label: 'shortcuts Page',
        },
    }
},
{
    path: 'store-locator',
    name: 'StoreLocatore',
    component: () => import( /* webpackChunkName: "craneMain" */'@/routes/crane/store_locator_page.vue'),
    meta: {
        "canonical": true,
        breadcrumb: {
            label: 'Store Locatore',
        },
    }
},

{
    path: 'crane-connection/:retailorId/:quantity/:itemNumber/:ccid?/:savItemId?',
    name: 'CraneConnection',
    component: () => import( /* webpackChunkName: "craneMain" */'@/routes/crane/crane_connection.vue'),
    meta: {
        breadcrumb: {
            label: 'Crane Connection',
        },
    }
},

{
    path: 'careers',
    name: 'Careers',
    component: () => import( /* webpackChunkName: "craneMain" */'@/routes/crane/careers.vue'),
    meta: {
        "canonical": true,
        breadcrumb: {
            label: 'Careers',
        },
    }
},
{
    path: 'crane-concierge',
    name: 'CraneConcierge',
    component: () => import( /* webpackChunkName: "craneMain" */'@/routes/crane/crane_concierge.vue'),
    meta: {
        "canonical": true,
        breadcrumb: {
            label: 'Crane Concierge',
        },
    }
},
{
    path: 'returns',
    name: 'ReturnPolicy',
    component: () => import( /* webpackChunkName: "craneMain" */'@/routes/crane/return_policy.vue'),
    meta: {
        "canonical": true,
        breadcrumb: {
            label: 'Return Policy',
        },
    }
},
{
    path: 'privacy-policy',
    name: 'PrivacyPolicy',
    component: () => import( /* webpackChunkName: "craneMain" */'@/routes/crane/privacy_policy.vue'),
    meta: {
        "canonical": true,
        breadcrumb: {
            label: 'Privacy Policy',
        },
    }
},
{
    path: 'terms-conditions',
    name: 'TermsAndConditions',
    component: () => import( /* webpackChunkName: "craneMain" */'@/routes/crane/terms_conditions.vue'),
    meta: {
        "canonical": true,
        breadcrumb: {
            label: 'Terms Conditions',
        },
    }
},
{
    path: 'logout-success',
    name: 'LogOutSuccess',
    component: () => import( /* webpackChunkName: "craneMain" */'@/routes/crane/logout_success.vue'),
    meta: {
        breadcrumb: {
            label: 'LogOut Success',
        },
    }
},
{
    path: 'cc-action/:ccid/:mode/:quantity/:itemNumber/:orderTotal/:itemId?',
    name: 'CCAction',
    component: () => import( /* webpackChunkName: "craneMain" */'@/routes/crane/cc_action.vue'),
    meta: {
        breadcrumb: {
            label: 'CC Action',
        },
    }
},
{
    path: 'cc-update/:ccid/:mode/:quantity/:itemNumber/:itemId/:orderTotal?',
    name: 'CCUpdate',
    component: () => import( /* webpackChunkName: "craneMain" */'@/routes/crane/cc_update.vue'),
    meta: {
        breadcrumb: {
            label: 'CC Update',
        },
    }
},
{
    path: 'cc-cancel/:mode/:itemId/:ccid?/:itemNumber?',
    name: 'CCCancel',
    component: () => import( /* webpackChunkName: "craneMain" */'@/routes/crane/cc_cancel.vue'),
    meta: {
        breadcrumb: {
            label: 'CC Cancel',
        },
    }
},
{
    path: 'cc-error',
    name: 'CCError',
    component: () => import( /* webpackChunkName: "craneMain" */'@/routes/crane/cc_error.vue'),
    meta: {
        breadcrumb: {
            label: 'CC Error',
        },
    }
},
{
    path: 'etiquette-guide',
    name: 'EtiquetteGuide',
    component: () => import( /* webpackChunkName: "craneMain" */'@/routes/crane/etiquette_guide.vue'),
    meta: {
        "canonical": true,
        breadcrumb: {
            label: 'Etiquette Guide',
        },
    }
},
{
    path: 'accessibility',
    name: 'Accessibility',
    component: () => import( /* webpackChunkName: "craneMain" */'@/routes/crane/accessibility.vue'),
    meta: {
        "canonical": true,
        breadcrumb: {
            label: 'Accessibility',
        },
    }
},
{
    path: 'about-us',
    name: 'AboutUs',
    component: () => import( /* webpackChunkName: "craneMain" */'@/routes/crane/about_us.vue'),
    meta: {
        "canonical": true,
        breadcrumb: {
            label: 'About Us',
        },
    }
},
{
    path: 'printing-processes',
    name: 'PrintingProcesses',
    component: () => import( /* webpackChunkName: "craneMain" */'@/routes/crane/printing_processes.vue'),
    meta: {
        "canonical": true,
        breadcrumb: {
            label: 'Our Printing Process',
        },
    }
},
{
    path: 'our-papers',
    name: 'OurPapers',
    component: () => import( /* webpackChunkName: "craneMain" */'@/routes/crane/our_papers.vue'),
    meta: {
        "canonical": true,
        breadcrumb: {
            label: 'OurPapers',
        },
    }
},
{
    path: 'order-success/:orderNumber',
    name: 'OrderSuccess',
    component: () => import( /* webpackChunkName: "craneMain" */'@/routes/crane/order_success.vue'),
    meta: {
        breadcrumb: {
            label: 'Order Success',
        },
    }
},
{
    path: 'maintenance/:disableId?',
    name: 'Maintenance-New',
    component: () => import( /* webpackChunkName: "craneMain" */'@/routes/crane/maintenance.vue'),
    meta: {
        "canonical": true,
        breadcrumb: {
            label: 'Maintenance',
        },
    }
}, {
    path: 'shipping-and-delivery',
    name: 'ShippingAndDelivery',
    component: () => import( /* webpackChunkName: "craneMain" */'@/routes/crane/shipping_delivery.vue'),
    meta: {
        "canonical": true,
        breadcrumb: {
            label: 'Shipping and Delivery',
        },
    }
},
{
    path: 'checkout',
    name: 'CheckoutPage',
    component: () => import( /* webpackChunkName: "craneMain" */'@/routes/crane/checkout_page.vue'),
    meta: {
        breadcrumb: {
            label: 'checkout',
        },
    }
},
{
    path: 'cart-products',
    name: 'CartProducts',
    component: () => import( /* webpackChunkName: "craneMain" */'@/routes/crane/cart_products.vue'),
    meta: {
        "canonical": true,
        breadcrumb: {
            label: 'cart products',
        },
    }
},
{
    path: 'introducing-crane-x-sarah-flint',
    name: 'LandingPage',
    component: () => import( /* webpackChunkName: "craneMain" */'@/routes/crane/landing_page.vue'),
    meta: { "id": 149, "canonical": true, "breadcrumb": { "label": "Crane x Sarah Flint" } }
},
{
    path: 'gift-guide',
    name: 'GiftGuide',
    component: () => import( /* webpackChunkName: "craneMain" */'@/routes/crane/gift_guide.vue'),
    meta: {
        "canonical": true,
        breadcrumb: {
            label: 'GiftGuide',
        },
    }
},
{
    path: 'playground',
    name: 'Playground',
    component: () => import( /* webpackChunkName: "craneMain" */'@/routes/crane/playground.vue'),
    meta: {
        breadcrumb: {
            label: 'Playground Page',
        },
    }
    },

    {
        path: 'shipping/tracking/popup',
        name: 'PopupRedirect',
        component: () => import( /* webpackChunkName: "craneMain" */'@/routes/crane/popupRedirect.vue'),
        meta: {
            "canonical": true,
            breadcrumb: {
                label: 'Popup Redirect',
            },
        }
    },
    {
        path: 'customer/account/createPassword',
        name: 'Create Password',
        component: () => import(/* webpackChunkName: "craneMain" */ '@/routes/crane/customerCreatePassword.vue'),
        meta: {
            breadcrumb: {
                label: 'Create Password',
            },
        }
    },

    {
        path: 'preference-center',
        name: 'PreferenceCenterPage',
        component: () => import( /* webpackChunkName: "craneMain" */'@/routes/crane/preference_center_page.vue'),
        meta: {
            breadcrumb: {
                label: 'Preference Center Page',
            },
        }
    },
    { path: '/giftcard', redirect: '/me/gift-card-page' },
    { path: '/catalogsearch/result', redirect: '/search' },
    {
        path: 'business-card/:builderSessionId',
        name: 'business-card',
        component: () => import( /* webpackChunkName: "craneMain" */'@/routes/crane/business_card.vue'),
        meta: {
            "canonical": true,
            breadcrumb: {
                label: 'business-card',
            },
        }
    },
    {
        path: 'rewards/:show?',
        name: 'ZinreloRewards',
        component: () => import( /* webpackChunkName: "craneMain" */'@/routes/crane/zinrelo_rewards.vue'),
        meta: {
            "canonical": true,
            breadcrumb: {
                label: 'rewards',
            },
        }
    },

//{
    //path: 'shipping-and-delivery-cms',
    //name: 'ShippingAndDeliveryCms',
    //component: () => import( /* webpackChunkName: "craneMain" */'@/routes/crane/shipping_delivery_cms.vue'),
    //meta: {
      //  "canonical": true,
       // breadcrumb: {
        //    label: 'Shipping and Delivery',
        //},
   // }
//}
];

let shopRoutes = [{
    path: '/',
    name: 'ShopPage',
    component: () => import( /* webpackChunkName: "craneMain" */'@/routes/crane/home_page.vue'),
    meta: {
        "canonical": true,
        breadcrumb: {
            label: 'Home',
        },
    }
},
// {
//     path: 'our-papers',
//     name: 'OurPapers',
//     component: () => import( /* webpackChunkName: "craneMain" */'@/routes/crane/product_page.vue'),
//     meta: {
//         "canonical": true,
//         breadcrumb: {
//             label: 'OurPapers',
//         },
//     }
// },
// {
//     path: 'product-details/:sku',
//     name: 'ProductPage',
//     component: () => import( /* webpackChunkName: "craneMain" */'@/routes/crane/product_page.vue'),
//     meta: {
//         breadcrumb: {
//             label: 'Product Page',
//         },
//     }
// },
{
    path: '*',
    name: 'RouteNotFound',
    component: () => import( /* webpackChunkName: "craneMain" */'@/routes/crane/route_not_found.vue'),
}

];

let childrenRoutes = seoRoutes.concat(defaultRoutes);


export default [{
        path: '/',
        name: 'Main',
        component: () => import( /* webpackChunkName: "craneMain" */'@/components/layout/Layout.vue'),
        children: childrenRoutes
    }, {
        path: '/shop',
        name: 'Shop',
        component: () => import( /* webpackChunkName: "craneMain" */'@/components/layout/Layout.vue'),
        children: shopRoutes,
    },
    {
        path: '/:pathMatch(.*)',
        name: 'RouteNotFound',
        component: () => import( /* webpackChunkName: "craneMain" */'@/routes/crane/route_not_found.vue'),
        beforeEnter: (to) => {

            let regEx = new RegExp(`^${to.path}$`);
            let seoData = seoUrls.filter(data => {
                let url=data.oldUrl;
                if(url.charAt(0)!=="/")
                url="/"+url;
                return url.match(regEx);
            })[0];
            if (seoData){
                let queryString = Object.keys(to.query).map(key => key + '=' + to.query[key]).join('&');
                location.href = seoData.newUrl+ "?"+queryString;
            }
            else {
                let q = to.path.replace("/", '');
                location.href = vueHomePageUrl + "/search?q=" + q;
            }
        }
    }
    ]
