
export const doIncreaseCountsForFilter = ({ product, availableFilter }) => {

    const pv = product[availableFilter.prop]
    if (pv) {
        const existingCount = availableFilter.counts.find(element => element.value == pv)
        if (existingCount !== undefined && existingCount != null) {
            existingCount.count = existingCount.count + 1
        }
        else {
            availableFilter.counts.push({ value: pv, count: 1 })
        }
    }

}

export const doCheckProductForEnabledFilters = ({ product, enabledFilters }) => {
    if (enabledFilters.length) {
        var filteredCount = 0;
        for (var f = 0; f < enabledFilters.length; f++) {
            const pv = product[enabledFilters[f].prop]
            if (enabledFilters[f].label == 'Category') {
                filteredCount++;
                continue  
            }
            if (pv == enabledFilters[f].value)  
            {
                filteredCount++;
                
            }
        }
        return (filteredCount == enabledFilters.length ? true : false);
    }
    else
        return true;
}

export const doCountProductAttributes = ({ product, availableFilters, attributesMap }) => {
 
    for (var a = 0; a < attributesMap.length; a++) {
        var avAttribute = attributesMap[a]
        var avFilter = availableFilters.find(element => element.label == avAttribute.label)
        if (avFilter === undefined || avFilter == null) {
            avFilter = {
                label: avAttribute.label,
                prop: avAttribute.prop,
                counts: [],
                options: avAttribute.options
            }
            availableFilters.push(avFilter)
        }
        doIncreaseCountsForFilter({ product, availableFilter: avFilter })
    }
}

export const doFilterItems = ({ items, enabledFilters, availableFilters, attributesMap }) => {
    const matchingItems = []
    for (var i = 0; i < items.length; i++) {
        var product = items[i]
        const isValid = doCheckProductForEnabledFilters({ product, enabledFilters })
        if (isValid) {
            doCountProductAttributes({ product, availableFilters, attributesMap })
            matchingItems.push(product)
        }
    }
    return matchingItems
}
