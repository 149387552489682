import { doFetchGiftCardBalance, doPostRedeemGiftCard, doFetchGiftCardCreditBalance, doPostStoreCredit, doFetchBalanceHistory } from '@/services/store_credit.service';
import { getField, updateField } from 'vuex-map-fields';
import { snakeToCamel } from '@/services/util.service';

export const storeCredit = {
    namespaced: true,
    state: {
        inProgress: false,
        giftCardBalance: '',
        redeemCard: '',
        giftCardCreditBalance: {},
        applyStoreCredit: '',
        storeCreditInProgress: false,
        balanceHistory: {}
    },
    getters: {
        getField,
    },
    mutations: {
        updateField,

        inProgress(state, yesOrNo) {
            state.inProgress = yesOrNo
        },
        storeCreditInProgress(state, yesOrNo) {
            state.storeCreditInProgress = yesOrNo
        },
        setGiftCardBalance(state, Balance) {
            state.giftCardBalance = Balance;
        },
        setGiftCardDetails(state, Details) {
            state.redeemCard = Details;
        },
        setGiftCardCreditBalance(state, Details) {
            state.giftCardCreditBalance = Details;
        },
        setApplyStoreCredit(state, credit) {
            state.applyStoreCredit = credit;
        },
        setBalanceHistory(state, balance) {
            state.balanceHistory = balance;
        }
    },
    actions: {
        redeemGiftCard: async ({ commit }, giftCardCode) => {
            commit('inProgress', true);
            try {
                const redeemCard = await doPostRedeemGiftCard(giftCardCode);
                if (redeemCard) {
                    commit('setGiftCardDetails', snakeToCamel(redeemCard));
                    commit('inProgress', false);
                }
            } catch (err) {
                commit('inProgress', false);
                throw err;
            }
        },
        fetchGiftCardBalance: async ({ commit }, giftCardCode) => {
            commit('inProgress', true);
            try {
                const giftCardBalance = await doFetchGiftCardBalance(giftCardCode);
                if (giftCardBalance) {
                    commit('setGiftCardBalance', snakeToCamel(giftCardBalance));
                    commit('inProgress', false);
                } else {
                    commit('setGiftCardBalance', snakeToCamel(giftCardBalance));
                    commit('inProgress', false);
                }
            } catch (err) {
                commit('inProgress', false);
                throw err;
            }
        },
        fetchGiftCardCreditBalance: async ({ commit }, customerId) => {
            commit('inProgress', true);
            try {
                const giftCardCreditBalance = await doFetchGiftCardCreditBalance(customerId);
                if (giftCardCreditBalance) {
                    commit('setGiftCardCreditBalance', snakeToCamel(giftCardCreditBalance));
                    commit('inProgress', false);
                }
            } catch (err) {
                commit('inProgress', false);
                throw err;
            }
        },
        fetchBalanceHistory: async ({ commit }, customerId) => {
            commit('inProgress', true);
            try {
                const balanceHistory = await doFetchBalanceHistory(customerId);
                if (balanceHistory) {
                    commit('setBalanceHistory', snakeToCamel(balanceHistory));
                    commit('inProgress', false);
                }
            } catch (err) {
                commit('inProgress', false);
                throw err;
            }
        },
        applyStoreCredit: async ({ commit }) => {
            commit('storeCreditInProgress', true);
            try {
                const applyStoreCredit = await doPostStoreCredit();
                if (applyStoreCredit) {
                    commit('setApplyStoreCredit', snakeToCamel(applyStoreCredit));
                    commit('storeCreditInProgress', false);
                }
            } catch (err) {
                commit('storeCreditInProgress', false);
                throw err;
            }
        },

    }
}