import {
    doPostCartItem,
    doFetchQuote,
    doFetchCartProducts,
    doFetchTotalAmt,
    doDeleteCartProduct,
    doPutCartItem,
    doFetchCoupon,
    doApplyDiscountCode,
    doRemoveDiscountCode,
    doFetchCartId,
    doPostGiftCartItem,
    doPutGiftCartItem,
    doPostConfiguredCartItem,
    doPutConfiguredCartItem,
    doFetchGiftMessage,
    doPutCConectionCartItem
} from '@/services/guest_cart.service';
import {
    doFetchList
} from '@/services/builder_session_item.service';
import { getField, updateField } from 'vuex-map-fields';
import { getCookieByName, setCookieByName } from '@/services/auth';
import {
    criteoViewBasket, listrakCartList, listrakCartListClear, snakeToCamel
} from "@/services/util.service";

const crGuestQuoteId = import.meta.env.VITE_GUEST_QUOTE_ID;

export const cartProduct = {
    namespaced: true,
    state: {
        cartDrawer: false,
        inProgress: false,
        fetchInProgress: false,
        cartProduct: [],
        cartProductList: [],
        productTotalAmt: [],
        productMedia: {},
        discountCode: null,
        errorMessage: null,
        cartProductsProgress: false,
        cartTotalAmtProgress: false,
        giftCartDetailsGuest: {
            "senderNameGuest": "",
            "senderEmailGuest": "",
            "recipientNameGuest": "",
            "recipientEmailGuest": "",
            "senderMessageGuest": "", 
            "giftCardAmtGuest": ""
        },
        giftMessageData: {}
        
    },
    getters: {
        getField
    },
    mutations: {
        updateField,

        inProgress(state, yesOrNo) {
            state.inProgress = yesOrNo
        },
        cartProductsProgress(state, yesOrNo) {
            state.cartProductsProgress = yesOrNo
        },
        cartTotalAmtProgress(state, yesOrNo) {
            state.cartTotalAmtProgress = yesOrNo
        },
        setCartDrawer(state, yesOrNo) {
            state.cartDrawer = yesOrNo
        },
        fetchInProgress(state, yesOrNo) {
            state.fetchInProgress = yesOrNo
        },
        setCartProduct(state, product) {
            state.cartProduct = product
        },
        setCartProductList(state, list) {
            state.cartProductList = list 
        },
        setCriteoViewBasket(state) {
            criteoViewBasket(state.cartProductList);
        },
        setListrakCartList(state) {
            listrakCartList(state.cartProductList);
        },
        setListrakCartListClear() {
            listrakCartListClear()
        },
        setProductAmt(state, amt) {
            state.productTotalAmt = amt;
        },
        setProductsMedia(state, media) {
            state.productMedia = media
        },
        setCouponCode(state, code) {
            state.discountCode = code.toString();
        },
        setErrorMessage(state, err) {
            state.errorMessage = err
        },
        setGiftMessageData(state, giftMessage) {
            state.giftCartDetailsGuest.senderNameGuest = giftMessage[0].productOption.infoBuyRequest.giftcardSenderName,
                state.giftCartDetailsGuest.senderEmailGuest = giftMessage[0].productOption.infoBuyRequest.giftcardSenderEmail,
                state.giftCartDetailsGuest.recipientNameGuest = giftMessage[0].productOption.infoBuyRequest.giftcardRecipientName,
                state.giftCartDetailsGuest.recipientEmailGuest = giftMessage[0].productOption.infoBuyRequest.giftcardRecipientEmail,
                state.giftCartDetailsGuest.senderMessageGuest = giftMessage[0].productOption.infoBuyRequest.giftcardMessage,
                state.giftCartDetailsGuest.giftCardAmtGuest = (giftMessage[0].productOption.infoBuyRequest.giftcardAmount)
        },

    },
    actions: {
        addToCart: async ({ commit, dispatch }, data) => {
            try {
                let cartProduct;
                commit('inProgress', true);
                let guestQuoteId = getCookieByName(crGuestQuoteId);
                if (!guestQuoteId) {
                    const guestQuote = await doFetchQuote();
                    setCookieByName(crGuestQuoteId, guestQuote)
                    cartProduct = await doPostCartItem(data.sku, data.qty, data.ccid, data.price, data.personalizeDetails, guestQuote);
                    if (cartProduct) {
                        commit('setCartProduct', (cartProduct));
                        dispatch('cartProductList', guestQuote);
                        dispatch('productTotalAmt', guestQuote);
                    }
                } else {
                    cartProduct = await doPostCartItem(data.sku, data.qty, data.ccid, data.price, data.personalizeDetails, guestQuoteId);
                    if (cartProduct) {
                        commit('setCartProduct', (cartProduct));
                        dispatch('cartProductList', guestQuoteId);
                        dispatch('productTotalAmt', guestQuoteId);
                    }
                }
                commit('inProgress', false);
                commit('setCartDrawer', true);
                return cartProduct;
            } catch (err) {
                commit('inProgress', false);
                throw err;
            }
        },
        addToConfiguredCart: async ({ commit, dispatch }, data) => {
            try {
                commit('inProgress', true);
                let cartProduct;
                let guestQuoteId = getCookieByName(crGuestQuoteId);
                if (!guestQuoteId) {
                    const guestQuote = await doFetchQuote();
                    setCookieByName(crGuestQuoteId, guestQuote)
                    cartProduct = await doPostConfiguredCartItem(data.sku, data.qty, data.typeId, data.optionId, data.optionValue, guestQuote);
                    if (cartProduct) {
                        commit('setCartProduct', (cartProduct));
                        dispatch('cartProductList', guestQuote);
                        dispatch('productTotalAmt', guestQuote);
                    }
                } else {
                    cartProduct = await doPostConfiguredCartItem(data.sku, data.qty, data.typeId, data.optionId, data.optionValue, guestQuoteId);
                    if (cartProduct) {
                        commit('setCartProduct', (cartProduct));
                        dispatch('cartProductList', guestQuoteId);
                        dispatch('productTotalAmt', guestQuoteId);
                    }
                }
                commit('inProgress', false);
                commit('setCartDrawer', true);
                return cartProduct;
            } catch (err) {
                commit('inProgress', false);
                throw err;
            }
        },
        addToGiftCart: async ({ commit, dispatch, state }, data) => {
            try {
                commit('inProgress', true);
                let cartProduct;
                let guestQuoteId = getCookieByName(crGuestQuoteId);
                if (!guestQuoteId) {
                    const guestQuote = await doFetchQuote();
                    setCookieByName(crGuestQuoteId, guestQuote)
                    cartProduct = await doPostGiftCartItem(data, guestQuote, state.giftCartDetailsGuest);
                    if (cartProduct) {
                        commit('setCartProduct', (cartProduct));
                        dispatch('cartProductList', guestQuote);
                        dispatch('productTotalAmt', guestQuote);
                    }
                } else {
                    cartProduct = await doPostGiftCartItem(data, guestQuoteId, state.giftCartDetailsGuest);
                    if (cartProduct) {
                        commit('setCartProduct', (cartProduct));
                        dispatch('cartProductList', guestQuoteId);
                        dispatch('productTotalAmt', guestQuoteId);
                    }
                }

                commit('inProgress', false);
                commit('setCartDrawer', true);
                return cartProduct;
            } catch (err) {
                commit('inProgress', false);
                throw err;
            }
        },
        productTotalAmt: async ({ commit }, guestQuoteId) => {
            commit('cartTotalAmtProgress', true);
            doFetchTotalAmt(guestQuoteId).then((productTotalAmt) => {
                commit('cartTotalAmtProgress', false)
                commit('setProductAmt', snakeToCamel(productTotalAmt))
            })
                .catch(() => commit('cartTotalAmtProgress', false))

        },
        cartProductList: async ({ commit }, guestQuoteId) => {
            try {
                commit('setCartProductList', []);
                commit('cartProductsProgress', true);
                let cartProductDetails = await doFetchCartId(guestQuoteId)
                if (cartProductDetails) {

                    let cartProductList = await doFetchCartProducts(cartProductDetails.id);
                    //let newCartProductList=[];
                    if (cartProductList.length > 0) {

                        for (let i = 0; i < cartProductList.length; i++) {
                            if (cartProductList[i] && cartProductList[i].options && cartProductList[i].options.options) {
                                let optionsList = cartProductList[i].options.options;
                                let builderSessionOne = null
                                builderSessionOne = optionsList.find(
                                    (x) => x.label == "BuilderSessionId"
                                );

                                if (builderSessionOne) {
                                    let sessionId = builderSessionOne.option_value;
                                    if (sessionId) {
                                        const sessionData = await doFetchList({ sessionId });
                                        if (sessionData && sessionData.length > 0) {
                                            
                                            let thumbnailUrl = sessionData.map(a => a.thumbnail_url);
                                            cartProductList[i]['thumbnail_url'] = thumbnailUrl; 
                                        }
                                    }
                                }

                            }

                        }
                    }
                    commit('setCartProductList', snakeToCamel(cartProductList))
                    commit('cartProductsProgress', false);
                    return snakeToCamel(cartProductList)
                }
            }
            catch (err) {
                commit('cartProductsProgress', false);
            }
        },
        removeCartProduct: async ({ commit, dispatch }, productId) => {
            let guestQuoteId = getCookieByName(crGuestQuoteId);
            try {
                commit('cartProductsProgress', true);
                commit('cartTotalAmtProgress', true);
                let trueOrFalse = await doDeleteCartProduct(productId, guestQuoteId);
                if (trueOrFalse) {
                    commit('cartProductsProgress', false);
                    commit('cartTotalAmtProgress', true);
                    commit('setCartDrawer', false);
                    await dispatch('cartProductList', guestQuoteId);
                    dispatch('productTotalAmt', guestQuoteId);
                    dispatch('setListrakCartListClear')
                }
            } catch (err) {
                commit('cartProductsProgress', false);
                commit('cartTotalAmtProgress', true)
                throw err;
            }
        },
        updateToCart: async ({ commit, dispatch }, data) => {
            let guestQuoteId = getCookieByName(crGuestQuoteId);
            try {
                commit('cartProductsProgress', true);
                const cartProduct = await doPutCartItem(data.sku, data.qty, guestQuoteId, data.itemId, data.ccid, data.price, data.personalizeDetails, data.optionId, data.optionValue);
                if (cartProduct) {
                    commit('cartProductsProgress', false);
                    if (data.routeName != 'CartProducts') {
                        commit('setCartDrawer', true);
                    }
                    commit('setCartProduct', (cartProduct));
                    dispatch('cartProductList', guestQuoteId);
                    dispatch('productTotalAmt', guestQuoteId);
                    return (cartProduct);
                }
            } catch (err) {
                commit('cartProductsProgress', false);
                dispatch('cartProductList', guestQuoteId);
                dispatch('productTotalAmt', guestQuoteId);
                throw err;
            }
        },
        updateToCConectionCart: async ({ commit }, data) => {
            try {
                commit('cartProductsProgress', true);
                const response = await doPutCConectionCartItem(data.sku, data.qty, data.ccid);
                commit('cartProductsProgress', false);
                return (response);

            } catch (err) {
                commit('cartProductsProgress', false);
                throw err;
            }
        },
        updateToConfiguredCart: async ({ commit, dispatch }, data) => {
            let guestQuoteId = getCookieByName(crGuestQuoteId);
            try {
                commit('cartProductsProgress', true);
                const cartProduct = await doPutConfiguredCartItem(data.sku, data.qty, guestQuoteId, data.itemId, data.optionId, data.optionValue);
                if (cartProduct) {
                    commit('cartProductsProgress', false);
                    if (data.routeName != 'CartProducts') {
                        commit('setCartDrawer', true);
                    }
                    commit('setCartProduct', (cartProduct));
                    dispatch('cartProductList', guestQuoteId);
                    dispatch('productTotalAmt', guestQuoteId);
                    return cartProduct
                }
            } catch (err) {
                dispatch('cartProductList', guestQuoteId);
                dispatch('productTotalAmt', guestQuoteId);
                commit('cartProductsProgress', false);
                throw err;
            }
        },
        updateToGiftCart: async ({ commit, dispatch, state }, data) => {
            let guestQuoteId = getCookieByName(crGuestQuoteId);

            try {
                commit('inProgress', true);
                const cartProduct = await doPutGiftCartItem(data, guestQuoteId, state.giftCartDetailsGuest);
                if (cartProduct) {
                    commit('inProgress', false);
                    if (data.routeName != 'CartProducts') {
                        commit('setCartDrawer', true);
                    }
                    commit('setCartProduct', (cartProduct));
                    dispatch('cartProductList', guestQuoteId);
                    dispatch('productTotalAmt', guestQuoteId);
                    return cartProduct
                }
            } catch (err) {
                dispatch('cartProductList', guestQuoteId);
                dispatch('productTotalAmt', guestQuoteId);
                commit('inProgress', false);
                throw err;
            }

        },
        cartDrawer: async ({ commit }, flag) => {
            commit('setCartDrawer', flag)
        },
        fetchCartCoupon: async ({ commit }, guestQuoteId) => {
            commit('fetchInProgress', true);
            try {
                let coupon = await doFetchCoupon(guestQuoteId);
                if (coupon) {
                    commit('fetchInProgress', false);
                    commit('setCouponCode', coupon);
                }
            } catch (err) {
                commit('fetchInProgress', false);
                throw err;
            }
        },
        applyDiscountCode: async ({ commit, dispatch }, data) => {
            commit('fetchInProgress', true);
            try {
                let coupon = await doApplyDiscountCode(data.discountTextCode, data.guestQuoteId);
                if (coupon) {
                    commit('fetchInProgress', false);
                    dispatch('fetchCartCoupon', data.guestQuoteId);
                }
            } catch (err) {
                commit('fetchInProgress', false);
                throw err;
            }
        },
        removeDiscountCode: async ({ commit, dispatch }, data) => {
            commit('fetchInProgress', true);
            try {
                let coupon = await doRemoveDiscountCode(data.guestQuoteId);
                if (coupon) {
                    commit('fetchInProgress', false);
                    dispatch('fetchCartCoupon', data.guestQuoteId);
                }
            } catch (err) {
                commit('fetchInProgress', false);
                throw err;
            }
        },
        fetchGiftMessage: async ({ commit }, data) => {
            let guestQuoteId = getCookieByName(crGuestQuoteId);
            commit('fetchInProgress', true);
            try {
                await doFetchCartId(guestQuoteId).then(async (cartProductDetails) => {
                    const giftMessageData = await doFetchGiftMessage(cartProductDetails.id, data.itemId);
                    if (giftMessageData) {
                        commit('fetchInProgress', false);
                        commit('setGiftMessageData', snakeToCamel(giftMessageData));
                    }
                })

            } catch (err) {
                commit('fetchInProgress', false);
                throw err;
            }
        },
        setCriteoViewBasket: async ({ commit, state }) => {
            if (state.cartProductList.length > 0) {
                commit('setCriteoViewBasket');
            }
        },
        setListrakCartList: async ({ commit, state }) => {
            if (state.cartProductList.length > 0) {
                commit('setListrakCartList');
            }
        },
        setListrakCartListClear: async ({ commit, state }) => {
            if (state.cartProductList.length <= 0) {
                commit('setListrakCartListClear');
            }
        },
    }
}