
   export default [{
        path: '/me',
        name: 'MyAccountPage',
        redirect: 'me/account-dashboard',
        component: () => import(/* webpackChunkName: "me" */ '@/components/me/layout/Layout.vue'),
        children: [{
                path: 'account-dashboard',
                name: 'AccountDashboard',
                component: () => import(/* webpackChunkName: "me" */ '@/routes/me/account_dashboard.vue'),
                meta: {
                    breadcrumb: {
                        label: 'Account Dashboard Page',
                    },
                }
            },
            {
                path: 'edit-account-info/:value',
                name: 'EditAccountInfo',
                component: () => import(/* webpackChunkName: "me" */ '@/routes/me/edit_account_info.vue'),
                meta: {
                    breadcrumb: {
                        label: 'Edit Account Information Page',
                    },
                }
            },
            {
                path: 'store-credit-info',
                name: 'StoreCreditInfoPage',
                component: () => import(/* webpackChunkName: "me" */ '@/routes/me/store_credit_info_page.vue'),
                meta: {
                    breadcrumb: {
                        label: 'Store Credit Page',
                    },
                }
            },
            {
                path: 'gift-card-page',
                name: 'GiftCardPage',
                component: () => import(/* webpackChunkName: "me" */ '@/routes/me/gift_card_page.vue'),
                meta: {
                    breadcrumb: {
                        label: 'Gift Card Page',
                    },
                }
            },
            {
                path: 'gift-card-page/:giftCardCode',
                name: 'GiftCardPageId',
                component: () => import(/* webpackChunkName: "me" */ '@/routes/me/gift_card_page.vue'),
                meta: {
                    breadcrumb: {
                        label: 'Gift Card Page',
                    },
                }
            },
            {
                path: 'my-orders',
                name: 'MyOrders',
                component: () => import(/* webpackChunkName: "me" */ '@/routes/me/my_orders.vue'),
                meta: {
                    breadcrumb: {
                        label: 'My Orders Page',
                    },
                }
            },
            {
                path: 'my-orders/:orderId',
                name: 'ViewProduct',
                component: () => import(/* webpackChunkName: "me" */ '@/routes/me/my_orders.vue'),
                meta: {
                    breadcrumb: {
                        label: 'My Orders Item',
                    },
                }
            },
           
            {
                path: 'address-book',
                name: 'AddressBook',
                component: () => import(/* webpackChunkName: "me" */ '@/routes/me/address_book.vue'),
                meta: {
                    breadcrumb: {
                        label: 'Address Book Page',
                    },
                }
            },
            {
                path: 'address-book/:addressId',
                name: 'EditAddressBook',
                component: () => import(/* webpackChunkName: "me" */ '@/routes/me/address_book.vue'),
                meta: {
                    breadcrumb: {
                        label: 'Address Book Edit',
                    },
                }
            },
            {
                path: 'news-letter-subscription',
                name: 'NewsLetterSubscription',
                component: () => import(/* webpackChunkName: "me" */ '@/routes/me/news_letter_subscription.vue'),
                meta: {
                    breadcrumb: {
                        label: 'News Letter subscription Page',
                    },
                }
            },
            {
                path: 'saved-for-later',
                name: 'SavedForLater',
                component: () => import(/* webpackChunkName: "me" */ '@/routes/me/saved_for_later.vue'),
                meta: {
                    breadcrumb: {
                        label: 'Saved For Later Page',
                    },
                }
            },
            {
                path: 'rewards',
                name: 'Rewards',
                component: () => import(/* webpackChunkName: "me" */ '@/routes/me/rewards.vue'),
                meta: {
                    breadcrumb: {
                        label: 'Rewards',
                    },
                }
            }
        ]
    },{
        path: '/:pathMatch(.*)',
        redirect: '/me/account-dashboard'
    }]