import {  createRouter,createWebHistory } from "vue-router";

import { beforeEachRoute } from '@/services/nav'
 
// Routes
import CraneRoutes from './crane.routes'
import GateRoutes from './gate.routes'
import MeRoutes from './me.routes'

 
export const routes = [
...CraneRoutes,
...GateRoutes,
...MeRoutes,
]

 
const router = createRouter({
  history: createWebHistory(),
   base: import.meta.env.BASE_URL || '/',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) return savedPosition

    return { x: 0, y: 0 }
  },
  routes,
});



router.beforeEach(beforeEachRoute);

export default router