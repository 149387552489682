import {
    doEmailSubscriber
} from '@/services/subscriber.service';
import { getField, updateField } from 'vuex-map-fields';

export const emailSubscriber = {
    namespaced: true,
    state: {
        inProgress: true,
    },
    getters: {
        getField
    },
    mutations: {
        updateField,
        inProgress(state, yesOrNo) {
            state.inProgress = yesOrNo
        },
    },
    actions: {
        fetchEmailSubscriber: async ({ commit }, data) => {
            try {
                commit('inProgress', true);
                const cartProduct = await doEmailSubscriber(data.email);
                return cartProduct;
            } catch (err) {
                throw err;
            }
        }
    },
}