import { doFetchOne, doPostOne, doPatchOne} from '@/services/pdfdoc.service';
import { checkEncodeURI, snakeToCamel } from '@/services/util.service';
import { getField, updateField } from 'vuex-map-fields';

export const pdfDocOne = {
    namespaced: true,
    state: {
        one: {},
        inProgress: false,
    },
    getters: {
        getField,
    },
    mutations: {
        updateField,
        setOne(state, one) {
            state.one = one;
          
            state.one.partOrder=state.one.partOrder?state.one.partOrder.toString():'0';
            if (checkEncodeURI(state.one.svgContent))
                 state.one.svgContent = decodeURIComponent(escape(atob(state.one.svgContent)));
        },
        inProgress(state, yesOrNo) {
            state.inProgress = yesOrNo
        },
    },
    actions: {
        new({ commit }) {
            commit('inProgress', false);
 
        },
        fetchOne: async ({ commit }, { pdfDocId }) => {
            try {
                commit('inProgress', true);
                const list = await doFetchOne(pdfDocId);
                const one = list[0];
                commit('setOne', one);
                commit('inProgress', false);
            }
            catch (err) {
                commit('inProgress', false);
                throw err;
            }
        },

        save: async ({ commit, state }, extraData) => {
            commit('inProgress', true);
            try {
                if (undefined === state.one.id) {
                    await doPostOne((Object.assign({}, state.one, extraData)));
                    commit('inProgress', false);
                    return ({ new: true });
                }
                else {
                    await doPatchOne(state.one);
                    commit('inProgress', false);
                    return ({ update: true });
                }
            }
            catch (err) {
                commit('inProgress', false);
                throw err;
            }
        },
        setOne: async ({ commit }, page) => {
            commit('setOne', snakeToCamel(page));
        },
        update: async ({ commit }, pdfDoc) => {
            commit('inProgress', true);
            try {
                await doPatchOne(pdfDoc);
                commit('inProgress', false);
                return ({ update: true });
            }
            catch (err) {
                commit('inProgress', false);
                throw err;
            }
        }
    },
}
