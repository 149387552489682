const imageLibApiUrl = import.meta.env.VITE_IMAGELIB_API_URL;

import { wrapRequest } from './nav';

export const doFetchStoreLocationsList = wrapRequest(() => {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-type': 'application/json'
        },
    };
    return fetch(`${imageLibApiUrl}/store_location`, requestOptions)
});

export const doFetchStoreLocationsByPlace = async(location) => {
    let markers = [{
            name: "House of Aleida Greve",
            description1: "By Current Location",
            description2: "Placed:2",
            description3: "Placed:3",
            date_build: "",
            position: { lat: location.lat, lng: location.lng }
        },
        {
            name: "Rio de Janeiro",
            description1: "Placed:1",
            description2: "Placed:2",
            description3: "Placed:3",
            date_build: "",
            position: { lat: 52.51195, lng: 6.091056 }
        }
    ]
    return markers;
};
export const doFetchStoresByPlaceWithRadius = async(location, radius) => {
    let markers = [{
            name: "House of Aleida Greve",
            description1: "Location with radius",
            description2: "Placed:2",
            description3: radius,
            date_build: "",
            position: { lat: location.lat, lng: location.lng }
        },
        {
            name: "Rio de Janeiro",
            description1: "Placed:1",
            description2: "Placed:2",
            description3: "Placed:3",
            date_build: "",
            position: { lat: 52.51195, lng: 6.091056 }
        }
    ]
    return markers;
};
