import { doFetchStoreLocationsList, doFetchStoreLocationsByPlace, doFetchStoresByPlaceWithRadius } from '@/services/storelocator.service';
import { getField, updateField } from 'vuex-map-fields';
import { snakeToCamel } from "@/services/util.service";

export const storeLocatorList = {
    namespaced: true,
    state: {
        inProgress: true,
        fetchInProgress: false,
        storeLocationData: [],
        storeSerchFilterplaces: []
    },
    getters: {
        getField
    },
    mutations: {
        updateField,
        inProgress(state, yesOrNo) {
            state.inProgress = yesOrNo
        },
        fetchInProgress(state, yesOrNo) {
            state.fetchInProgress = yesOrNo
        },
        setStoreLocationDetails(state, list) {
            state.storeLocationData = list;
        },
        setserchFilterplaces(state, list) {
            state.storeSerchFilterplaces = list;
          
        }
    },
    actions: {
        fetchStoreLocationsList: async ({ commit }) => {
            try {
                commit('inProgress', true);
                const storeData = await doFetchStoreLocationsList();
                if (storeData) {
                    commit('inProgress', false);
                    commit('setStoreLocationDetails', snakeToCamel(storeData));
                }
            } catch (error) {
                commit('inProgress', false);
                throw error
            }
        },
        fetchStoreLocationsByPlace: async ({ commit }, location) => {
            try {
                commit('fetchInProgress', true);
                const storeData = await doFetchStoreLocationsByPlace(location);
                if (storeData) {
                    commit('fetchInProgress', false);
                    commit('setStoreLocationDetails', snakeToCamel(storeData));
                }
            }
            catch (error) {
                commit('fetchInProgress', false);
                throw error
            }
        },
        fetchStoreByPlaceWithRadius: async ({ commit }, { location, radius }) => {
            try {
                commit('fetchInProgress', true);
                const storeData = await doFetchStoresByPlaceWithRadius(location, radius);
                if (storeData) {
                    commit('fetchInProgress', false);
                    commit('setStoreLocationDetails', snakeToCamel(storeData));
                }
            }
            catch (error) {
                commit('fetchInProgress', false);
                throw error
            }
        },
        serchFilterplaces: async ({ commit }, newPlacesList) => {
            try {
                commit('fetchInProgress', true);
                commit('setserchFilterplaces', (newPlacesList));
                commit('fetchInProgress', false);
            }
            catch (error) {
                commit('fetchInProgress', false);
                throw error
            }
        },
    },

}