 
const theme = {
    defaultTheme: 'light',
    themes: {
      light: {
        dark: false,
        colors: {
          'primary': '#2c50da',
        },
      }
    },
  }
  export default theme