<template>
  <div>
     <SearchProductsComponent @criteriaChanged="searchProducts" :criteria="criteria" :searchPageContent="searchPageContent" :categoryPageContent="categoryContent" :breadcrumbData="categoryId" :key="categoryId" />
    <SeoFooterContent key:categoryId />
    <FooterComponent />
  </div>
</template>
<script>

const baseUrl = import.meta.env.VITE_BASE_URL;
import SeoFooterContent from "@/components/layout/footer/SeoFooterContent.vue";
import FooterComponent from "@/components/layout/footer/FooterComponent.vue";
import SearchProductsComponent from "@/components/crane/search/SearchProducts.vue";

import { getMeta,getCategoryOne} from "@/services/util.service";
 import { mapState } from "vuex";
export default {
  name: "CategoryPage",
  components: {
    FooterComponent,
    SeoFooterContent,
    SearchProductsComponent
  },
   data: () => ({
     searchPageContent:false,
     categoryContent:true,
      criteria: [
      { label: "POSITION", value: "position" },
      { label: "PRODUCT NAME", value: "name" },
      { label: "PRICE:LOW-HIGH", value: "price" },
      { label: "COLOR", value: "color" },
    ],
   }),
    metaInfo() {
        return {
           titleTemplate: `${this.metaData && this.metaData.title != undefined ?this.metaData.title:'Crane Stationery'}`,
           title: `${this.metaData && this.metaData.title != undefined ?this.metaData.title:'Crane Stationery'}`,
           description:`${this.metaData && this.metaData.description != undefined ?this.metaData.description:''}`,
           keywords:`${this.metaData && this.metaData.keywords != undefined?this.metaData.keywords:''}`,
         
            link: [
                {rel: 'canonical', href:`${this.canonical ? window.location.href.split('?')[0] : baseUrl }`}
            ]
        }
  },
  computed: {
     ...mapState({
      searchTerms: (state) => state.catalogBrowser.searchTerms,
    }),

    categoryId: function() {
      return this.$route.meta.id;
    },
    canonical: function() {
      return this.$route.meta.canonical;
    },
    heroContent() {
      return this.$store.state.layout.heroContent;
    },
    metaData(){
      return getMeta(this.heroContent.ca);
    },
    categoryList: function() {
      return this.$store.state.headerCategoryList.categoryListIdWise;
    },
    categoryDetailsList: function() {
      return this.$store.state.headerCategoryList.categoryDetails;
    }
  },
  watch: {
    categoryId() {
      this.createdLinkForBackButton();
    }
  },
  methods: {
    async createdLinkForBackButton() {
    if (
      this.$route.query.pageNumber &&
      this.$route.query.pageSize &&
      this.$route.query.sortBy &&
      this.$route.query.filter &&
      this.$route.meta.id
    ) {
      let pageNumber  = Number(this.$route.query.pageNumber);
      let pageSize = this.$route.query.pageSize;
      let sortBy = this.$route.query.sortBy;
      let filters =JSON.parse(atob(this.$route.query.filter));

      let keywords='';
      this.$store.dispatch("catalogBrowser/search", {
      fullCatalog: window.craneCatalog,
      keywords,
      filters,
      pageNumber,
      pageSize,
      sortBy,
    });
    } else {
      let pageNumber  = 1;
      let pageSize = 9;
      let sortBy =  "position";
      let filters = [{label:'Category', prop:'ci', value: this.categoryId}];
      let keywords='';
       this.$store.dispatch("catalogBrowser/search", {
      fullCatalog: window.craneCatalog,
      keywords,
      filters,
      pageNumber,
      pageSize,
      sortBy,
    });
    }
    if (this.categoryDetailsList) {
        let categoryOne = await getCategoryOne(
          this.categoryDetailsList,
          this.categoryId
        );
        if (categoryOne) {
          this.$store.dispatch("layout/setHeroContent", categoryOne);
        }
    }
   },
    searchProducts() {
         this.$store.dispatch("catalogBrowser/search", {
          fullCatalog: window.craneCatalog,
          keywords: this.searchTerms.keywords,
          filters: this.searchTerms.filters,
          pageNumber:this.searchTerms.pageNumber,
          pageSize:this.searchTerms.pageSize,
          sortBy: this.searchTerms.sortBy
     });
      this.pushPrams({
      pageNumber: this.searchTerms.pageNumber,
      pageSize: this.searchTerms.pageSize,
      sortBy:this.searchTerms.sortBy,
      q: this.searchTerms.keywords,
      filter:btoa(JSON.stringify(this.searchTerms.filters)),
    });
    },
    pushPrams(pushObj) {
      this.$router
        .push({
          query: {
            pageNumber: pushObj.pageNumber,
            pageSize: pushObj.pageSize,
            sortBy: pushObj.sortBy,
            q: pushObj.q,
            filter: pushObj.filter,
          },
        })
        .catch((error) => {
          error;
        });
    },
  },
  async created() {
    if (
      this.$route.query.pageNumber &&
      this.$route.query.pageSize &&
      this.$route.query.sortBy &&
      this.$route.query.filter &&
      this.$route.meta.id
    ) {
      let pageNumber  = Number(this.$route.query.pageNumber);
      let pageSize = this.$route.query.pageSize;
      let sortBy = this.$route.query.sortBy;
      let filters =JSON.parse(atob(this.$route.query.filter));

      let keywords='';
      this.$store.dispatch("catalogBrowser/search", {
      fullCatalog: window.craneCatalog,
      keywords,
      filters,
      pageNumber,
      pageSize,
      sortBy,
    });
    } else {
      let pageNumber  = 1;
      let pageSize = 9;
      let sortBy =  "position";
      let filters = [{label:'Category', prop:'ci', value: this.categoryId}];
      let keywords='';
       this.$store.dispatch("catalogBrowser/search", {
      fullCatalog: window.craneCatalog,
      keywords,
      filters,
      pageNumber,
      pageSize,
      sortBy,
    });
    }
    if (this.categoryDetailsList) {
        let categoryOne = await getCategoryOne(
          this.categoryDetailsList,
          this.categoryId
        );
        if (categoryOne) {
          this.$store.dispatch("layout/setHeroContent", categoryOne);
        }
    }
   }
};
</script>