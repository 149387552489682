import { createVuetify } from "vuetify";
 
import "../styles/main.scss"; 
import { aliases, mdi } from 'vuetify/iconsets/mdi-svg'
import { VDataTable } from 'vuetify/labs/VDataTable'
import * as labs from 'vuetify/labs/components'
import * as directives from 'vuetify/directives'
import theme from './theme'

// Vuetify

export default createVuetify({
  theme,
  ssr: true,
  directives,
    components: {
        VDataTable,
        ...labs,
      },
      icons: {
        defaultSet: 'mdi',
        aliases: {
          ...aliases,
        
        },
        sets: {
          mdi,
        },
      },  
});
