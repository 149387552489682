import {
    doFetchProductsList,
    doFetchFilterOptions,
    doFetchAttributeFilterProducts,
    doFetchSortProducts,
    doFetchSearchProducts,
    doFetchAttributeList,
    
    doSideBarFilter
} from '@/services/product.service';
import { 
     doFetchProductList
 } from '@/services/cache.service';
 import { 
    getProductArray,getProductsList, snakeToCamel
} from '@/services/util.service';
import { getField, updateField } from 'vuex-map-fields';

export const productList = {
    namespaced: true,
    state: {
        inProgress: false,
        productData: { items: [] },
        filterList: {},
        attributeList: {},
        guestQuote: [],
        cartProduct: [],
        cartProductList: [],
        productTotalAmt: [],
        recentlyViewed: [],
        sideBarFilterDetails: {},
        inProgressSideBarFilter: false,
        emptyProductList: false,
    },
    getters: {
        getField
    },
    mutations: {
        updateField,

        inProgress(state, yesOrNo) {
            state.inProgress = yesOrNo
        },
        setProductsDetails(state, list) {
            state.productData = list
        },
        setEmptyProductList(state, flag) {
            state.emptyProductList = flag
        },
        setFilterList(state, list) {
            state.filterList = list
        },
        setAttributeList(state, attributeList) {
            state.attributeList = attributeList
        },
        setGuestQuote(state, quoteId) {
            state.guestQuote = quoteId
        },
        setCartProduct(state, product) {
            state.cartProduct = product
        },
        setCartProductList(state, list) {
            state.cartProductList = list
        },
        setProductAmt(state, amt) {
            state.productTotalAmt = amt;
        },
        setRecentlyViewedList(state, list) {
            state.recentlyViewed = list
        },
        setSideBarFilterDetails(state, list) {
            state.sideBarFilterDetails = list
        },
        inProgressSideBarFilter(state, yesOrNo) {
            state.inProgressSideBarFilter = yesOrNo
        },
    },
    actions: {
        fetchProductsList: async({ commit }) => {
            const productData = await doFetchProductsList();
            if (productData) {
                commit('setProductsDetails', snakeToCamel(productData));
                commit('setEmptyProductList', false);
            } else {
                commit('setEmptyProductList', true);
            }
        },

        fetchFilterOptions: async({ commit }) => {
            const filterList = await doFetchFilterOptions();
            if (filterList) {
                commit('setFilterList', snakeToCamel(filterList));
            }
        },

        fetchFilterProducts: async({ commit }, id) => {
            const productData = await doFetchAttributeFilterProducts(id.attributeCode, id.value);
            if (productData) {
                commit('setProductsDetails', snakeToCamel(productData));
                commit('setEmptyProductList', false);
            } else {
                commit('setEmptyProductList', true);
            }
        },
        fetchRecentlyViewedList: async({ commit }, productIds) => {
            let fullCatalog= window.craneCatalog;
            let products=[];
            let ids = productIds.split(",");
            for(let i=0;i<ids.length;i++){
                let id=ids[i];
                let product = fullCatalog.products[id];
                if(product)
                    products.push(product)                
            }
           
            if (products) 
                commit('setRecentlyViewedList', snakeToCamel(products));
            else
            commit('setRecentlyViewedList', []);
        },

        fetchSearchProducts: async({ commit }, search) => {
            const productData = await doFetchSearchProducts(search);
            if (productData) {
                commit('setProductsDetails', snakeToCamel(productData));
                commit('setEmptyProductList', false);
            } else {
                commit('setEmptyProductList', true);
            }
        },
     
        fetchSortProducts: async({ commit }, pageDetails) => {
            commit('inProgress', true);
            const productData = await doFetchSortProducts(pageDetails.page, pageDetails.size, pageDetails.sortBy, pageDetails.categoryId, pageDetails.search, pageDetails.sideFilter);
            if (productData) {
                let categoryIds = pageDetails.categoryId.split(",");
                let sortedProducts = new Array(categoryIds.length).fill("");
                for (let i = 0; i < productData.items.length; i++) {
                    let product = productData.items[i];
                    let productIndex = categoryIds.indexOf(product.id + "");
                    sortedProducts[productIndex] = product;
                }
                productData.items = sortedProducts;
                commit('setProductsDetails', snakeToCamel(productData));
                commit('setEmptyProductList', false);
                commit('inProgress', false);
            } else {
                commit('setEmptyProductList', true);
                commit('inProgress', false);
            }
        },
        fetchAttributeList: async({ commit }) => {
            const attributeList = await doFetchAttributeList();
            if (attributeList) {
                commit('setAttributeList', snakeToCamel(attributeList));
            }
        },
        
        fetchSideBarFilter: async({ commit, dispatch }, pageDetails) => {
            commit('inProgress', true);
            commit('setProductsDetails', { "items": [] });
            const filterDetails = await doSideBarFilter(pageDetails.page, pageDetails.size, pageDetails.sortBy, pageDetails.categoryId, pageDetails.sideFilter, pageDetails.search);
            if (filterDetails) {
                commit('setSideBarFilterDetails', snakeToCamel(filterDetails));
                let result = filterDetails.items.map(a => a.id); 
                if (result.length > 0) {
                    dispatch('fetchSortProducts', {
                        page: pageDetails.page,
                        size: pageDetails.size,
                        sortBy: pageDetails.sortBy,
                        categoryId: (result).toString(),
                        search: pageDetails.search,
                        sideFilter: pageDetails.sideFilter
                    });
                    commit('setEmptyProductList', false);
                    commit('inProgress', false);
                } else {
                    commit('setProductsDetails', { "items": [] });
                    commit('setEmptyProductList', true);
                    commit('inProgress', false);
                }
            }
        },
        fetchSideBarDefaultProducts: async({ commit }, pageDetails) => {
            commit('inProgress', true);
            commit('setProductsDetails', { "items": [] });
            const filterDetails = await doSideBarFilter(pageDetails.page, pageDetails.size, pageDetails.sortBy, pageDetails.categoryId, pageDetails.sideFilter, pageDetails.search);
            if (filterDetails) {
                commit('setSideBarFilterDetails', snakeToCamel(filterDetails));
            }
            commit('inProgress', false);
        },
        fetchDefaultProducts: async({ commit, dispatch }, pageDetails) => {
            console.log('fetchDefaultProducts called');
            commit('inProgress', true);
            let sortedProducts = { "items": [] };
             if (pageDetails.categoryId) {
                const productData = await doFetchProductList();
                const productArray = await getProductArray(productData,pageDetails.categoryId); 
                const productsList = await getProductsList(productData,productArray); 
                if (productsList.length != 0) {
                    for (let i = 0; i < 9; i++) {
                        let product = productsList[i];
                        if (product)
                            sortedProducts.items.push(product)
                    }
                    dispatch('fetchSideBarDefaultProducts', pageDetails);
                    commit('setEmptyProductList', false);
                } else {
                    commit('setEmptyProductList', true);
                    commit('setSideBarFilterDetails', {
                        "items": [],
                        "aggregations": [],
                        "total_count": 0
                    });
                } 
                 commit('setProductsDetails', snakeToCamel(sortedProducts));
                commit('inProgress', false);
            } else {
                commit('setEmptyProductList', true);
                commit('inProgress', false);
            }
        },
    },

}