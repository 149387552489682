<template>
  <v-container class="pt-8 background-white px-0 pt-15 pt-md-0">
    <v-row no-gutters justify="center" class="pa-0 d-none d-sm-flex">
      <div class="hr-line-100px"></div>
    </v-row>
    <!-----------  mobile view -------->
    <v-content
      align="center"
      class="pa-0 mt-md-5 pt-5 mb-9 d-sm-none ls-n01"
      v-if="recentlyViewed && recentlyViewed.length > 0"
    >
      <p
        class="text-uppercase fontsize-28 regular-font mx-2 px-2 mb-0 text-center line-height-36"
      >
        Recently
      </p>
      <p
        class="text-uppercase fontsize-28 regular-font mx-2 px-2 mb-0 text-center line-height-36"
      >
        Viewed
      </p>
    </v-content>
    <!-----------  mobile view end -------->
    <div v-if="recentlyViewed && recentlyViewed.length > 0">
      <v-content
        align="center"
        class="pa-0 mt-md-5 mt-5 mb-12 d-none d-sm-block ls-n01"
      >
        <p
          class="text-uppercase fontsize-28 mt-5 mx-2 px-2 text-center line-height-36 regular-font"
        >
          Recently Viewed 
        </p>
      </v-content>
      <v-row no-gutters>
         <v-container class="pa-0 pa-sm-3  pt-10">
          <v-col lg="12" md="12" cols="12" sm="12" class="pa-0 pa-sm-3">
            <vueper-slides
              class=" no-shadow mb-0 recent-products-silder recentproducts-image-slider"
              :class="recentlyViewed.length > 4 ? '' : 'recentproducts-images'"
              :visible-slides="perSlides"
              :dragging-distance="1"
              :fixed-height="slideHeight"
              :touchable="false"
              
            >
              <vueper-slide
                v-for="(product, index) in recentlyViewed.slice(0, 16)"
                v-bind:key="index"
              >
                <template v-slot:content>
                  <div  class="
                      vueperslide__content-wrapper vueperslide__title
                      cursor-pointer 
                    " >
                    <v-card
                      rounded="0" 
                      class=" transparent-background recentproducts-image-card vueperslide__title px-sm-4 py-3 w-100"
                      @click="OpenProductDetails(product.n)"
                      flat
                       
                    >
                    <picture>
                      <source :media="`(min-width:100px)`" :srcset="mediaImageUrl+'/' +fetchProductImageName(product.i) +'_360.webp'"  style="width:100%">
                      <img :src="mediaImageUrl+'/' +fetchProductImageName(product.i) +'.webp'"   style="width:100%"
                      :alt="product.n"
                      :title="product.n" onerror="this.onerror=null;this.parentNode.children[0].srcset=this.parentNode.children[1].srcset=this.parentNode.children[2].srcset='https://cdn.crane.com/stg-images/cc12011-1.webp';">
                  </picture>  
                     
               
                      <v-row no-gutters class="pt-4">
                        <v-col class="pb-0" cols="6">
                          <h1
                            class="font-weight-300 text-left mb-3 fontsize-14 regular-font relatedproduct-fcolor-black-darken-2 ls-n009 word_break"
                          >
                            {{ product.n }}
                          </h1>
                        </v-col>
                       
                      </v-row>
                    </v-card>
                  </div>
                </template>
              </vueper-slide>
            </vueper-slides>
          </v-col>
        </v-container>
      </v-row>

      <v-row
        no-gutters
        align="center"
        justify="center"
        class="d-none d-sm-flex pa-0 pt-7"
      >
        <div class="hr-line-100px"></div>
      </v-row>
    </div>
    <v-row
      no-gutters
      align="center"
      justify="center"
      class="d-flex d-sm-none pa-0 pt-12"
    >
      <div class="hr-line-100px"></div>
    </v-row>
    <v-row no-gutters class="mt-5 d-none d-sm-block">
      <v-col align="center">
        <h2
          class="text-uppercase body-font fontsize-28px ls-n01 title-color-black text-center mb-4 line-height-36"
        >
          the craftsmanship of crane
        </h2>
        <p class="text-center pt-4 ls-n01 body-font fontcolor-black-darken">
          Luxurious Lettra and touchable embossing elevate a simple sentiment
          for
          <br />this refined holiday card. Friends and family will cherish your
          personal <br />message and lovingly chosen photograph.
        </p>
      </v-col>
    </v-row>
    <v-row no-gutters class="mt-5 d-block d-sm-none">
      <v-col align="center">
        <h2
          class="text-uppercase body-font fontsize-28px ls-n01 title-color-black text-center mb-4 line-height-36"
        >
          the
          <br />craftsmanship <br />of crane
        </h2>
        <p class="text-center pt-1 ls-n01 body-font fontcolor-black-darken">
          Luxurious Lettra and touchable
          <br />embossing elevate a simple sentiment <br />for this refined
          holiday card. Friends and <br />family will cherish your personal
          <br />message and lovingly chosen <br />photograph.
        </p>
      </v-col>
    </v-row>

    <div class="d-none d-sm-flex flex-column flex-start pt-5 pb-4">
      <v-container class="px-0">
        <v-row class="pb-9">
          <v-col
            md="4"
            sm="4"
            class="cursor-pointer"
            @click="goToPage('/about-us')"
          >
            <v-img
              :src="aboutUsImage"
              class="align-center text-center text-white"
            >
              <span
                class="regular-font line-height-36 fontsize-28 ls-n01 text-uppercase"
                >Legacy of Excellence</span
              >
            </v-img>
          </v-col>

          <v-col
            md="4"
            sm="4"
            class="cursor-pointer"
            @click="goToPage('/our-papers')"
          >
            <v-img
              :src="ourPaperImage"
              class="align-center text-center text-white"
            >
              <span
                class="regular-font line-height-36 fontsize-28 text-uppercase ls-n01"
                >Details Matter</span
              >
            </v-img>
          </v-col>

          <v-col
            md="4"
            sm="4"
            class="cursor-pointer"
            @click="goToPage('/printing-processes')"
          >
            <v-img
              :src="printingProcess"
              class="align-center text-center text-white"
            >
              <span
                class="regular-font line-height-36 fontsize-28 text-uppercase ls-n01"
                >Handcrafted</span
              >
            </v-img>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <!------- mobile view code --------------------------------->
    <v-row no-gutters class="d-sm-none mt-5">
      <v-container class="mb-7 mt-7 pa-0">
        <v-row>
          <v-col
            cols="12"
            class="pa-3 px-0 cursor-pointer"
            @click="goToPage('/about-us')"
          >
            <v-img
              :src="aboutUsImage"
              class="align-center text-center text-white"
            >
              <span class="h3 ls-n008 text-uppercase"
                >Legacy of Excellence</span
              >
            </v-img>
          </v-col>

          <v-col
            cols="12"
            class="pa-3 px-0 cursor-pointer"
            @click="goToPage('/our-papers')"
          >
            <v-img
              :src="ourPaperImage"
              class="align-center text-center text-white"
            >
              <span class="h3 text-uppercase ls-n008">Details Matter</span>
            </v-img>
          </v-col>

          <v-col
            cols="12"
            class="pa-3 px-0 cursor-pointer"
            @click="goToPage('/printing-processes')"
          >
            <v-img
              :src="printingProcess"
              class="align-center text-center text-white"
            >
              <span class="h3 text-uppercase ls-n008">Handcrafted</span>
            </v-img>
          </v-col>
        </v-row>
      </v-container>
    </v-row>
    <!------- end mobile view code --------------------------------->
  </v-container>
</template>
<script>
import { VueperSlides, VueperSlide } from "vueperslides";
import { attribute, customAttributeValue } from "@/services/util.service";
import aboutUsImage from "@/assets/legacy_of_excellence.webp";
import ourPaperImage from "@/assets/detail_matter.webp";
import printingProcess from "@/assets/hand_crafted.webp";
const mediaImageUrl = import.meta.env.VITE_APP_MEDIA_IMAGES_API_URL;

export default {
  name: "RecentlyViewedComponent",
  props: ["recentlyViewed"],
  components: {
    VueperSlides,
    VueperSlide,
  },
    data() {
    return {
      mediaImageUrl,
      aboutUsImage: aboutUsImage,
      ourPaperImage: ourPaperImage,
      printingProcess: printingProcess,
    };
  },
  computed: {
    productAttributes() {
      return this.$store.state.productList.attributeList;
    },
   imageBasePathUrl() {
      return import.meta.env.VITE_IMAGE_PATH_URL;
    },
     perSlides: function () {
      if (this.$vuetify.display.xs) return 1;
      else if (this.$vuetify.display.sm) return 3;
      else return this.$vuetify.display.md ? 4 : 4;
    },
    slideHeight: function () {
      return this.$vuetify.display.xsOnly ? "400px" : "350px";
    },
   showArrows: function () {
      if (this.$vuetify.display.smAndDown) return false;
      else return true;
    },
    imageMaxHeight: function () {
      if (this.$vuetify.display.xsOnly) return "250px";
      else return "300px";
    },
  },
  methods: {
    fetchProductImageName(imageFullName) {
      let fileExtention = imageFullName.split("/");
      let filename = fileExtention[fileExtention.length - 1].split(".")[0];
      return filename;
    },
    initialLink() {
      return import.meta.env.VITE_HOME_PAGE_URL;
    },
    goToPage(pageLink) {
      window.location.replace(this.initialLink() + pageLink);
    },
    getAttribute(customAttributes, attributeCode) {
      return attribute(customAttributes, attributeCode, this.productAttributes);
    },

    getCustomAttributeValue(customAttributes, attributeCode) {
      return customAttributeValue(customAttributes, attributeCode);
    },
    OpenProductDetails(productName) {
      this.$router
        .push({
          name: productName,
        })
        .catch((error) => {
          error;
        });
    },
  },
};
</script>
<style src="./RecentlyViewedComponent.scss" lang="scss" />