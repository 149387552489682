<template>
<metainfo>
</metainfo>
  <v-app color="white">
    <router-view/>
  </v-app>
</template>

<script>
const branch = import.meta.env.VITE_GIT_BRANCH_NAME;
const commitHash = import.meta.env.VITE_GIT_COMMIT_HASH;
const commitDate = import.meta.env.VITE_GIT_COMMIT_DATE;
// const commitAuthor = import.meta.env.VITE_GIT_COMMIT_AUTHOR;

export default {
  name: "App",
   created() {
    console.info("Build - ", commitHash, branch, commitDate); //commitAuthor,
    try{
    let recaptchaInterval = null;
    recaptchaInterval = setInterval(() => {
      let recaptchaBadge = document.getElementsByClassName("grecaptcha-badge");
      if (recaptchaBadge && recaptchaBadge.length && recaptchaBadge[0].style) {
        recaptchaBadge[0].style.display = "none";
        clearInterval(recaptchaInterval);
      }
    }, 100);
    }
    catch(error){
      console.error("recpatcha hide error",error)
    }
  },
};
</script>
 <style src="./styles/_app.scss" lang="scss" />


