import { wrapRequest, meWrapRequest } from './nav';
const vueAppMagenotApiUrl = import.meta.env.VITE_MAGENTO_API_URL;
 const retailorId = import.meta.env.VITE_RETAILOR_ID;
const vueAppNodeslsApiUrl = import.meta.env.VITE_NODESLS_API_URL;
export const doFetchBasicInfo = async() => {
    let address = {
        "id": '',
        "group_id": '',
        "default_billing": '',
        "default_shipping": '',
        "created_at": '',
        "updated_at": '',
        "created_in": '',
        "email": '',
        "firstname": '',
        "lastname": '',
        "store_id": '',
        "website_id": '',
        "addresses": [{
            "id": '',
            "customer_id": '',
            "region": {
                "region_code": '',
                "region": '',
                "region_id": ''
            },
            "region_id": '',
            "country_id": '',
            "street": [
                ''
            ],
            "telephone": '',
            "postcode": '',
            "city": '',
            "firstname": '',
            "lastname": ''
        }],
    }

    return address
};


export const doValidateAddress = wrapRequest((address) => {
    const requestOptions = {
        method: 'GET',
    };
    return fetch("https://maps.googleapis.com/maps/api/geocode/json?address=" + address + "&key=AIzaSyDmHp0PPuKHD1KGHCDmLq1qQKr_03XKEHo", requestOptions);
});
export const doFetchCheckEmailAvailable = wrapRequest((email) => {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-type': 'application/json',
            'Access-Control-Allow-Origin': '*',
        },
        body: JSON.stringify({
            "customerEmail": email,
            "websiteId": 1
        })
    };

    return fetch(`${vueAppMagenotApiUrl}/rest/V1/customers/isEmailAvailable`, requestOptions)

});
 
 
export const doFetchEstimatedShippingMethods = wrapRequest((address, email, guestQuoteId) => {
    let streetAddress = [];
    let streetArray = [];
    if (address.streetNoTwo == " " || address.streetNoTwo == undefined) {
        streetAddress[0] = address.streetNoOne;
        streetArray = streetAddress.filter(v => v != '');
    } else {
        streetAddress[0] = address.streetNoOne
        streetAddress[1] = address.streetNoTwo;
        streetArray = streetAddress.filter(v => v != '');
    }
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-type': 'application/json',
        },
        body: JSON.stringify({
            "address": {
                "region_id": address.regionId,
                "country_id": address.countryId,
                "street": streetArray,
                "postcode": address.postcode,
                "city": address.city,
                "firstname": address.firstname,
                "lastname": address.lastname,
                "email": email,
                "same_as_billing": 1
            }
        }),
    };
    return fetch(`${vueAppMagenotApiUrl}/rest/V1/guest-carts/${guestQuoteId}/estimate-shipping-methods`, requestOptions)
});
export const doShippingInformation = wrapRequest((address, estimated, email, guestQuoteId, suggestedAddress) => {
    let streetAddress = [];
    let streetArray = [];
    if (address.streetNoTwo == " " || address.streetNoTwo == undefined) {
        streetAddress[0] = suggestedAddress.streetNoOne ? suggestedAddress.streetNoOne : address.streetNoOne;
        streetArray = streetAddress.filter(v => v != '');
    } else {
        streetAddress[0] = suggestedAddress.streetNoOne ? suggestedAddress.streetNoOne : address.streetNoOne
        streetAddress[1] = suggestedAddress.streetNoTwo ? suggestedAddress.streetNoTwo : address.streetNoTwo;
        streetArray = streetAddress.filter(v => v != '');
    }
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-type': 'application/json',
        },
        body: JSON.stringify({
            "addressInformation": {
                "shipping_address": {
                    "region_id": suggestedAddress.region_id ? suggestedAddress.region_id : address.regionId,
                    "country_id": suggestedAddress.country_id ? suggestedAddress.country_id : address.countryId,
                    "street": streetArray,
                    "postcode": suggestedAddress.postcode ? suggestedAddress.postcode : address.postcode,
                    "city": suggestedAddress.city ? suggestedAddress.city : address.city,
                    "firstname": address.firstname,
                    "lastname": address.lastname,
                    "email": email,
                    "telephone": address.telephone
                },
                "shipping_carrier_code": estimated.carrierCode,
                "shipping_method_code": estimated.methodCode
            }
        })
    };
    return fetch(`${vueAppMagenotApiUrl}/rest/V1/guest-carts/${guestQuoteId}/shipping-information`, requestOptions)
});
export const doProductsOrder = meWrapRequest((userEmail, guestQuoteId, paymentMethod, oneAddress) => {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-type': 'application/json'
        },
        body: JSON.stringify({
            "email": userEmail,
            "cartId": guestQuoteId,
            "billingAddress": oneAddress,
            "paymentMethod": paymentMethod,
            "comments": ""
        })
    };
    return fetch(`${vueAppMagenotApiUrl}/rest/V1/guest-carts/${guestQuoteId}/payment-information`, requestOptions)
});
 
export const doProductsOrderOnCC = meWrapRequest((orderNumber, shippingAddress, webOrderNumber) => {
    let address2 = shippingAddress.street[1] ? shippingAddress.street[1] : undefined;
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-type': 'application/json'
        },
        body: JSON.stringify({
            "retailorId": retailorId,
            "orderNumber": orderNumber,
            "shipToFirstName": shippingAddress.firstname,
            "shipToLastName": shippingAddress.firstname,
            "shipToAddress1": shippingAddress.street[0],
            "shipToAddress2": address2,
            "shipToCity": shippingAddress.city,
            "shipToState": shippingAddress.regionCode,
            "shipToZip": shippingAddress.postcode,
            "shipToCountry": shippingAddress.countryId,
            "shipToPhone": shippingAddress.telephone,
            "shipToEmail": shippingAddress.email,
            "shippingMethod": "usp",
            "webOrderNumber": webOrderNumber
        })
    };
    return fetch(`${vueAppNodeslsApiUrl}/cc-submit-order`, requestOptions)
});
 