import { doFetchOne, doPatchOne,doDeleteItem } from '@/services/builder_session.service';
import { snakeToCamel, camelToSnake } from "@/services/util.service";


export const sessionOne = {
    namespaced: true,
    state: {
        one: {},
        inProgress: false
    },
    mutations: {
        setSession(state, one) {
            state.one = one;
        },
        inProgress(state, yesOrNo) {
            state.inProgress = yesOrNo
        }
    },
    actions: {
        fetchOne: async ({ commit }, { sessionId }) => {
            commit('inProgress', true);
            try {
                let response = await doFetchOne({ id: sessionId });
                commit('setSession', snakeToCamel(response));
                commit('inProgress', false);
                return ({ response: snakeToCamel(response) });
            }
            catch (err) {
                commit('inProgress', false);
                throw err;
            }
        },
        updateOne: async ({ commit }, { sessionId, property }) => {
            commit('inProgress', true);
            try {
                let response = await doPatchOne({ sessionId, property: camelToSnake(property) });
                commit('setSession', snakeToCamel(response));
                commit('inProgress', false);
                return ({ response: snakeToCamel(response) });
            }
            catch (err) {
                commit('inProgress', false);
                throw err;
            }
        },
        updateSessionOne: async ({ commit }, { sessionId,property }) => {
            commit('inProgress', true);
            try {
                let response = await doPatchOne({ sessionId,property });
                commit('setSession', snakeToCamel(response));
                commit('inProgress', false);
                return ({ response: snakeToCamel(response) });
            }
            catch (err) {
                commit('inProgress', false);
                throw err;
            }
        },
        deleteItem: async ({ commit }, sessionId) => {
            commit('inProgress', true);
            try {
                let response = await doDeleteItem(sessionId);
                commit('inProgress', false);
                return ({ response: true });
            }
            catch (err) {
                commit('inProgress', false);
                throw err;
            }
        },
    },

}
