import { doFetchSavedForLaterList, doDeleteWishlistProduct } from '@/services/saved_for_later.service';
import { getField, updateField } from 'vuex-map-fields';
import { snakeToCamel } from '@/services/util.service';

export const meSavedForLaterList = {
    namespaced: true,
    state: {
        inProgress: true,
        savedForLaterList: [],
    },
    getters: {
        getField
    },
    mutations: {
        updateField,

        inProgress(state, yesOrNo) {
            state.inProgress = yesOrNo
        },
        setSavedForLaterList(state, list) {
            state.savedForLaterList = list
        },
    },
    actions: {
        fetchSavedForLaterList: async ({ commit }, customerId) => {
            try {
                commit('inProgress', true); 
                let savedForLaterList = await doFetchSavedForLaterList(customerId);
                if (savedForLaterList) {
                    commit('inProgress', false);
                    commit('setSavedForLaterList', snakeToCamel(savedForLaterList));
                }
            }
            catch (error) {
                commit('inProgress', false);
                throw error
            }
        },
        removeWishlistProduct: async ({ commit }, { itemId }) => {
            try {
                commit('inProgress', true);
                const deleteFlag = await doDeleteWishlistProduct(itemId);
                commit('inProgress', false);
                return deleteFlag;
            }
            catch (error) {
                commit('inProgress', true);
                throw error
            }
        }
    },

}
