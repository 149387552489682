<template>
  <v-container class="bg-white" ref="divOne">
    <v-row class="mb-md-10">
      <v-col
        cols="1"
        class="d-none d-sm-block pr-4"
        v-if="productDetails.mediaGalleryEntries.length"
      >
        <div
          v-for="(productImage, index) in productDetails.mediaGalleryEntries"
          v-bind:key="index"
        >
          <div v-if="productImage.mediaType === 'image'">
            <v-col class="pb-2 pt-0 pl-0 pr-0">
              <v-hover v-slot="{ isHovering, props }">
                <v-card
                  :variant="index==selected || isHovering ? 'outlined' : 'plain'"
                  color="primary"
                  v-bind="props"
                  @click="setSelected(productImage.file,index)"
                  tile
                  rounded="0"
                >
                  <v-img
                    :src="mediaImageUrl +
                    '/' +
                    fetchProductImageName(productImage.file) +
                    '_360.webp'"
                    @error="setAltImg()"
                    :alt="productDetails.name"
                    :title="productDetails.name"
                  ></v-img>
                </v-card>
              </v-hover>
            </v-col>
          </div>
        </div>
      </v-col>
      <v-col
        cols="1"
        class="d-none d-sm-block pr-4"
        v-else-if="productDetails.mediaGalleryEntries.length == 0"
      >
        <v-col class="pb-2 pt-0 px-0">
          <v-hover v-slot="{ isHovering, props }">
            <v-card
              :variant="index==selected || isHovering ? 'outlined' : 'plain'"
                  color="primary"
              v-bind="props"
              tile
              rounded="0"
            >
               <picture>
                <template v-for="imgWidth in variants"  :key="imgWidth">
                  <source

                    :media="`(min-width:${imgWidth}px)`"
                    :srcset="
                      mediaImageUrl +
                      '/' +
                      fetchProductImageName(imageBasePathUrl) +
                      '_' +
                      imgWidth +
                      '.webp'
                    "
                    style="width: 100%"
                  />
                </template>
                <img
                  :src="
                    mediaImageUrl +
                    '/' +
                    fetchProductImageName(imageBasePathUrl) +
                    '.webp'
                  "
                  style="width: 100%"
                  :alt="productDetails.name"
                  :title="productDetails.name" onerror="this.onerror=null;this.parentNode.children[0].srcset=this.parentNode.children[1].srcset=this.parentNode.children[2].srcset='https://cdn.crane.com/stg-images/cc12011-1.webp';">
              </picture>
            </v-card>
          </v-hover>
        </v-col>
      </v-col>
      <v-col cols="12" sm="7" md="7" class="pr-md-8 pb-0" v-if="imagepath">
        <v-row class="ma-0">
          <v-col cols="11" class="d-block d-sm-none pa-0 ma-0 pb-5">
            <h1 class="text-uppercase h3 ma-0 pa-0 ls-n01 title-color-black">
              {{ productDetails.name }}
            </h1>
          </v-col>

        </v-row>
        <picture>
          <template v-for="imgWidth in variants" :key="imgWidth">
            <source
              :media="`(min-width:${imgWidth}px)`"
              :srcset="
                mediaImageUrl +
                '/' +
                fetchProductImageName(imagepath) +
                '_' +
                imgWidth +
                '.webp'
              "
              style="width: 100%"
            />
          </template>
          <img :src="mediaImageUrl+'/' +fetchProductImageName(imagepath) +'.webp'"   style="width:100%"  :alt="productDetails.name"
          :title="productDetails.name" onerror="this.onerror=null;this.parentNode.children[0].srcset=this.parentNode.children[1].srcset=this.parentNode.children[2].srcset='https://cdn.crane.com/stg-images/cc12011-1.webp';">
        </picture>
      </v-col>
      <v-col cols="12" sm="7" md="7" class="pr-md-8 pb-0" v-else>
        <v-col cols="11" class="d-block d-sm-none pa-0 ma-0 pb-5">
          <h1 class="text-uppercase h3 ma-0 pa-0 ls-n01 title-color-black">
            {{ productDetails.name }}
          </h1>
        </v-col>

         <picture>
          <template v-for="imgWidth in variants" :key="imgWidth">
            <source

              :media="`(min-width:${imgWidth}px)`"
              :srcset="
                mediaImageUrl +
                '/' +
                fetchProductImageName(
                  productDetails.mediaGalleryEntries.length
                    ? productDetails.mediaGalleryEntries[0].file
                    : ''
                ) +
                '_' +
                imgWidth +
                '.webp'
              "
              style="width: 100%"
            />
          </template>
          <img :src="mediaImageUrl+'/' + fetchProductImageName(productDetails.mediaGalleryEntries[0].file) +'.webp'"   style="width:100%" :alt="productDetails.name" :title="productDetails.name" onerror="this.onerror=null;this.parentNode.children[0].srcset=this.parentNode.children[1].srcset=this.parentNode.children[2].srcset='https://cdn.crane.com/stg-images/cc12011-1.webp';">
        </picture>
      </v-col>
      <v-row
        align="center"
        class="pl-4 pr-3 d-flex d-sm-none px-1"
        v-if="productDetails.mediaGalleryEntries.length"
      >
        <v-col
          v-for="(productImage, index) in productDetails.mediaGalleryEntries"
          v-bind:key="index"
          v-show="productImage.mediaType === 'image'"
          class="px-2"
        >
          <v-hover v-slot="{ isHovering, props }">
            <v-card
              max-width="70"
              max-height="70"
              v-bind="props"
              class="mx-auto"
              @click="setSelected(productImage.file,index)"
              :variant="index==selected || isHovering ? 'outlined' : 'plain'"
                  color="primary"
              tile
              v-if="productImage.file"
            >
                <picture>
              <source :media="`(min-width:100px)`" :srcset="mediaImageUrl+'/' +fetchProductImageName(productImage.file) +'_360.webp'"  style="width:100%">
              <img :src="mediaImageUrl+'/' +fetchProductImageName(productImage.file) +'.webp'"   style="width:100%"
                :alt="productImage.name"
                :title="productImage.name" onerror="this.onerror=null;this.parentNode.children[0].srcset=this.parentNode.children[1].srcset=this.parentNode.children[2].srcset='https://cdn.crane.com/stg-images/cc12011-1.webp';">
            </picture>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
      <v-row
        align="center"
        class="d-flex d-sm-none mx-auto px-1"
        v-else-if="productDetails.mediaGalleryEntries.length == 0"
      >
        <v-col class="px-2" cols="3">
           <v-hover v-slot="{ isHovering, props }">
            <v-card
              max-width="70"
              max-height="70"
              v-bind="props"
              class="mx-auto bg-primary"
              :variant="index==selected || isHovering ? 'outlined' : 'plain'"
                  color="primary"
              tile
            >
              <picture>
                <template v-for="imgWidth in variants"  :key="imgWidth">
                  <source

                    :media="`(min-width:${imgWidth}px)`"
                    :srcset="
                      mediaImageUrl +
                      '/' +
                      fetchProductImageName(imageBasePathUrl) +
                      '_' +
                      imgWidth +
                      '.webp'
                    "
                    style="width: 100%"
                  />
                </template>
                <img
                  :src="
                    mediaImageUrl +
                    '/' +
                    fetchProductImageName(imageBasePathUrl) +
                    '.webp'
                  "
                  style="width: 100%"
                  :alt="productDetails.name"
                  :title="productDetails.name" onerror="this.onerror=null;this.parentNode.children[0].srcset=this.parentNode.children[1].srcset=this.parentNode.children[2].srcset='https://cdn.crane.com/stg-images/cc12011-1.webp';">
              </picture>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
      <v-col cols="12" sm="4" md="4" class="pl-md-12">
        <v-row>
          <v-col md="12" cols="12" class="pr-0 pt-0">
            <p
              class="d-none d-sm-block fontsize-28 regular-font line-height-36 title-color-black text-uppercase ma-0 pa-0 ls-n01"
            >
              {{ productDetails.name }}
            </p>

            <p
              class="d-block caption line-height-21 mb-0 body-font ls-n008 fontcolor-grey-darken d-sm-none text-uppercase"
            >
              {{ productDetails.sku }}
            </p>
          </v-col>
        </v-row>
      

        <p
          class="d-none d-sm-block body-font caption line-height-21 ls-n008 fontcolor-grey-darken text-uppercase pb-1"
        >
          sku {{ productDetails.sku }}
        </p>
        <p
          class="fontsize-16 mt-4 line-height-21 body-font ls-n01 fontcolor-black-darken"
          v-html="productDetails.description"
        ></p>
        <div v-if="productDetails && productDetails.customAttribute.length" class="mt-4">
          <div v-show="!cartInProgress">
            <div v-if="!fetchInProgress">
              <v-form ref="giftCardForm" v-model="valid">
                <v-row class>
                  <v-col cols="12" sm="6" md="6" class="pb-0">
                    <p
                      class="body body-font mb-0 ls-n01 fontcolor-black-darken"
                    >
                      Amount
                    </p>
                    <div
                      v-if="
                        getCustomAttributeLable(
                          productDetails.customAttribute,
                          'giftcard_amounts'
                        )
                      "
                    >
                      <div v-show="!userAuth">
                        <v-hover v-slot:default="{ isHovering, props }">
                          <v-select
                             v-bind="props"
                            :items="giftPriceOptions()"
                            class="word-break fontsize-16 line-height-21 ls-n009 font-weight-400 productpage-select-button select-change-icon"
                            density="compact"
                            variant="outlined"
                            item-title="value"
                            item-value="value"
                            single-line
                            v-model="giftCardAmtGuest"
                            ref="selectedGiftCardAmt"
                            background-color="transparent"
                            :append-inner-icon="mdiPlus"
                            :rules="!userAuth ? priceRules : []"
                            label="Choose an Amount"
                            required
                            :class="
                              giftCardAmtGuest ? '' : 'v-select-error-border'
                            "
                          >
                            <template v-slot:selection="{ item }">
                              <span
                                class="fontsize-16 line-height-09 ls-n009 regular-font font-weight-400 pl-3 pt-1 pb-2"
                                :class="isHovering || !isActive ? 'text-primary' : 'text-black'"
                                >
                                {{item && item.raw ? '$'+ item.raw.websiteValue : 'Choose an Amount'}}
                              </span>
                            </template>
                            <template v-slot:item="{ props, item }">
                              <v-list-item-title  v-bind="props"
                                class="amountListItem fontsize-16 ls-n009 regular-font font-weight-400 pl-3 pt-1 pb-2"
                                >{{item && item.raw ? '$'+ item.raw.websiteValue : 'Choose an Amount'}} </v-list-item-title
                              >
                            </template>

                          </v-select>
                        </v-hover>
                      </div>
                      <div v-show="userAuth">
                        <v-hover v-slot:default="{ isHovering, props }">
                          <v-select
                          v-bind="props"
                            :items="giftPriceOptions()"
                            class="word-break fontsize-16 line-height-21 ls-n009 font-weight-400 productpage-select-button select-change-icon"
                            density="compact"
                            variant="outlined"
                            attach
                            flat
                            solo
                            item-text="value"
                            item-value="value"
                            single-line
                            v-model="giftCardAmt"
                            ref="selectedGiftCardAmt"
                            background-color="transparent"
                            :append-inner-icon="mdiPlus"
                            :rules="userAuth ? priceRules : []"
                            label="Choose an Amount"
                            required
                            :class="giftCardAmt ? '' : 'v-select-error-border'"
                          >
                            <template v-slot:selection="{ item }">
                              <span
                                class="fontsize-16 line-height-21 ls-n009 regular-font font-weight-400"
                                :class="
                                  isHovering || !isActive
                                    ? 'text-primary'
                                    : 'text-black'
                                "
                                >{{item && item.raw ? '$'+ item.raw.websiteValue : 'Choose an Amount'}}</span
                              >
                            </template>
                            <template v-slot:item="{ props, item }">

                                <v-list-item-content @click="isActive = true">
                                  <v-list-item-title v-bind="props"
                                    class="amountListItem fontsize-16 pl-2 ls-n009 regular-font font-weight-400"
                                    >${{
                                      item.raw.websiteValue
                                    }}</v-list-item-title
                                  >
                                </v-list-item-content>

                            </template>
                          </v-select>
                        </v-hover>
                      </div>
                    </div>
                    <div v-else>
                      <h2 class="body-font mb-0 ls-n01 fontcolor-black-darken">
                        ${{ productDetails.price }}
                      </h2>
                    </div>
                  </v-col>
                  <v-col
                    align="right"
                    justify="right"
                    cols="12"
                    sm="6"
                    md="6"
                    class="pb-0"
                  >
                    <p
                      class="body body-font ls-n01 mb-0 fontcolor-black-darken text-left"
                    >
                      Qty
                    </p>
                    <div
                      v-if="
                        getCustomAttributeLable(
                          productDetails.customAttribute,
                          'qty_price'
                        ) && productDetails.status == '1'
                      "
                    >
                      <v-overflow-btn
                        right
                        class="body title-color-black py-0 mt-0 overflow-button"
                        :items="
                          JSON.parse(
                            getCustomAttributeLable(
                              productDetails.customAttribute,
                              'qty_price'
                            )
                          )
                        "
                        label="30 ($110.00)"
                        segmented
                        density="comfortable"
                        target="#dropdown-example"
                        item-text="price"
                        item-value="qty"
                        single-line
                        elevation="0"
                        v-model="selectedQty"
                      >
                        <template v-slot:selection="data">
                          <span
                            >{{ data.item.qty }} ($
                            {{ data.item.price }} )</span
                          >
                        </template>
                        <template v-slot:item="data">
                          <template>
                            <v-list-item-content>
                              <v-list-item-title class="text-uppercase"
                                >{{ data.item.qty }} ($
                                {{ data.item.price }} )</v-list-item-title
                              >
                            </v-list-item-content>
                          </template>
                        </template>
                      </v-overflow-btn>
                    </div>
                    <div v-else-if="productDetails.status == '1'">
                   
                      <v-select
                        :items="numberOptions"
                        class="word-break fontsize-16 line-height-21 ls-n009 font-weight-400 productpage-select-button select-change-icon"
                        density="compact"
                        variant="outlined"


                        v-model="selectedQty"
                        background-color="transparent"
                        ref="selectedQty4"
                        :append-inner-icon="mdiPlus"
                        :rules="[(v) => !!v || 'Please select the Quantity']"
                      ></v-select>
                    </div>
                  </v-col>
                </v-row>
                <v-row v-show="!userAuth">
                  <v-col cols="12" class="pb-1">
                    <v-text-field
                      label="Sender Name"
                      ref="fullName"
                      v-model="senderNameGuest"
                      :rules="!userAuth ? textRules : []"
                      variant="outlined"
                      required
                      density="compact"
                       color="primary"
                    ></v-text-field>
                    <v-text-field
                      label="Sender Email"
                      ref="email"
                      type="email"
                      id="senderEmail"
                      v-model="senderEmailGuest"
                      :rules="!userAuth ? emailRules : []"
                      variant="outlined"
                      required
                      density="compact"
                       color="primary"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" class="py-0">
                    <v-text-field
                      label="Recipient Name"
                      ref="fullName"
                      v-model="recipientNameGuest"
                      :rules="!userAuth ? textRules : []"
                      variant="outlined"
                      required
                      density="compact"
                       color="primary"
                    ></v-text-field>

                    <v-text-field
                      label="Recipient Email"
                      ref="email"
                      v-model="recipientEmailGuest"
                      type="email"
                      id="recipientEmail"
                      :rules="!userAuth ? emailRules : []"
                      variant="outlined"
                      required
                      density="compact"
                       color="primary"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <v-textarea
                      variant="outlined"
                      auto-grow
                      rows="5"
                      v-model="senderMessageGuest"
                       color="primary"
                    ></v-textarea>
                  </v-col>
                </v-row>
                <v-row v-show="userAuth">
                  <v-col cols="12" class="pb-1">
                    <v-text-field
                      label="Sender Name"
                      ref="fullName"
                      v-model="firstname"
                      :rules="userAuth ? textRules : []"
                      variant="outlined"
                      required
                      density="compact"
                       color="primary"
                    ></v-text-field>
                    <v-text-field
                      label="Sender Email"
                      ref="email"
                      type="email"
                      id="senderEmail"
                      v-model="email"
                      :rules="userAuth ? emailRules : []"
                      variant="outlined"
                      required
                      density="comfortable"
                       color="primary"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <v-text-field
                      label="Recipient Name"
                      ref="fullName"
                      v-model="recipientName"
                      :rules="userAuth ? textRules : []"
                      variant="outlined"
                      required
                      density="compact"
                       color="primary"
                    ></v-text-field>

                    <v-text-field
                      label="Recipient Email"
                      ref="email"
                      v-model="recipientEmail"
                      type="email"
                      id="recipientEmail"
                      :rules="userAuth ? emailRules : []"
                      variant="outlined"
                      required
                      density="compact"
                       color="primary"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <v-textarea
                      variant="outlined"
                      auto-grow
                      rows="5"
                      v-model="senderMessage"
                       color="primary"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-form>
              <p
                class="py-0 my-0 text-red"
                v-if="
                  productDetails.stockDetails.manageStock === 1 &&
                  productDetails.stockDetails.qty <= 0
                "
              >
                {{
                  getCustomAttributeValue(
                    productDetails.customAttribute,
                    "backorder_status"
                  )
                }}
              </p>
            </div>
            <div v-else class="text-center">
              <v-progress-circular
                :size="60"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </div>
          </div>
          <v-row v-if="!itemId">
            <v-col
              class="pt-4"
              v-if="productDetails.status == '1' && stockFlag"
            >
              <v-hover v-slot:default="{ isHovering, props }">
                <v-btn
                v-bind="props"
                  :disabled="cartInProgress"
                  block
                  size="x-large"
                  :variant="cartInProgress || isHovering ? 'outlined' : 'flat'"
                  :color="isHovering ? 'white' : 'primary'"
                  :class="isHovering ? 'text-primary' : 'text-white'"
                  class="text-uppercase ls-0 body productpage-button rounded-0"
                  tile
                  depressed
                  @click="addToGiftCart(productDetails.sku, productDetails)"
                >
                  <v-progress-circular
                    align="center"
                    :size="40"
                    block
                    color="primary"
                    indeterminate
                    v-if="cartInProgress"
                  ></v-progress-circular>
                  <span v-if="!cartInProgress">ADD TO CART</span>
                </v-btn>
              </v-hover>
            </v-col>

            <v-col
              class="pt-4"
              v-if="productDetails.status == '2' || !stockFlag"
            >
              <v-btn
                block
                x-large
                class="text-uppercase ls-0 body pb-1 text-white"
                tile
                color="#97ABB4"
                depressed
                >UNAVAILABLE</v-btn
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-4" v-if="itemId">
              <v-hover v-slot:default="{ isHovering, props }">
                <v-btn
                v-bind="props"
                  :disabled="cartInProgress"
                  block
                  size="x-large"
                  depressed
                  :variant="cartInProgress || isHovering ? 'outlined': 'flat'"
                  :color="isHovering ? 'white' : 'primary'"
                  :class="isHovering ? 'text-primary' : 'text-white'"
                  class="text-uppercase ls-0 body productpage-button rounded-0"
                  tile
                  @click="
                    updateToGiftCart(productDetails.name, productDetails.sku)
                  "
                >
                  <v-progress-circular
                    align="center"
                    :size="40"
                    color="primary"
                    indeterminate
                    v-if="cartInProgress"
                  ></v-progress-circular>
                  <span v-if="!cartInProgress">UPDATE CART</span>
                </v-btn>
              </v-hover>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="pt-5">
              <v-tabs
              v-model="tab"
              slider-color="primary"
              color="black"
              class="product-overview-tabs"
              >
              <v-tab
                value="overview"
                class="fontsize-14
                mb-0
                regular-font
                ls-n008
                text-capitalize
                "
                >
                  Overview
                </v-tab>

                <v-tab
                value="materials"
                class="
                  fontsize-14
                  regular-font
                  ls-n008
                  text-capitalize
                "
                >Materials</v-tab
              >
              <v-tab
                value="shipping"
                class="
                  fontsize-14
                  regular-font
                  ls-n008
                  text-capitalize
                "
                >Shipping</v-tab
              >
            </v-tabs>
            <v-card-text class="listOfTabItems">
              <v-window v-model="tab" class="pl-6 pl-xl-10 pt-2">
                <v-window-item value="overview">
                  <ul class="py-3 fontsize-16 body-font ls-n008">
                    <li
                      v-if="
                        getCustomAttributeLable(
                          productDetails.customAttribute,
                          'brand'
                        )
                      "
                    >
                      {{
                        getCustomAttributeLable(
                          productDetails.customAttribute,
                          "brand"
                        )
                      }}
                    </li>
                    <li
                      v-if="
                        getCustomAttributeLable(
                          productDetails.customAttribute,
                          'product_def_type'
                        )
                      "
                    >
                      {{
                        getCustomAttributeLable(
                          productDetails.customAttribute,
                          "product_def_type"
                        )
                      }}
                    </li>
                    <li
                      v-if="
                        getCustomAttributeLable(
                          productDetails.customAttribute,
                          'unit_description'
                        )
                      "
                    >
                      {{
                        getCustomAttributeLable(
                          productDetails.customAttribute,
                          "unit_description"
                        )
                      }}
                    </li>
                    <li
                      v-if="
                        getCustomAttributeLable(
                          productDetails.customAttribute,
                          'product_dimensions'
                        )
                      "
                    >
                      {{
                        getCustomAttributeLable(
                          productDetails.customAttribute,
                          "product_dimensions"
                        )
                      }}
                    </li>
                    <li
                      v-if="
                        getCustomAttributeLable(
                          productDetails.customAttribute,
                          'printing_process'
                        )
                      "
                    >
                      Printing Process -
                      {{
                        getCustomAttributeLable(
                          productDetails.customAttribute,
                          "printing_process"
                        )
                      }}
                    </li>
                    <li
                      v-if="
                        getCustomAttributeLable(
                          productDetails.customAttribute,
                          'additional_feature_1'
                        )
                      "
                    >
                      {{
                        getCustomAttributeLable(
                          productDetails.customAttribute,
                          "additional_feature_1"
                        )
                      }}
                    </li>
                    <li
                      v-if="
                        getCustomAttributeLable(
                          productDetails.customAttribute,
                          'additional_feature_2'
                        )
                      "
                    >
                      {{
                        getCustomAttributeLable(
                          productDetails.customAttribute,
                          "additional_feature_2"
                        )
                      }}
                    </li>
                    <li
                      v-if="
                        getCustomAttributeLable(
                          productDetails.customAttribute,
                          'additional_feature_3'
                        )
                      "
                    >
                      {{
                        getCustomAttributeLable(
                          productDetails.customAttribute,
                          "additional_feature_3"
                        )
                      }}
                    </li>
                    <li
                      v-if="
                        getCustomAttributeLable(
                          productDetails.customAttribute,
                          'additional_feature_4'
                        )
                      "
                    >
                      {{
                        getCustomAttributeLable(
                          productDetails.customAttribute,
                          "additional_feature_4"
                        )
                      }}
                    </li>
                    <li
                      v-if="
                        getCustomAttributeLable(
                          productDetails.customAttribute,
                          'additional_feature_5'
                        )
                      "
                    >
                      {{
                        getCustomAttributeLable(
                          productDetails.customAttribute,
                          "additional_feature_5"
                        )
                      }}
                    </li>
                    <li
                      v-if="
                        getCustomAttributeLable(
                          productDetails.customAttribute,
                          'additional_feature_6'
                        )
                      "
                    >
                      {{
                        getCustomAttributeLable(
                          productDetails.customAttribute,
                          "additional_feature_6"
                        )
                      }}
                    </li>
                  </ul>
                </v-window-item>

                <v-window-item value="materials">
                  <ul class="py-3 fontsize-16 body-font ls-n008">
                    <li
                      v-if="
                        getCustomAttributeLable(
                          productDetails.customAttribute,
                          'cotton_paper'
                        )
                      "
                    >
                      {{
                        getCustomAttributeLable(
                          productDetails.customAttribute,
                          "cotton_paper"
                        )
                      }}
                    </li>
                    <li
                      v-if="
                        getCustomAttributeLable(
                          productDetails.customAttribute,
                          'paper_weight'
                        )
                      "
                    >
                      {{
                        getCustomAttributeLable(
                          productDetails.customAttribute,
                          "paper_weight"
                        )
                      }}
                    </li>
                    <li
                      v-if="
                        getCustomAttributeLable(
                          productDetails.customAttribute,
                          'made_usa'
                        )
                      "
                    >
                      {{
                        getCustomAttributeLable(
                          productDetails.customAttribute,
                          "made_usa"
                        )
                      }}
                    </li>
                  </ul>
                </v-window-item>

                <v-window-item value="shipping">
                  <ul class="py-3 fontsize-16 body-font ls-n008">
                    <li
                      v-if="
                        getCustomAttributeLable(
                          productDetails.customAttribute,
                          'processing_time'
                        )
                      "
                    >
                      {{
                        getCustomAttributeLable(
                          productDetails.customAttribute,
                          "processing_time"
                        )
                      }}
                    </li>
                    <li>Standard shipping available</li>
                  <li>Expedited shipping available</li>
                  <li>Ships from Cohoes, NY</li>
                  </ul>
                </v-window-item>
              </v-window>
            </v-card-text>
             
            </v-col>
          </v-row>
        </div>
        <div v-else>
          <v-skeleton-loader
            v-bind="attrs"
            type="list-item-three-line"
            class="ml-0 pl-0"
          ></v-skeleton-loader
          ><br />
          <v-skeleton-loader
            v-bind="attrs"
            type="list-item-two-line"
            class="ml-0 pl-0"
          ></v-skeleton-loader
          ><br />
          <v-skeleton-loader
            v-bind="attrs"
            type="actions"
            class="ml-0 pl-0"
          ></v-skeleton-loader>
        </div>
      </v-col>
    </v-row>
    <SnackbarComponent :snackbar="snackbar"></SnackbarComponent>
    <v-dialog v-model="signInForm" persistent flat max-width="500">
      <v-card flat>
        <v-card-title class="d-flex justify-space-between">
          <p class="h3 mb-0 whyte-regular-font">Sign In</p>
          <v-btn
            @click="closeSignInForm()"
            density="comfortable"
            variant="text"
            :ripple="false"
            icon
          >
          
            <v-icon :icon="mdiWindowClose"/>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-3 pb-0">
          <v-alert class="word-break" type="error" v-if="!!errorMessage">{{
            errorMessage
          }}</v-alert>
          <v-alert class="word-break" type="success" v-if="!!signInMessage">{{
            signInMessage
          }}</v-alert>
          <v-form ref="loginForm" v-model="validLogin">
            <v-col class="pt-3 pb-1">
              <v-text-field
                v-model="emailLogin"
                type="email"
                label="Email Address"
                ref="email"
                name="email"
                variant="outlined"
                density="comfortable"
                :rules="emailRules"
              ></v-text-field>
            </v-col>
            <v-col class="pt-1 pb-0">
              <v-text-field
                v-model="password"
                label="Password"
                ref="password"
                type="password"
                name="password"
                variant="outlined"
                density="comfortable"
                :rules="passwordRules"
              ></v-text-field>
            </v-col>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-row no-gutters>
            <v-col cols="12" class="px-7">
              <v-btn
                variant="flat"
                block
                color="primary"
                class="text-white"
                @click="signIn(productDetails.sku)"
              >
                <v-progress-circular
                  :size="20"
                  color="white"
                  indeterminate
                  v-if="inProgress"
                ></v-progress-circular>
                &nbsp;SIGN IN
              </v-btn>
            </v-col>
            <v-col cols="6" class="py-5 pl-7" align="left">
              <span
                class="text-decoration-none cursor-pointer text-primary"
                @click="openSignUpDialog()"
              >Sign Up</span>
            </v-col>
            <v-col cols="6" class="py-5 pr-7" align="right">
              <span
                class="text-decoration-none cursor-pointer text-primary"
                @click="goToForgetPassword()"
                >Forgot Your Password?</span
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

  
    <v-dialog v-model="signUpForm" persistent flat max-width="500">
      <v-card flat>
        <v-card-title class="d-flex justify-space-between">
          <p class="h3 mb-0 whyte-regular-font">Sign Up</p>
          <v-btn
            @click="closeSignUpForm()"
            density="comfortable"
            variant="text"
            :ripple="false"
            icon>
           
            <v-icon :icon="mdiWindowClose"/>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-3 pb-0">
          <v-alert class="word-break" type="error" v-if="!!errorMessage">{{errorMessage}}</v-alert>
          <v-alert class="word-break" type="success" v-if="!!signUpMessage">{{signUpMessage}}</v-alert>
          <v-form ref="registerForm" v-model="validRegister">
            <v-text-field
              class="pt-2"
              label="First Name"
              type="text"
              ref="firstName"
              name="firstName"
              variant="outlined"
              density="comfortable"
              v-model="signUpFirstName"
              :rules="firstNameRules"
            ></v-text-field>
            <v-text-field
              class="pt-2"
              label="Last Name"
              type="text"
              ref="lastName"
              name="lastName"
              variant="outlined"
              density="comfortable"
              v-model="signUpLastName"
              :rules="lastNameRules"
            ></v-text-field>

            <v-text-field
              class="pt-2"
              label="Email Id"
              type="email"
              ref="email"
              name="email"
              id="userEmail"
              variant="outlined"
              density="comfortable"
              v-model="signUpEmail"
              :rules="signUpEmailRules"
            ></v-text-field>

            <v-text-field
              class="pt-2 mt-0"
              type="password"
              label="Password"
              ref="password"
              name="password"
              variant="outlined"
              density="comfortable"
              v-model="signUpPassword"
              :rules="commonValidationRules.passwordRule"
            ></v-text-field>
            <v-checkbox v-model="signUpNewsLetter" label="Subscribe to our newsletter" hide-details="true" color="indigo"></v-checkbox>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-row no-gutters>
            <v-col cols="12" class="px-7">
              <v-btn
                variant="flat"
                block
                color="primary"
                class="text-white"
                @click="signUp(productDetails.sku)"
                :disabled="isSignUp"
              > <v-progress-circular :size="20" color="white" indeterminate v-if="inProgress"></v-progress-circular> &nbsp;SIGN UP </v-btn>
            </v-col>
            <v-col cols="6" class="py-5 pl-7" align="left">
              <span
                class="text-decoration-none cursor-pointer text-primary"
                @click="openSignInDialog()"
              >Sign In</span>
            </v-col>
            <v-col cols="6" class="py-5 pr-7" align="right">
              <span
                class="text-decoration-none cursor-pointer text-primary"
                @click="goToForgetPassword()"
              >Forgot Your Password?</span>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { mdiWindowClose, mdiPlus, mdiHeart, mdiHeartOutline } from '@mdi/js'
import SnackbarComponent from "@/components/common/SnackbarComponent.vue";
import {
  getLable,
  googleAanalytics,
  customAttributeValue,
  setCategoryNameInLocal,
  commonValidationRules
} from "@/services/util.service";
import { createHelpers } from "vuex-map-fields";
import * as easings from "vuetify/lib/services/goto/easing-patterns";
const mediaImageUrl = import.meta.env.VITE_APP_MEDIA_IMAGES_API_URL;

const { mapFields: mapFieldsAuth } = createHelpers({
  getterType: "auth/getField",
  mutationType: "auth/updateField",
});
const { mapFields: registerInfo } = createHelpers({
  getterType: "registration/getField",
  mutationType: "registration/updateField",
});

const { mapFields: guest } = createHelpers({
  getterType: "cartProduct/getField",
  mutationType: "cartProduct/updateField",
});
const { mapFields: customer } = createHelpers({
  getterType: "userCartProduct/getField",
  mutationType: "userCartProduct/updateField",
});
const { mapFields: customerInfo } = createHelpers({
  getterType: "accountOne/getField",
  mutationType: "accountOne/updateField",
});
import productDefaultImage from '@/assets/defaultProduct.jpg'

export default {
  name: "GiftCardDetailsComponent",
  props: ["stockFlag", "categoryId"],
  components: { SnackbarComponent },
  data: () => ({
    mdiWindowClose,
    mdiPlus,
    mdiHeartOutline,
    mdiHeart,

    mediaImageUrl,
     variants: [1366, 768, 360],
    attrs: {
      boilerplate: false,
      elevation: 0,
    },
    tab:null,
    productDefaultImage:productDefaultImage,
    isAmtActive: true,
    isQtyActive: true,
    isActive: true,
    easings: Object.keys(easings),
    snackbar: {
      show: false,
      message: null,
      color: null,
      timeout: 0,
    },
    textRules: [
      (v) => !!v || "This field is required",
      (v) => (v && v.length >= 3) || "This field must be at least 3 characters",
    ],
    priceRules: [(v) => !!v || "This field is required"],
    emailRules: [
      (v) => !!v || "Email Id is required",
      (v) => /.+@.+\..+/.test(v) || "Email Id must be valid",
    ],
    fillHeart:'mdiHeart',
    outlineHeart:'mdiHeartOutline',
    myBtnColor:null,
    isOutlineHeart:true,
    isFillHeart:false,
    valid: true,
    selectedQty: "",
    blue: "primary",
    white: "white",
    cartErrorMessage: "",
    selected: "",
    imagepath: "",
    fav: true,
    menu: false,
    message: false,
    hints: true,
    signInForm: false,
    passwordFields: false,
    passwordRules: [
      (v) => !!v || "Password is required",
      (v) => (v && v.length >= 8) || "Password must be at least 8 characters",
    ],
    validLogin: false,
    validRegister: false,
    isSignUp: false,
    lazy: false,
    signUpForm: false,
    commonValidationRules: commonValidationRules,
    firstNameRules: [
      v => !!v || "First Name is required",
      v => (v && v.length >= 3) || "First Name must be at least 3 characters"
    ],
    lastNameRules: [
      v => !!v || "Last Name is required",
      v => (v && v.length >= 3) || "Last Name must be at least 3 characters"
    ],
    
    signUpEmailRules: [
      v => !!v || "Email Id is required",
      v => /.+@.+\..+/.test(v) || "Email Id must be valid"
    ]
  }),
  computed: {
    savedForLaterList: function(){
      return this.$store.state.meSavedForLaterList.savedForLaterList;
    },
    productDetails: function () {
      return this.$store.state.productOne.productDetails;
    },
    ...guest([
      "giftCartDetailsGuest",
      "giftCartDetailsGuest.senderNameGuest",
      "giftCartDetailsGuest.senderEmailGuest",
      "giftCartDetailsGuest.recipientNameGuest",
      "giftCartDetailsGuest.recipientEmailGuest",
      "giftCartDetailsGuest.senderMessageGuest",
      "giftCartDetailsGuest.giftCardAmtGuest",
    ]),
    ...customer([
      "giftCartDetails",
      "giftCartDetails.recipientName",
      "giftCartDetails.recipientEmail",
      "giftCartDetails.senderMessage",
      "giftCartDetails.giftCardAmt",
    ]),
    ...mapFieldsAuth({
      one: "one",
      emailLogin: "one.email",
      password: "one.password",
      errorMessage: "errorMessage",
      inProgress: "inProgress",
      signInMessage: "signInMessage",
    }),
    ...registerInfo({
      signUpOne: "one",
      signUpFirstName: "one.firstname",
      signUpLastName: "one.lastname",
      signUpNewsLetter: "one.newsletter",
      signUpEmail: "one.email",
      signUpPassword: "one.password",
      signUpMessage: "signUpMessage"
    }),
    ...customerInfo(["one", "one.firstname", "one.email"]),
    imageBasePathUrl() {
      return import.meta.env.VITE_IMAGE_PATH_URL;
    },
    categoryListIdWise: function () {
      return this.$store.state.headerCategoryList.categoryListIdWise;
    },
    itemId: function () {
      this.pageScrollUp();
      return this.$route.query.itemId;
    },
    qty: function () {
      this.getMessageDetails();
      return this.$route.query.qty;
    },

    amtOptions: function () {
      if (this.productDetails.customAttribute) {
        let minSalesQty = this.getCustomAttributeLable(
          this.productDetails.customAttribute,
          "additional_feature_1"
        );
        let qtyIncrements = this.getCustomAttributeLable(
          this.productDetails.customAttribute,
          "ba_qty_increments"
        );
        minSalesQty = Number(minSalesQty) ? 50 : 50;
        qtyIncrements = Number(qtyIncrements) ? 25 : 25;

        let nums = [];
        nums[0] = minSalesQty;
        for (let i = 1; minSalesQty <= 750; i++) {
          minSalesQty = minSalesQty + qtyIncrements;
          nums[i] = minSalesQty;
        }
        if (nums.length > 0) return nums;
        else return "";
 
      } else {
        return "";
      }
    },
    numberOptions: function () {
      let vm = this;
      if (this.productDetails.customAttribute) {
        let minSalesQty = this.getCustomAttributeLable(
          this.productDetails.customAttribute,
          "ba_min_sales_qty"
        );
        let qtyIncrements = this.getCustomAttributeLable(
          this.productDetails.customAttribute,
          "ba_qty_increments"
        );
        minSalesQty = Number(minSalesQty) ? Number(minSalesQty) : 1;
        qtyIncrements = Number(qtyIncrements) ? Number(qtyIncrements) : 1;

        let nums = [];
        nums[0] = minSalesQty;
        if (this.qty) {
          vm.selectedQty = Number(this.qty);
        } else {
          vm.selectedQty = minSalesQty;
        }
        for (let i = 1; i <= 98; i++) {
          minSalesQty = minSalesQty + qtyIncrements;
          nums[i] = minSalesQty;
        }
        if (nums.length > 0) return nums;
        else return "";

       
      } else {
        return "";
      }
    },
    userAuth: function () {
      return this.$store.state.auth.userAuth;
    },
    guestQuoteId: function () {
      return this.$store.state.guestInfo.guestQuoteId;
    },
    cartInProgress: function () {
      if (this.userAuth) {
        return this.$store.state.userCartProduct.inProgress;
      } else {
        return this.$store.state.cartProduct.inProgress;
      }
    },
    fetchInProgress: function () {
      if (this.userAuth) {
        return this.$store.state.userCartProduct.fetchInProgress;
      } else {
        return this.$store.state.cartProduct.fetchInProgress;
      }
    },
  },
  watch: {
    productDetails() {
      this.imagepath = this.productDetails.mediaGalleryEntries
        ? this.productDetails.mediaGalleryEntries[0].file
        : "";
    },
    savedForLaterList(){
      this.heartButtonActive();
    }
  },
  methods: {
      fetchProductImageName(imageFullName) {
      let fileExtention = imageFullName.split("/");
      let filename = fileExtention[fileExtention.length - 1].split(".")[0];
      return filename;
    },
    heartButtonActive()
    {
     if(this.savedForLaterList && this.savedForLaterList.wishlistItems.length)
          {

            for(let i = 0; i < this.savedForLaterList.wishlistItems.length; i++){
            if(this.productDetails.sku === this.savedForLaterList.wishlistItems[i].product.sku){
              this.isFillHeart = true;
              this.isOutlineHeart=false;
              this.myBtnColor = 'red'
            }
        }
          }
    },
    async addToSaveToLater(sku) {
      if (this.userAuth) {
        await this.$store
          .dispatch("savedForLaterOne/addToSavedForLater", {
            sku: sku,
          })
          .then(() => {
            this.$store.dispatch("meSavedForLaterList/fetchSavedForLaterList");
            this.snackbar = {
              message: "Product added in wishlist successfully",
              color: "success",
              show: true,
              timeout: 3000,
            };
          })
          .catch((error) => {
            this.snackbar = {
              message: error.message,
              color: "error",
              show: true,
              timeout: 3000,
            };
          });
      } else {
        this.signInForm = true;
      }
    },
    async signIn(sku) {
    
      const { valid } = await this.$refs.loginForm.validate()
      if (valid) {
        this.$store.dispatch("auth/changeRedirectFlag", false);
        this.$store.dispatch("auth/login").then((status) => {
          if (status) {
            this.$store.dispatch("auth/changeRedirectFlag", true);
            this.$store.dispatch("accountOne/basicInfo");
            this.$store.dispatch("userCartProduct/cartProductList");
           
            this.addToSaveToLater(sku);
            this.signInForm = false;
          }
        });
      }
    },
    async signUp(sku) {
     
      const { valid } = await this.$refs.registerForm.validate()
      if (valid) {
        try {
          this.isSignUp = true;
          this.$store.dispatch("auth/changeRedirectFlag", false);
          let resp = await this.$store.dispatch("registration/signUp");
          // console.log(resp);
          if (resp.registration) {

            this.$store.dispatch("auth/changeRedirectFlag", false);
            this.$store.dispatch("accountOne/basicInfo");
            this.$store.dispatch("userCartProduct/cartProductList");
            this.addToSaveToLater(sku);
            this.signUpForm=false;
            this.signInForm=false;
             
          }
          else {
            this.snackbar = {
              message: resp.errorMessage,
              color: "error",
              show: true,
              timeout: 3000,
            };
          }
          this.isSignUp = false;
        }
        catch(error) {
          this.isSignUp = false;
          console.log(error);
          this.snackbar = {
            message: error.message,
            color: "error",
            show: true,
            timeout: 3000,
          };
        }
      }

    },
    closeSignInForm() {
      this.$refs.loginForm.reset()
      this.signInForm = false;
      this.$store.dispatch("auth/changeRedirectFlag", true);
    },
    closeSignUpForm() {
      this.$refs.registerForm.reset()
      this.signUpForm = false;
      this.$store.dispatch("auth/changeRedirectFlag", true);
    },
    openSignInDialog() {
      this.signInForm = true
      this.signUpForm = false
     
      this.$refs.registerForm.reset()
    },
    openSignUpDialog() {
      this.signInForm = false
      this.signUpForm = true 
    },
    goToForgetPassword() {
      window.open(`/gate/forgot-password`, "_self");
    },

    getCustomAttributeValue(customAttributes, attributeCode) {
      return customAttributeValue(customAttributes, attributeCode);
    },
    setAltImg() {
      event.target.src = this.productDefaultImage;
    },
    giftPriceOptions() {
      let vm = this;
      let list = vm.getCustomAttributeLable(
        this.productDetails.customAttribute,
        "giftcard_amounts"
      );
      return list;
    },
    async addToGiftCart(sku, productDetails) {
      try{
      let validate = await this.$refs.giftCardForm.validate()
      if (validate && validate.valid) {
        let vm = this;
        let qtyNew = this.selectedQty;
        if (this.userAuth) {
          this.$store
            .dispatch("userCartProduct/addToGiftCart", {
              sku: sku,
              qty: this.selectedQty,
              senderName: this.one.firstname,
              senderEmail: this.one.email,
            })
            .then((res) => {
              googleAanalytics(
                vm,
                res,
                productDetails,
                qtyNew,
                "add_to_cart",
                "Ecommerce",
                vm.categoryListIdWise
              );
              if (this.categoryId) {
                setCategoryNameInLocal(this.categoryId, res);
              }
              this.$store.dispatch("userCartProduct/cartProductList");
              
              this.$store.dispatch("userCartProduct/cartDrawer", true);
              this.snackbar = {
                message: "Product Added Successfully",
                color: "success",
                show: true,
                timeout: 2000,
              };
            })
            .catch((error) => {
              this.snackbar = {
                message: error.message,
                color: "error",
                show: true,
                timeout: 2000,
              };
            });
        } else {
          this.$store
            .dispatch("cartProduct/addToGiftCart", {
              sku: sku,
              qty: this.selectedQty,
            })
            .then((res) => {
              googleAanalytics(
                vm,
                res,
                productDetails,
                qtyNew,
                "add_to_cart",
                "Ecommerce",
                vm.categoryListIdWise
              );
              if (this.categoryId) {
                setCategoryNameInLocal(this.categoryId, res);
              }
              this.snackbar = {
                message: "Product Added Successfully",
                color: "success",
                show: true,
                timeout: 2000,
              };
            })
            .catch((error) => {
              this.snackbar = {
                message: error.message,
                color: "error",
                show: true,
                timeout: 2000,
              };
            });
        }
      }
      }
      catch(error)
      {
        console.log('error',error);
      }
    },
    async updateToGiftCart(productName, sku) {
      try{
      if (this.selectedQty) {
        this.cartErrorMessage = "";
        if (this.userAuth) {
          try {
           let res= await this.$store.dispatch("userCartProduct/updateToGiftCart", {
              sku: sku,
              qty: this.selectedQty,
              itemId: this.itemId,
              senderName: this.one.firstname,
              senderEmail: this.one.email,
            });
            this.snackbar = {
              message: "Product Updated Successfully",
              color: "success",
              show: true,
              timeout: 3000,
            };

             if(res)
            {

              this.$router.replace({
                  query: { qty: this.selectedQty, itemId: res.item_id },
               })
            }

          } catch (error) {
            this.snackbar = {
              message: error.message,
              color: "error",
              show: true,
              timeout: 3000,
            };
          }
        } else {
          try {
          let res=  await this.$store.dispatch("cartProduct/updateToGiftCart", {
              sku: sku,
              qty: this.selectedQty,
              guestQuote: this.guestQuoteId,
              itemId: this.itemId,
            });

            this.snackbar = {
              message: "Product Updated Successfully",
              color: "success",
              show: true,
              timeout: 3000,
            };
            if(res)
            {

              this.$router.replace({
                  query: { qty: this.selectedQty, itemId: res.item_id },
               })
            }

          } catch (error) {
            this.snackbar = {
              message: error.message,
              color: "error",
              show: true,
              timeout: 3000,
            };
          }
        }
      

      } else {
        this.cartErrorMessage = "Please Select Quantity";
      }
      }
      catch(error)
      {
        console.log('error',error);
      }
    },
    getCustomAttributeLable(customAttributes, attributeCode) {
      return getLable(customAttributes, attributeCode);
    },
    setSelected(part, index) {
      this.imagepath = part;
      this.selected = index;
    },
    pageScrollUp() {
      
      const data=  this.$refs.divOne
      if(data){
        this.$smoothScroll({
          scrollTo: data.$el.scrollTop,
          duration: 300,
          easingFunction: "easeInOutCubic",
           updateHistory: false
        })
      }
    },
    async getMessageDetails() {
      if (this.itemId) {
        if (this.userAuth) {
          await this.$store.dispatch("userCartProduct/cartProductList");
          await this.$store.dispatch("userCartProduct/fetchGiftMessage", {
            itemId: this.itemId,
          });
        } else {
          this.$store.dispatch("cartProduct/fetchGiftMessage", {
            guestQuote: this.guestQuoteId,
            itemId: this.itemId,
          });
        }
      }
    },
  },
  mounted() {
    this.pageScrollUp();
  },
 
};
</script>
<style lang="scss">
 

.product-overview-tabs{
  color: rgba(77, 70, 70, 0.54)
}
.product-overview-tabs .v-tabs-slider-wrapper {
  color: #2850da;
  height: 4px !important;
}

.product-overview-tabs .v-tabs-items {
  background-color: transparent !important;
}
.product-overview-tabs .v-tabs-bar {
  background-color: transparent !important;
  height: 35px !important;
}
.product-overview-tabs1 .v-slide-group__prev {
  display: none !important;
}
.v-text-field .v-input__control {
  border-radius: unset !important;
  height: auto; //48px;
}
.productpage-select-button .v-input__control .v-input__slot {
  border: thin solid #8b8b8b !important;
}
.productpage-select-button:hover .v-input__control .v-input__slot {
  border: thin solid #2850da !important;
}
.productpage-select-button.v-input--is-label-active.v-input--is-focused
  .v-select__selection.v-select__selection--comma {
  color: #2850da !important;
}
.productpage-select-button:hover
  .v-select__selection.v-select__selection--comma {
  color: #2850da !important;
}
.productpage-select-button:hover .v-input__append-inner .v-input__icon .v-icon {
  color: #2850da !important;
}
.productpage-select-button .v-input__append-inner .v-input__icon .v-icon {
  font-size: 20px !important;
}
.productpage-select-button.v-input--is-label-active.v-input--is-focused
  .v-input__control
  .v-input__slot {
  border: thin solid #2850da !important;
}
 
.productpage-select-button.v-text-field.v-text-field--solo.v-select--is-menu-active
  .v-input__append-inner {
  -ms-flex-item-align: center;
  align-self: center;
  padding-top: 7px;
  border-left: 1px solid #2850da;
  padding-left: 12px;
  height: 37px;
}
.productpage-select-button
  .v-input__control
  .v-select__slot
  .v-select__selections {
  justify-content: center;
}
.productpage-select-button.v-select.v-text-field input {
  display: none;
}
.productpage-select-button .v-input__control .v-input__slot {
  padding-left: 0px !important;
}
.v-select-error-border.error--text .v-input__control .v-input__slot {
  border: 2px solid #ff5252 !important;
}
.v-tab__slider{
  height: 4px;
}
.line-height-09 {
  line-height: 0.925rem !important;
}
.productpage-part-select-menu.v-menu>.v-overlay__content>.v-list {
  border: thin solid #2850da !important;
  border-radius: 0px !important;
}
</style>
<style src="./ProductDetailComponent.scss" lang="scss" scoped/>
