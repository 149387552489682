<template>
  <v-container class="bg-white" ref="divOne">
    <v-row
      class="mb-md-10"
      v-if="!fetchErrorMessage && configuredProductDetails"
    >
      <v-col
        cols="1"
        class="d-none d-sm-block pr-4"
        v-if="configuredProductDetails[0].mediaGalleryEntries.length"
      >
        <div
          v-for="(productImage, index) in configuredProductDetails[0]
            .mediaGalleryEntries"
          :key="index"
        >
          <div v-if="productImage.mediaType === 'image'">
            <v-col class="pb-2 pt-0 pl-0 pr-0">
              <v-hover v-slot="{ isHovering, props }">
                <v-card
                  :variant="
                    index == selected || isHovering ? 'outlined' : 'plain'
                  "
                  color="primary"
                  v-bind="props"
                  @click="setSelected(productImage.file, index)"
                  v-bind:class="{ primary: index == selected }"
                  tile
                  v-if="productImage.file"
                  class="productpage-mediatype-image"
                  rounded="0"
                >
                  <v-img
                    :src="
                      mediaImageUrl +
                      '/' +
                      fetchProductImageName(productImage.file) +
                      '_360.webp'
                    "
                    @error="setAltImg()"
                    :alt="productDetails.name"
                    :title="productDetails.name"
                  ></v-img>
                </v-card>
              </v-hover>
            </v-col>
          </div>
        </div>
      </v-col>
      <v-col
        cols="1"
        class="d-none d-sm-block pr-4"
        v-else-if="configuredProductDetails[0].mediaGalleryEntries.length == 0"
      >
        <v-col class="pb-2 pt-0 px-0">
          <v-hover v-slot="{ isHovering, props }">
            <v-card
              v-bind="props"
              :variant="index == selected || isHovering ? 'outlined' : 'plain'"
              color="primary"
              tile
              rounded="0"
            >
              <picture>
                <template v-for="imgWidth in variants" :key="imgWidth">
                  <source
                    :media="`(min-width:${imgWidth}px)`"
                    :srcset="
                      mediaImageUrl +
                      '/' +
                      fetchProductImageName(imageBasePathUrl) +
                      '_' +
                      imgWidth +
                      '.webp'
                    "
                    style="width: 100%"
                  />
                </template>
                <img
                  :src="
                    mediaImageUrl +
                    '/' +
                    fetchProductImageName(imageBasePathUrl) +
                    '.webp'
                  "
                  style="width: 100%"
                  :alt="productDetails.name"
                  :title="productDetails.name"
                  onerror="this.onerror=null;this.parentNode.children[0].srcset=this.parentNode.children[1].srcset=this.parentNode.children[2].srcset='https://cdn.crane.com/stg-images/cc12011-1.webp';"
                />
              </picture>
            </v-card>
          </v-hover>
        </v-col>
      </v-col>

      <v-col cols="12" sm="7" md="7" class="pr-md-8 pb-0" v-if="imagepath">
        <v-row class="ma-0">
          <v-col cols="11" class="d-block d-sm-none pa-0 ma-0 pb-5">
            <h1 class="text-uppercase h3 ma-0 pa-0 ls-n01 title-color-black">
              {{ productDetails.name }}
            </h1>
          </v-col>
        </v-row>
        <picture>
          <template v-for="imgWidth in variants" :key="imgWidth">
            <source
              :media="`(min-width:${imgWidth}px)`"
              :srcset="
                mediaImageUrl +
                '/' +
                fetchProductImageName(imagepath) +
                '_' +
                imgWidth +
                '.webp'
              "
              style="width: 100%"
            />
          </template>
          <img
            :src="
              mediaImageUrl + '/' + fetchProductImageName(imagepath) + '.webp'
            "
            style="width: 100%"
            :alt="productDetails.name"
            :title="productDetails.name"
            onerror="this.onerror=null;this.parentNode.children[0].srcset=this.parentNode.children[1].srcset=this.parentNode.children[2].srcset='https://cdn.crane.com/stg-images/cc12011-1.webp';"
          />
        </picture>
      </v-col>
      <v-col cols="12" sm="7" md="7" class="pr-md-8 pb-0" v-else>
        <v-col class="d-block d-sm-none pa-0 ma-0 pb-5">
          <h1 class="text-uppercase h3 ma-0 pa-0 ls-n01 title-color-black">
            {{ productDetails.name }}
          </h1>
        </v-col>
        <picture>
          <template v-for="imgWidth in variants" :key="imgWidth">
            <source
              :media="`(min-width:${imgWidth}px)`"
              :srcset="
                mediaImageUrl +
                '/' +
                fetchProductImageName(
                  productDetails.mediaGalleryEntries.length
                    ? productDetails.mediaGalleryEntries[0].file
                    : ''
                ) +
                '_' +
                imgWidth +
                '.webp'
              "
              style="width: 100%"
            />
          </template>
          <img
            :src="
              mediaImageUrl +
              '/' +
              fetchProductImageName(
                productDetails.mediaGalleryEntries[0].file
              ) +
              '.webp'
            "
            style="width: 100%"
            :alt="productDetails.name"
            :title="productDetails.name"
            onerror="this.onerror=null;this.parentNode.children[0].srcset=this.parentNode.children[1].srcset=this.parentNode.children[2].srcset='https://cdn.crane.com/stg-images/cc12011-1.webp';"
          />
        </picture>
      </v-col>
      <v-row
        align="center"
        class="d-flex d-sm-none mx-auto px-1"
        v-if="configuredProductDetails[0].mediaGalleryEntries.length"
      >
        <v-col
          cols="1"
          class="d-none d-sm-block pr-4"
          v-if="configuredProductProgress"
        ></v-col>
        <v-col
          v-else
          v-for="(productImage, index) in configuredProductDetails[0]
            .mediaGalleryEntries"
          v-bind:key="index"
          v-show="productImage.mediaType === 'image'"
          class="px-2"
          cols="3"
        >
          <v-hover v-slot:default="{ isHovering, props }">
            <v-card
              max-width="70"
              max-height="70"
              class="mx-auto"
              color="primary"
              :variant="index == selected || isHovering ? 'outlined' : 'plain'"
              @click="setSelected(productImage.file, index)"
              v-bind="props"
              v-bind:class="{ primary: index == selected }"
              tile
              v-if="productImage.file"
            >
              <picture>
                <source
                  :media="`(min-width:100px)`"
                  :srcset="
                    mediaImageUrl +
                    '/' +
                    fetchProductImageName(productImage.file) +
                    '_360.webp'
                  "
                  style="width: 100%"
                />
                <img
                  :src="
                    mediaImageUrl +
                    '/' +
                    fetchProductImageName(productImage.file) +
                    '.webp'
                  "
                  style="width: 100%"
                  :alt="productImage.name"
                  :title="productImage.name"
                  onerror="this.onerror=null;this.parentNode.children[0].srcset=this.parentNode.children[1].srcset=this.parentNode.children[2].srcset='https://cdn.crane.com/stg-images/cc12011-1.webp';"
                />
              </picture>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
      <v-row
        align="center"
        class="d-flex d-sm-none mx-auto px-1"
        v-else-if="configuredProductDetails[0].mediaGalleryEntries.length == 0"
      >
        <v-col class="px-2" cols="3">
          <v-hover v-slot:default="{ isHovering }">
            <v-card
              max-width="70"
              max-height="70"
              class="mx-auto primary"
              outlined
              :color="isHovering ? blue : white"
              tile
            >
              <v-img
                v-bind:src="imageBasePathUrl + ''"
                max-width="70"
                max-height="70"
                @error="setAltImg()"
                :alt="productDetails.name"
                :title="productDetails.name"
              ></v-img>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>

      <v-col cols="12" sm="4" md="4" class="pl-md-12">
        <v-row>
          <v-col md="12" cols="12" class="pr-0 pt-0">
            <p
              class="d-none d-sm-block fontsize-28 regular-font line-height-36 title-color-black text-uppercase ma-0 pa-0 ls-n01"
            >
              {{ productDetails.name }}
            </p>
            <p
              v-if="!selectedConfigValue"
              class="d-block caption line-height-21 mb-0 body-font ls-n008 fontcolor-grey-darken d-sm-none text-uppercase"
            >
              {{ productDetails.sku }}
            </p>
            <p
              v-else
              class="d-block caption line-height-21 mb-0 body-font ls-n008 fontcolor-grey-darken d-sm-none text-uppercase"
            >
              {{ configuredProductDetails[0].sku }}
            </p>
          </v-col>
        </v-row>

        <p
          v-if="!selectedConfigValue"
          class="d-none d-sm-block body-font caption line-height-21 ls-n008 fontcolor-grey-darken text-uppercase pb-1"
        >
          sku {{ productDetails.sku }}
        </p>
        <p
          v-else
          class="d-none d-sm-block body-font caption line-height-21 ls-n008 fontcolor-grey-darken text-uppercase pb-1"
        >
          sku {{ configuredProductDetails[0].sku }}
        </p>

        <p
          class="mt-4 fontsize-16 line-height-21 body-font ls-n01 fontcolor-black-darken"
          v-html="productDetails.description"
        ></p>
        <div v-if="showZinreloRewards && showZinreloRewards == 'on'">
          <div
            v-if="rewardsPoints"
            class="my-2 fontsize-16 mt-4 line-height-21 body-font ls-n01 fontcolor-black-darken"
          >
            You will earn a minimum of
            <span class="text-primary text-underline"
              >{{ rewardsPoints }}
            </span>
            Reward Points with this purchase.
            <v-icon
              :icon="mdiAccountCircleOutline"
              class="ml-2"
              @click="signInPop()"
              size="small"
              v-if="!userAuth"
            />
          </div>
          <div
            v-else
            class="my-2 fontsize-16 mt-4 line-height-21 body-font ls-n01 fontcolor-black-darken"
          >
            You will earn Reward Points with this purchase.
            <v-icon
              :icon="mdiAccountCircleOutline"
              class="ml-2"
              @click="signInPop()"
              size="small"
              v-if="!userAuth"
            />
          </div>
        </div>

        <div
          v-if="
            configuredProductDetails[0] &&
            configuredProductDetails[0].customAttributes.length
          "
        >
          <v-row class="pt-3 mt-4">
            <v-col cols="6" sm="12" md="12" lg="5" class="pr-0 pb-0">
              <p
                class="body-font mb-0 caption ls-n008 fontcolor-grey-darken pb-1"
              >
                {{ productDetails.unitDescription }}
              </p>

              <div v-if="configuredProductDetails">
                <p
                  v-if="
                    getCustomAttributeValue(
                      configuredProductDetails[0].customAttributes,
                      'lowest_price'
                    )
                  "
                  class="body title-color-black"
                >
                  From ${{
                    Number(
                      getCustomAttributeValue(
                        configuredProductDetails[0].customAttributes,
                        "lowest_price"
                      )
                    )
                  }}
                  {{ productDetails.uom }}
                </p>
                <div
                  v-else
                  class="fontsize-14 body ls-n009 fontcolor-black-darken font-weight-300"
                >
                  <div
                    v-if="
                      getCustomAttributeValue(
                        configuredProductDetails[0].customAttributes,
                        'special_price'
                      )
                    "
                  >
                    <s>${{ Number(configuredProductDetails[0].price) }}</s>
                    &nbsp;
                    <span class="red--text mb-0"
                      >${{
                        Number(
                          getCustomAttributeValue(
                            configuredProductDetails[0].customAttributes,
                            "special_price"
                          )
                        )
                      }}</span
                    >
                    / {{ productDetails.uom }}
                  </div>
                  <p v-else class="body title-color-black">
                    ${{ configuredProductDetails[0].price }} /
                    {{ productDetails.uom }}
                  </p>
                </div>
              </div>
            </v-col>
            <v-col
              align="right"
              justify="right"
              cols="6"
              sm="12"
              md="12"
              lg="7"
              class="pb-0"
            >
              <v-select
                :items="numberOptions"
                class="word-break fontsize-16 line-height-21 ls-n009 font-weight-400 productpage-select-button select-change-icon"
                density="compact"
                variant="outlined"
                attach
                flat
                solo
                v-model="selectedQty"
                ref="selectedQty1"
                background-color="transparent"
                :append-inner-icon="mdiPlus"
                @update:modelValue="productReward(selectedQty)"
              ></v-select>
            </v-col>
            <v-col cols="12" class="pl-4 pb-0">
              <v-form
                ref="configuredLetter"
                v-model="valid"
                :lazy-validation="lazy"
              >
                <span
                  v-if="
                    productDetails.configurableProductOptions &&
                    configurableProductOptionsCheck(
                      productDetails.configurableProductOptions,
                      'choose_color'
                    )
                  "
                >
                  <div
                    v-for="(
                      color, index
                    ) in productDetails.configurableProductLinksDetails"
                    v-bind:key="index"
                    class="d-inline-block"
                  >
                    <v-tooltip location="bottom">
                      <template v-slot:activator="{ props }">
                        <div
                          v-bind="props"
                          class="color-circle d-flex justify-center align-center cursor-pointer"
                          v-bind:class="{
                            'color-circle-border':
                              color.letterLabel == selectedColor,
                          }"
                        >
                          <div
                            class="product-color-circle"
                            :style="
                              'background-color:' +
                              setHexaColor(color.letterLabel)
                            "
                            @click="
                              setColor(
                                color,
                                productDetails.configurableProductOptions[0]
                                  .options
                              )
                            "
                          ></div>
                        </div>
                      </template>
                      <span>{{ color.letterLabel }}</span>
                    </v-tooltip>
                  </div>
                  <div
                    v-if="requiredValueCheck && selectedColor == null"
                    class="text-red"
                  >
                    Please select color
                  </div>
                </span>
                <span
                  v-if="
                    productDetails.configurableProductOptions &&
                    configurableProductOptionsCheck(
                      productDetails.configurableProductOptions,
                      'Letter'
                    )
                  "
                >
                  <v-select
                    :items="productDetails.configurableProductLinksDetails"
                    class="word-break fontsize-16 line-height-21 ls-n009 font-weight-400 select-change-icon"
                    :class="checkLetter ? '' : 'productpage-select-button'"
                    density="compact"
                    attach
                    flat
                    solo
                    :variant="checkLetter ? 'outlined' : ''"
                    :color="checkLetter ? 'error' : 'primary'"
                    v-model="selectedConfigValue"
                    @blur="resetValidation()"
                    ref="selectedQty2"
                    item-title="letterLabel"
                    item-value="letterLabel"
                    label="Choose an option..."
                    single-line
                    @update:modelValue="
                      getImages(
                        productDetails.configurableProductOptions[0].options,
                        selectedConfigValue,
                        productDetails.configurableProductLinks
                      )
                    "
                    background-color="transparent"
                    :append-inner-icon="mdiPlus"
                    :rules="[(v) => !!v || 'This is a required field.']"
                  >
                    <template v-slot:selection="{ item }">
                      <span class="fontsize-14 pl-0">
                        {{
                          item && item.raw
                            ? item.raw.letterLabel
                            : "Choose an option..."
                        }}</span
                      >
                    </template>
                    <template v-slot:item="{ props, item }">
                      <v-list-item-title
                        v-bind="props"
                        class="letterLabelItems pl-4 text-uppercase"
                      >
                        {{ item.raw.letterLabel }}
                      </v-list-item-title>
                    </template>
                  </v-select>
                  <div
                    v-if="requiredValueCheck && selectedConfigValue == null"
                    class="text-red"
                  >
                    Please select option
                  </div>
                </span>
              </v-form>
            </v-col>
          </v-row>
          <p
            class="py-0 my-0 text-red"
            v-if="
              productDetails.stockDetails.manageStock === 1 &&
              productDetails.stockDetails.qty <= 0
            "
          >
            {{
              getCustomAttributeValue(
                productDetails.customAttribute,
                "backorder_status"
              )
            }}
          </p>
          <p class="py-0 my-0 text-red">{{ cartErrorMessage }}</p>
          <v-row v-if="!itemId">
            <v-col
              class="pt-4"
              v-if="
                productDetails.status == '1' &&
                getCustomAttributeValue(
                  productDetails.customAttribute,
                  'is_personalize'
                ) === '0' &&
                stockFlag
              "
            >
              <v-hover v-slot:default="{ isHovering, props }">
                <v-btn
                  v-bind="props"
                  :disabled="inProgress"
                  block
                  size="x-large"
                  :variant="inProgress || isHovering ? 'outlined' : 'flat'"
                  :color="isHovering ? 'white' : 'primary'"
                  :class="isHovering ? 'text-primary' : 'text-white'"
                  class="pa-6 text-uppercase ls-0 body productpage-button rounded-0"
                  tile
                  depressed
                  @click="
                    addToConfiguredCart(
                      productDetails.sku,
                      productDetails.typeId,
                      productDetails.configurableProductOptions[0].attributeId,
                      productDetails
                    )
                  "
                >
                  <v-progress-circular
                    align="center"
                    :size="40"
                    block
                    color="primary"
                    indeterminate
                    v-if="inProgress"
                  ></v-progress-circular>
                  <span v-if="!inProgress">ADD TO CART</span>
                </v-btn>
              </v-hover>
            </v-col>

            <v-col
              class="pt-4"
              v-if="productDetails.status == '2' || !stockFlag"
            >
              <v-btn
                block
                x-large
                class="text-uppercase ls-0 body pb-1 white--text"
                tile
                color="#97ABB4"
                depressed
                >UNAVAILABLE</v-btn
              >
            </v-col>
          </v-row>

          <v-row v-else>
            <v-col
              class="pt-4"
              v-if="
                itemId &&
                getCustomAttributeValue(
                  productDetails.customAttribute,
                  'is_personalize'
                ) === '0'
              "
            >
              <v-hover v-slot:default="{ isHovering, props }">
                <v-btn
                  v-bind="props"
                  :disabled="inProgress"
                  block
                  size="x-large"
                  depressed
                  :variant="inProgress || isHovering ? 'outlined' : 'flat'"
                  :color="isHovering ? 'white' : 'primary'"
                  :class="isHovering ? 'text-primary' : 'text-white'"
                  class="text-uppercase ls-0 body productpage-button rounded-0"
                  tile
                  @click="
                    updateToConfiguredCart(
                      productDetails.name,
                      productDetails.sku,
                      productDetails.typeId,
                      productDetails.configurableProductOptions[0].attributeId
                    )
                  "
                >
                  <v-progress-circular
                    align="center"
                    :size="40"
                    color="primary"
                    indeterminate
                    v-if="inProgress"
                  ></v-progress-circular>
                  <span v-if="!inProgress">UPDATE CART</span>
                </v-btn>
              </v-hover>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="pt-5">
              <v-tabs
                v-model="tab"
                slider-color="primary"
                color="black"
                class="product-overview-tabs"
              >
                <v-tab
                  value="overview"
                  class="fontsize-14 regular-font ls-n008 text-capitalize"
                >
                  Overview
                </v-tab>
                <v-tab
                  value="materials"
                  class="fontsize-14 regular-font ls-n008 text-capitalize"
                  >Materials</v-tab
                >
                <v-tab
                  value="shipping"
                  class="fontsize-14 regular-font ls-n008 text-capitalize"
                  >Shipping</v-tab
                >
              </v-tabs>
              <v-card-text>
                <v-window v-model="tab" class="pl-6 pl-xl-10">
                  <v-window-item value="overview">
                    <ul class="py-3 fontsize-16 body-font ls-n008">
                      <li
                        v-if="
                          getCustomAttributeLable(
                            productDetails.customAttribute,
                            'brand'
                          )
                        "
                      >
                        {{
                          getCustomAttributeLable(
                            productDetails.customAttribute,
                            "brand"
                          )
                        }}
                      </li>
                      <li
                        v-if="
                          getCustomAttributeLable(
                            productDetails.customAttribute,
                            'product_def_type'
                          )
                        "
                      >
                        {{
                          getCustomAttributeLable(
                            productDetails.customAttribute,
                            "product_def_type"
                          )
                        }}
                      </li>
                      <li
                        v-if="
                          getCustomAttributeLable(
                            productDetails.customAttribute,
                            'unit_description'
                          )
                        "
                      >
                        {{
                          getCustomAttributeLable(
                            productDetails.customAttribute,
                            "unit_description"
                          )
                        }}
                      </li>
                      <li
                        v-if="
                          getCustomAttributeLable(
                            productDetails.customAttribute,
                            'product_dimensions'
                          )
                        "
                      >
                        {{
                          getCustomAttributeLable(
                            productDetails.customAttribute,
                            "product_dimensions"
                          )
                        }}
                      </li>
                      <li
                        v-if="
                          getCustomAttributeLable(
                            productDetails.customAttribute,
                            'printing_process'
                          )
                        "
                      >
                        Printing Process -
                        {{
                          getCustomAttributeLable(
                            productDetails.customAttribute,
                            "printing_process"
                          )
                        }}
                      </li>
                      <li
                        v-if="
                          getCustomAttributeLable(
                            productDetails.customAttribute,
                            'additional_feature_1'
                          )
                        "
                      >
                        {{
                          getCustomAttributeLable(
                            productDetails.customAttribute,
                            "additional_feature_1"
                          )
                        }}
                      </li>
                      <li
                        v-if="
                          getCustomAttributeLable(
                            productDetails.customAttribute,
                            'additional_feature_2'
                          )
                        "
                      >
                        {{
                          getCustomAttributeLable(
                            productDetails.customAttribute,
                            "additional_feature_2"
                          )
                        }}
                      </li>
                      <li
                        v-if="
                          getCustomAttributeLable(
                            productDetails.customAttribute,
                            'additional_feature_3'
                          )
                        "
                      >
                        {{
                          getCustomAttributeLable(
                            productDetails.customAttribute,
                            "additional_feature_3"
                          )
                        }}
                      </li>
                      <li
                        v-if="
                          getCustomAttributeLable(
                            productDetails.customAttribute,
                            'additional_feature_4'
                          )
                        "
                      >
                        {{
                          getCustomAttributeLable(
                            productDetails.customAttribute,
                            "additional_feature_4"
                          )
                        }}
                      </li>
                      <li
                        v-if="
                          getCustomAttributeLable(
                            productDetails.customAttribute,
                            'additional_feature_5'
                          )
                        "
                      >
                        {{
                          getCustomAttributeLable(
                            productDetails.customAttribute,
                            "additional_feature_5"
                          )
                        }}
                      </li>
                      <li
                        v-if="
                          getCustomAttributeLable(
                            productDetails.customAttribute,
                            'additional_feature_6'
                          )
                        "
                      >
                        {{
                          getCustomAttributeLable(
                            productDetails.customAttribute,
                            "additional_feature_6"
                          )
                        }}
                      </li>
                    </ul>
                  </v-window-item>

                  <v-window-item value="materials">
                    <ul class="py-3 fontsize-16 body-font ls-n008">
                      <li
                        v-if="
                          getCustomAttributeLable(
                            productDetails.customAttribute,
                            'cotton_paper'
                          )
                        "
                      >
                        {{
                          getCustomAttributeLable(
                            productDetails.customAttribute,
                            "cotton_paper"
                          )
                        }}
                      </li>
                      <li
                        v-if="
                          getCustomAttributeLable(
                            productDetails.customAttribute,
                            'paper_weight'
                          )
                        "
                      >
                        {{
                          getCustomAttributeLable(
                            productDetails.customAttribute,
                            "paper_weight"
                          )
                        }}
                      </li>
                      <li
                        v-if="
                          getCustomAttributeLable(
                            productDetails.customAttribute,
                            'made_usa'
                          )
                        "
                      >
                        {{
                          getCustomAttributeLable(
                            productDetails.customAttribute,
                            "made_usa"
                          )
                        }}
                      </li>
                    </ul>
                  </v-window-item>

                  <v-window-item value="shipping">
                    <ul class="py-3 fontsize-16 body-font ls-n008">
                      <li
                        v-if="
                          getCustomAttributeLable(
                            productDetails.customAttribute,
                            'processing_time'
                          )
                        "
                      >
                        {{
                          getCustomAttributeLable(
                            productDetails.customAttribute,
                            "processing_time"
                          )
                        }}
                      </li>
                      <li>Standard shipping available</li>
                      <li>Expedited shipping available</li>
                      <li>Ships from Cohoes, NY</li>
                    </ul>
                  </v-window-item>
                </v-window>
              </v-card-text>
            </v-col>
          </v-row>
        </div>
        <div v-else>
          <v-skeleton-loader
            v-bind="attrs"
            type="list-item-three-line"
            class="ml-0 pl-0"
          ></v-skeleton-loader
          ><br />
          <v-skeleton-loader
            v-bind="attrs"
            type="list-item-two-line"
            class="ml-0 pl-0"
          ></v-skeleton-loader
          ><br />
          <v-skeleton-loader
            v-bind="attrs"
            type="actions"
            class="ml-0 pl-0"
          ></v-skeleton-loader>
        </div>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col class="text-center">
        <v-alert type="error" text>{{ fetchErrorMessage }}</v-alert>
      </v-col>
    </v-row>
    <SnackbarComponent :snackbar="snackbar"></SnackbarComponent>
    <v-dialog v-model="signInForm" persistent flat max-width="500">
      <v-card flat>
        <v-card-title class="d-flex justify-space-between">
          <p class="h3 mb-0 whyte-regular-font">Sign In</p>
          <v-btn
            @click="closeSignInForm()"
            density="comfortable"
            variant="text"
            :ripple="false"
            icon
          >
            <v-icon :icon="mdiWindowClose" />
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-3 pb-0">
          <v-alert class="word-break" type="error" v-if="!!errorMessage">{{
            errorMessage
          }}</v-alert>
          <v-alert class="word-break" type="success" v-if="!!signInMessage">{{
            signInMessage
          }}</v-alert>
          <v-form ref="loginForm" v-model="validLogin">
            <v-col class="pt-3 pb-1">
              <v-text-field
                v-model="email"
                type="email"
                label="Email Address"
                ref="email"
                name="email"
                variant="outlined"
                density="comfortable"
                :rules="emailRules"
              ></v-text-field>
            </v-col>
            <v-col class="pt-1 pb-0">
              <v-text-field
                v-model="password"
                label="Password"
                ref="password"
                type="password"
                name="password"
                variant="outlined"
                density="comfortable"
                :rules="passwordRules"
              ></v-text-field>
            </v-col>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-row no-gutters>
            <v-col cols="12" class="px-7">
              <v-btn
                variant="flat"
                block
                color="primary"
                class="text-white"
                @click="signIn(productDetails.sku)"
              >
                <v-progress-circular
                  :size="20"
                  color="white"
                  indeterminate
                  v-if="inProgress"
                ></v-progress-circular>
                &nbsp;SIGN IN
              </v-btn>
            </v-col>
            <v-col cols="6" class="py-5 pl-7" align="left">
              <span
                class="text-decoration-none cursor-pointer text-primary"
                @click="openSignUpDialog()"
                >Sign Up</span
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="signUpForm" persistent flat max-width="500">
      <v-card flat>
        <v-card-title class="d-flex justify-space-between">
          <p class="h3 mb-0">Sign Up</p>
          <v-btn
            @click="closeSignUpForm()"
            density="comfortable"
            variant="text"
            :ripple="false"
            icon
          >
            <v-icon :icon="mdiWindowClose" />
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-3 pb-0">
          <v-alert class="word-break" type="error" v-if="!!errorMessage">{{
            errorMessage
          }}</v-alert>
          <v-alert class="word-break" type="success" v-if="!!signUpMessage">{{
            signUpMessage
          }}</v-alert>
          <v-form ref="registerForm" v-model="validRegister">
            <v-text-field
              class="pt-2"
              label="First Name"
              type="text"
              ref="firstName"
              name="firstName"
              variant="outlined"
              density="comfortable"
              v-model="signUpFirstName"
              :rules="firstNameRules"
            ></v-text-field>
            <v-text-field
              class="pt-2"
              label="Last Name"
              type="text"
              ref="lastName"
              name="lastName"
              variant="outlined"
              density="comfortable"
              v-model="signUpLastName"
              :rules="lastNameRules"
            ></v-text-field>

            <v-text-field
              class="pt-2"
              label="Email Id"
              type="email"
              ref="email"
              name="email"
              id="userEmail"
              variant="outlined"
              density="comfortable"
              v-model="signUpEmail"
              :rules="signUpEmailRules"
            ></v-text-field>

            <v-text-field
              class="pt-2 mt-0"
              type="password"
              label="Password"
              ref="password"
              name="password"
              variant="outlined"
              density="comfortable"
              v-model="signUpPassword"
              :rules="commonValidationRules.passwordRule"
            ></v-text-field>
            <v-checkbox
              v-model="signUpNewsLetter"
              label="Subscribe to our newsletter"
              hide-details="true"
              color="indigo"
            ></v-checkbox>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-row no-gutters>
            <v-col cols="12" class="px-7">
              <v-btn
                variant="flat"
                block
                color="primary"
                class="text-white"
                @click="signUp(productDetails.sku)"
                :disabled="isSignUp"
              >
                <v-progress-circular
                  :size="20"
                  color="white"
                  indeterminate
                  v-if="inProgress"
                ></v-progress-circular>
                &nbsp;SIGN UP
              </v-btn>
            </v-col>
            <v-col cols="6" class="py-5 pl-7" align="left">
              <span
                class="text-decoration-none cursor-pointer text-primary"
                @click="openSignInDialog()"
                >Sign In</span
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import {
  mdiWindowClose,
  mdiPlus,
  mdiClose,
  mdiHeart,
  mdiHeartOutline,
  mdiAccountCircleOutline,
} from "@mdi/js";

const personlizeUrl = import.meta.env.VITE_PERSONALIZE_URL;
const retailorId = import.meta.env.VITE_RETAILOR_ID;
import * as easings from "vuetify/lib/services/goto/easing-patterns";
import SnackbarComponent from "@/components/common/SnackbarComponent.vue";
const mediaImageUrl = import.meta.env.VITE_APP_MEDIA_IMAGES_API_URL;

import {
  getLable,
  customAttributeValue,
  googleAanalytics,
  setCategoryNameInLocal,
  commonValidationRules,
  colorsName,
} from "@/services/util.service";
import { createHelpers } from "vuex-map-fields";
const { mapFields: mapFieldsAuth } = createHelpers({
  getterType: "auth/getField",
  mutationType: "auth/updateField",
});
const { mapFields: registerInfo } = createHelpers({
  getterType: "registration/getField",
  mutationType: "registration/updateField",
});
import productDefaultImage from "@/assets/defaultProduct.jpg";
export default {
  name: "ConfiguredProductDetailComponent",
  props: ["stockFlag", "categoryId"],
  components: { SnackbarComponent },
  data: () => ({
    mdiWindowClose,
    mdiAccountCircleOutline,
    mdiPlus,
    mdiClose,
    mdiHeartOutline,
    mdiHeart,

    mediaImageUrl,
    variants: [1366, 768, 360],
    productDefaultImage: productDefaultImage,
    tab: null,
    updateProgress: "",

    dropDownIcon: "mdiPlus",
    iconColor: "black--text",
    isActive: true,
    isLetterActive: true,
    easings: Object.keys(easings),
    snackbar: {
      show: false,
      message: null,
      color: null,
      timeout: 0,
    },
    lazy: false,
    textRules: [
      (v) => !!v || "This field is required",
      (v) => (v && v.length >= 3) || "This field must be at least 3 characters",
    ],
    fillHeart: "mdiHeart",
    outlineHeart: "mdiHeartOutline",
    myBtnColor: null,
    isOutlineHeart: true,
    isFillHeart: false,
    valid: true,
    optionValue: "",
    selectedQty: "",
    selectedConfigValue: null,
    blue: "primary",
    checkLetter: false,
    white: "white",
    cartErrorMessage: "",
    itemNumber: "193C",
    quantity: "25",
    savFromKey: "Ub07GxNhS3yyoDfO",
    ccId: "766a5cef-6b51-4ab4-9f25-6446c4c8baeb",
    selected: "",
    imagepath: "",
    fav: true,
    menu: false,
    message: false,
    hints: true,

    priceOptions: [
      { text: "31 ($110.00)", callback: () => "31 ($110.00)" },
      { text: "32 ($110.00)", callback: () => "32 ($110.00)" },
      { text: "33 ($110.00)", callback: () => "33 ($110.00)" },
    ],

    attrs: {
      boilerplate: false,
      elevation: 0,
    },
    signInForm: false,
    passwordFields: false,
    emailRules: [
      (v) => !!v || "Email is required",
      (v) => /.+@.+\..+/.test(v) || "Email must be valid",
    ],
    passwordRules: [
      (v) => !!v || "Password is required",
      (v) => (v && v.length >= 8) || "Password must be at least 8 characters",
    ],
    validLogin: false,
    selectedColor: null,
    requiredValueCheck: false,
    validRegister: false,
    isSignUp: false,
    signUpForm: false,
    commonValidationRules: commonValidationRules,
    firstNameRules: [
      (v) => !!v || "First Name is required",
      (v) => (v && v.length >= 3) || "First Name must be at least 3 characters",
    ],
    lastNameRules: [
      (v) => !!v || "Last Name is required",
      (v) => (v && v.length >= 3) || "Last Name must be at least 3 characters",
    ],

    signUpEmailRules: [
      (v) => !!v || "Email Id is required",
      (v) => /.+@.+\..+/.test(v) || "Email Id must be valid",
    ],
  }),
  computed: {
    savedForLaterList: function () {
      return this.$store.state.meSavedForLaterList.savedForLaterList;
    },
    productDetails: function () {
      return this.$store.state.productOne.productDetails;
    },
    staticProduct: function () {
      return this.$store.state.productOne.staticDataOnProduct;
    },
    imageBasePathUrl() {
      return import.meta.env.VITE_IMAGE_PATH_URL;
    },
    categoryListIdWise: function () {
      return this.$store.state.headerCategoryList.categoryListIdWise;
    },

    sku: function () {
      return this.$route.meta.sku
        ? this.$route.meta.sku
        : this.$route.params.sku;
    },
    itemId: function () {
      this.pageScrollUp();
      return this.$route.query.itemId;
    },
    qty: function () {
      return this.$route.query.qty;
    },

    qtyPriceOptions() {
      let vm = this;
      let list = JSON.parse(
        this.getCustomAttributeLable(
          this.productDetails.customAttribute,
          "qty_price"
        )
      );
      if (this.qty) {
        vm.selectedQty = this.qty.toString();
      } else {
        vm.selectedQty = list[0].qty;
      }
      return list;
    },
    numberOptions: function () {
      let vm = this;
      if (this.productDetails.customAttribute) {
        let minSalesQty = this.getCustomAttributeLable(
          this.productDetails.customAttribute,
          "ba_min_sales_qty"
        );
        let qtyIncrements = this.getCustomAttributeLable(
          this.productDetails.customAttribute,
          "ba_qty_increments"
        );
        minSalesQty = Number(minSalesQty) ? Number(minSalesQty) : 1;
        qtyIncrements = Number(qtyIncrements) ? Number(qtyIncrements) : 1;

        let nums = [];
        nums[0] = minSalesQty;
        if (this.qty) {
          vm.selectedQty = Number(this.qty);
        } else {
          vm.selectedQty = minSalesQty;
        }
        for (let i = 1; i <= 98; i++) {
          minSalesQty = minSalesQty + qtyIncrements;
          nums[i] = minSalesQty;
        }
        if (nums.length > 0) return nums;
        else return "";
      } else {
        return "";
      }
    },
    configuredProductDetails() {
      return this.$store.state.productOne.configuredProductDetails.items;
    },
    userAuth: function () {
      return this.$store.state.auth.userAuth;
    },
    guestQuoteId: function () {
      return this.$store.state.guestInfo.guestQuoteId;
    },
    inProgress: function () {
      if (this.userAuth) {
        return this.$store.state.userCartProduct.inProgress;
      } else {
        return this.$store.state.cartProduct.inProgress;
      }
    },
    configuredProductProgress: function () {
      return this.$store.state.productOne.configuredProductProgress;
    },
    fetchInProgress: function () {
      return this.$store.state.productOne.fetchInProgress;
    },
    fetchErrorMessage: function () {
      return this.$store.state.productOne.errorMessage;
    },
    productAttributes() {
      return this.$store.state.productList.attributeList;
    },
    ...mapFieldsAuth([
      "one",
      "one.email",
      "one.password",
      "errorMessage",
      "inProgress",
      "signInMessage",
    ]),
    ...registerInfo({
      signUpOne: "one",
      signUpFirstName: "one.firstname",
      signUpLastName: "one.lastname",
      signUpNewsLetter: "one.newsletter",
      signUpEmail: "one.email",
      signUpPassword: "one.password",
      signUpMessage: "signUpMessage",
    }),
    rewardsPoints: function () {
      return this.$store.state.productOne.rewardsPoints;
    },
    showZinreloRewards: function () {
      return this.$store.state.layout.showZinreloRewards;
    },
  },
  watch: {
    productDetails() {
      this.$store.dispatch("productOne/resetReward");
      this.imagepath = this.productDetails.mediaGalleryEntries
        ? this.productDetails.mediaGalleryEntries[0].file
        : "";
      if (this.productDetails && this.productDetails.id) {
      }
    },
    configuredProductDetails() {
      if (
        this.configuredProductDetails &&
        this.configuredProductDetails[0].price
      ) {
        this.productReward(this.qty);
      }
    },

    valid() {
      if (!this.valid) {
      }
    },

    savedForLaterList() {
      this.heartButtonActive();
    },
  },
  methods: {
    productReward(qty) {
      try {
        if (qty) {
          this.$store.dispatch("productOne/fetchRewardsPoints", [
            {
              price: this.configuredProductDetails[0].price,
              product_id: this.configuredProductDetails[0].sku,
              category: "configurable",
              quantity: qty,
            },
          ]);
        } else {
          this.$store.dispatch("productOne/fetchRewardsPoints", [
            {
              price: this.configuredProductDetails[0].price,
              product_id: this.configuredProductDetails[0].sku,
              category: "configurable",
              quantity: 1,
            },
          ]);
        }
      } catch (error) {
        console.log(error);
      }
    },
    fetchProductImageName(imageFullName) {
      let fileExtention = imageFullName.split("/");
      let filename = fileExtention[fileExtention.length - 1].split(".")[0];
      return filename;
    },
    setHexaColor(colorVal) {
      let hexaColor = colorsName.find((obj) => obj.name == colorVal);
      if (hexaColor) {
        return hexaColor.colorHex;
      } else {
        return "#fffffff";
      }
    },
    setColor(color, letterArray) {
      this.selectedColor = color.letterLabel;
      this.selectedConfigValue = color.letterLabel;
      this.getImagesColor(color, letterArray);
    },
    getImagesColor(color, letterArray) {
      this.checkLetter = false;
      this.selected = 0;
      this.cartErrorMessage = "";
      let indexOption = letterArray.filter(
        (x) => x.label === color.letterLabel
      );
      this.optionValue = indexOption[0].valueIndex;
      this.$store
        .dispatch("productOne/fetchConfiguredProductDetails", color.entityId)
        .then(
          () =>
            (this.imagepath = this.configuredProductDetails[0]
              .mediaGalleryEntries
              ? this.configuredProductDetails[0].mediaGalleryEntries[0].file
              : "")
        );
      this.isLetterActive = true;
    },
    configurableProductOptionsCheck(configurableProductOptions, lable) {
      let result = configurableProductOptions.filter((element) => {
        return element.label === lable;
      });

      if (result && result.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    heartButtonActive() {
      if (
        this.savedForLaterList &&
        this.savedForLaterList.wishlistItems.length
      ) {
        for (let i = 0; i < this.savedForLaterList.wishlistItems.length; i++) {
          if (
            this.productDetails.sku ===
            this.savedForLaterList.wishlistItems[i].product.sku
          ) {
            this.isFillHeart = true;
            this.isOutlineHeart = false;
            this.myBtnColor = "red";
          }
        }
      }
    },
    async addToSaveToLater(sku) {
      try {
        if (this.userAuth) {
          await this.$store
            .dispatch("savedForLaterOne/addToSavedForLater", {
              sku: sku,
            })
            .then(() => {
              this.$store.dispatch(
                "meSavedForLaterList/fetchSavedForLaterList"
              );
              this.snackbar = {
                message: "Product added in wishlist successfully",
                color: "success",
                show: true,
                timeout: 3000,
              };
            })
            .catch((error) => {
              this.snackbar = {
                message: error.message,
                color: "error",
                show: true,
                timeout: 3000,
              };
            });
        } else {
          this.signInForm = true;
        }
      } catch (error) {
        console.log("error", error);
      }
    },
    async signInPop() {
      this.signInForm = true;
    },
    async signIn(sku) {
      const { valid } = await this.$refs.loginForm.validate();
      if (valid) {
        this.$store.dispatch("auth/changeRedirectFlag", false);
        this.$store.dispatch("auth/login").then((status) => {
          if (status) {
            this.$store.dispatch("auth/changeRedirectFlag", true);
            this.$store.dispatch("accountOne/basicInfo");
            this.$store.dispatch("userCartProduct/cartProductList");

            this.signInForm = false;
          }
        });
      }
    },
    async signUp(sku) {
      const { valid } = await this.$refs.registerForm.validate();
      if (valid) {
        try {
          this.isSignUp = true;
          this.$store.dispatch("auth/changeRedirectFlag", false);
          let resp = await this.$store.dispatch("registration/signUp");

          if (resp.registration) {
            this.$store.dispatch("auth/changeRedirectFlag", false);
            this.$store.dispatch("accountOne/basicInfo");
            this.$store.dispatch("userCartProduct/cartProductList");

            this.signUpForm = false;
            this.signInForm = false;
          } else {
            this.snackbar = {
              message: resp.errorMessage,
              color: "error",
              show: true,
              timeout: 3000,
            };
          }
          this.isSignUp = false;
        } catch (error) {
          this.isSignUp = false;
          console.log(error);
          this.snackbar = {
            message: error.message,
            color: "error",
            show: true,
            timeout: 3000,
          };
        }
      }
    },
    closeSignInForm() {
      this.$refs.loginForm.reset();
      this.signInForm = false;
      this.$store.dispatch("auth/changeRedirectFlag", true);
    },
    closeSignUpForm() {
      this.$refs.registerForm.reset();
      this.signUpForm = false;
      this.$store.dispatch("auth/changeRedirectFlag", true);
    },
    openSignInDialog() {
      this.signInForm = true;
      this.signUpForm = false;

      this.$refs.registerForm.reset();
    },
    openSignUpDialog() {
      this.signInForm = false;
      this.signUpForm = true;
    },
    goToForgetPassword() {
      window.open(`/gate/forgot-password`, "_self");
    },
    setAltImg() {
      event.target.src = this.productDefaultImage;
    },
    resetValidation() {
      this.isLetterActive = true;
      this.checkLetter = false;
      if (!this.valid && this.$refs.configuredLetter) {
      }
    },
    getImages(letterArray, letter, configuredlink) {
      this.checkLetter = false;
      this.selected = 0;
      this.cartErrorMessage = "";
      let index = letterArray.findIndex((x) => x.label === letter);
      let code = configuredlink[index];

      let indexOption = letterArray.filter((x) => x.label === letter);
      if (indexOption && indexOption.length) {
        this.optionValue = indexOption[0].valueIndex;

        this.$store
          .dispatch("productOne/fetchConfiguredProductDetails", code)
          .then(
            () =>
              (this.imagepath = this.configuredProductDetails[0]
                .mediaGalleryEntries
                ? this.configuredProductDetails[0].mediaGalleryEntries[0].file
                : "")
          );
        this.isLetterActive = true;
      }
    },
    getCustomAttributeValue(customAttributes, attributeCode) {
      return customAttributeValue(customAttributes, attributeCode);
    },
    addToConfiguredCart(sku, typeId, optionId, productDetails) {
      try {
        this.checkLetter = true;
        let vm = this;
        if (this.selectedConfigValue || this.selectedColor) {
          this.checkLetter = false;
          if (this.selectedQty) {
            let qtyNew = this.selectedQty;
            this.cartErrorMessage = "";
            if (this.userAuth) {
              this.$store
                .dispatch("userCartProduct/addToConfiguredCart", {
                  sku: sku,
                  qty: this.selectedQty,
                  typeId: typeId,
                  optionId: optionId,
                  optionValue: this.optionValue,
                })
                .then((res) => {
                  googleAanalytics(
                    vm,
                    res,
                    productDetails,
                    qtyNew,
                    "add_to_cart",
                    "Ecommerce",
                    vm.categoryListIdWise
                  );
                  if (this.categoryId) {
                    setCategoryNameInLocal(this.categoryId, res);
                  }
                  this.$store.dispatch("userCartProduct/cartProductList");

                  this.$store.dispatch("userCartProduct/cartDrawer", true);
                  this.snackbar = {
                    message: "Product Added Successfully",
                    color: "success",
                    show: true,
                    timeout: 2000,
                  };
                })
                .catch((error) => {
                  this.snackbar = {
                    message: error.message,
                    color: "error",
                    show: true,
                    timeout: 2000,
                  };
                });
            } else {
              this.$store
                .dispatch("cartProduct/addToConfiguredCart", {
                  sku: sku,
                  qty: this.selectedQty,
                  typeId: typeId,
                  optionId: optionId,
                  optionValue: this.optionValue,
                })
                .then((res) => {
                  googleAanalytics(
                    vm,
                    res,
                    productDetails,
                    qtyNew,
                    "add_to_cart",
                    "Ecommerce",
                    vm.categoryListIdWise
                  );
                  if (this.categoryId) {
                    setCategoryNameInLocal(this.categoryId, res);
                  }
                  this.snackbar = {
                    message: "Product Added Successfully",
                    color: "success",
                    show: true,
                    timeout: 2000,
                  };
                })
                .catch((error) => {
                  this.snackbar = {
                    message: error.message,
                    color: "error",
                    show: true,
                    timeout: 2000,
                  };
                });
            }
          } else {
            this.cartErrorMessage = "This is a required field.";
          }
        } else {
          this.requiredValueCheck = true;
        }
      } catch (error) {
        console.log("error", error);
      }
    },
    updateToConfiguredCart(productName, sku, typeId, optionId) {
      try {
        if (this.selectedConfigValue || this.selectedColor) {
          if (this.selectedQty) {
            this.cartErrorMessage = "";
            if (this.userAuth) {
              this.$store
                .dispatch("userCartProduct/updateToConfiguredCart", {
                  sku: sku,
                  qty: this.selectedQty,
                  itemId: this.itemId,
                  typeId: typeId,
                  optionId: optionId,
                  optionValue: this.optionValue,
                })
                .then((res) => {
                  this.snackbar = {
                    message: "Product Updated Successfully",
                    color: "success",
                    show: true,
                    timeout: 3000,
                  };
                  this.$router.replace({
                    query: { qty: this.selectedQty, itemId: res.item_id },
                  });
                })
                .catch((error) => {
                  this.snackbar = {
                    message: error.message,
                    color: "error",
                    show: true,
                    timeout: 3000,
                  };
                });
            } else {
              this.$store
                .dispatch("cartProduct/updateToConfiguredCart", {
                  sku: sku,
                  qty: this.selectedQty,

                  itemId: this.itemId,
                  typeId: typeId,
                  optionId: optionId,
                  optionValue: this.optionValue,
                })
                .then((res) => {
                  this.snackbar = {
                    message: "Product Updated Successfully",
                    color: "success",
                    show: true,
                    timeout: 3000,
                  };
                  this.$router.replace({
                    query: { qty: this.selectedQty, itemId: res.item_id },
                  });
                })
                .catch((error) => {
                  this.snackbar = {
                    message: error.message,
                    color: "error",
                    show: true,
                    timeout: 3000,
                  };
                });
            }
          } else {
            this.cartErrorMessage = "Please Select Quantity";
          }
        } else {
          this.requiredValueCheck = true;
        }
      } catch (error) {
        console.log("error", error);
      }
    },

    getCustomAttributeLable(customAttributes, attributeCode) {
      return getLable(customAttributes, attributeCode);
    },

    setValue(color) {
      return (this.selected = color) && (this.cartErrorMessage = "");
    },
    submit() {
      if (this.selected !== "") {
        return (this.selected = "") && (this.cartErrorMessage = "");
      } else {
        this.cartErrorMessage = "Please Select Color";
      }
    },
    gotoPersonalize() {
      if (
        this.getCustomAttributeLable(
          this.productDetails.customAttribute,
          "builder_version"
        ) === "1.0" ||
        this.getCustomAttributeLable(
          this.productDetails.customAttribute,
          "builder_version"
        ) === ""
      ) {
        this.$router.push({
          name: "CraneConnection",
          params: {
            retailorId: retailorId,
            quantity: this.selectedQty,
            itemNumber: this.sku,
          },
        });
      } else {
        let itemCode = this.itemId ? this.itemId : "new";
        window.open(
          `${personlizeUrl}/builder/${this.sku}/select-part/${itemCode}/${this.selectedQty}`,
          "_blank"
        );
      }
    },
    setSelected(part, index) {
      this.imagepath = part;
      this.selected = index;
    },
    pageScrollUp() {
      const data = this.$refs.divOne;
      if (data) {
        this.$smoothScroll({
          scrollTo: data.$el.scrollTop,
          duration: 300,
          easingFunction: "easeInOutCubic",
          updateHistory: false,
        });
      }
    },
  },
  mounted() {
    this.pageScrollUp();
  },
  created() {
    if (this.itemId) {
      this.updateProgress = true;
      setTimeout(() => {
        this.updateProgress = false;
      }, 3000);
    }
  },
};
</script>
<style lang="scss">
.overflow-button .v-select__slot {
  border-left: 1px solid #dcdddd !important;
  border-right: 1px solid #dcdddd !important;
}
.product-overview-tabs .v-tabs-slider-wrapper {
  color: #2850da;
  height: 4px !important;
}
.overflow-button.v-overflow-btn.v-input--is-focused .v-input__slot {
  box-shadow: none !important;
  border-top: 1px solid #dcdddd !important;
  border-bottom: 1px solid #dcdddd !important;
  border-radius: 0px !important;
}

.product-overview-tabs {
  color: rgba(77, 70, 70, 0.54);
}
.product-overview-tabs .v-tabs-items {
  background-color: transparent !important;
}
.product-overview-tabs .v-tabs-bar {
  background-color: transparent !important;
  height: 35px !important;
}
.product-overview-tabs .v-slide-group__prev {
  display: none !important;
}
.v-text-field .v-input__control {
  border-radius: unset !important;
}
.productpage-select-button .v-input__control .v-input__slot {
  border: thin solid #8b8b8b !important;
}
.productpage-select-button:hover .v-input__control .v-input__slot {
  border: thin solid #2850da !important;
}
.productpage-select-button.v-input--is-label-active.v-input--is-focused
  .v-select__selection.v-select__selection--comma {
  color: #2850da !important;
}
.productpage-select-button:hover
  .v-select__selection.v-select__selection--comma {
  color: #2850da !important;
}
.productpage-select-button:hover .v-input__append-inner .v-input__icon .v-icon {
  color: #2850da !important;
}
.productpage-select-button.v-input--is-label-active.v-input--is-focused
  .v-input__control
  .v-input__slot {
  border: thin solid #2850da !important;
}

.product-circle-border,
.color-circle-border {
  border: 2px solid #2850da !important;
}

.product-color-circle {
  height: 34px;
  width: 34px;
  border-radius: 50%;
  border: 1px solid #777;
}
.color-circle {
  height: 42px;
  width: 42px;
  border-radius: 50%;
  border: 1px solid #fff;
}
</style>
<style src="./ProductDetailComponent.scss" lang="scss" scoped/>