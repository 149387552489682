import { createStore } from "vuex";
 

import { layout } from "./crane/layout";
import { registration } from "./gate/registration.module";
import { meProductList } from "./me/me_product.list";
import { accountOne } from "./me/account.one";
import { storeCredit } from "./me/me_store_credit";
import { productList } from "./crane/product.list";
import { productOne } from "./crane/product.one";
import { storeLocatorList } from "./crane/storelocator.list";
import { cartProduct } from "./crane/guest_cart.one";
import { userCartProduct } from "./crane/user_cart.one";
import { auth } from "./gate/auth.module";
import { headerCategoryList } from "./crane/header";
import { guestInfo } from "./me/guestInfo.one";
import { giftCartProducts } from "./crane/gift_cart.one";
import { block } from "./crane/block.js";
import { emailSubscriber } from "./crane/email_subscriber.js";
import { meProductOne } from "./me/me_product.one";
import { pdfDocOne } from "./crane/pdfdoc.one";
import { catalogBrowser } from "./crane/catalog_browser";
import { meSavedForLaterList } from "./me/me_saved_for_later.list";
import { savedForLaterOne } from "./crane/saved_for_later.one";
import { sessionItemOne } from "./crane/session_item.one";
import { sessionItemList } from "./crane/session_item.list";
import { sessionOne } from "./crane/session.one";
import { sessionList } from "./crane/session.list";
import { pricerBundleOne } from "./crane/pricer_bundle.one";

import { incidentOne } from "./crane/incident.one.js";
 
const store = createStore({
  modules: {
    auth,
    registration,
    headerCategoryList,
    layout,
    meProductList,
    accountOne,
    productList,
    productOne,
    storeLocatorList,
    cartProduct,
    userCartProduct,
    guestInfo,
    giftCartProducts,
    block,
    emailSubscriber,
    meProductOne,
    storeCredit,
    pdfDocOne,
    catalogBrowser,
    meSavedForLaterList,
    savedForLaterOne,
    sessionItemOne,
    sessionItemList,
    sessionOne,
    sessionList,
    incidentOne,
    pricerBundleOne,
  },
});

export default store;
