import { doFetchPriceBundle } from "@/services/pricerBundle.service";
import { getField, updateField } from "vuex-map-fields";

export const pricerBundleOne = {
  namespaced: true,
  state: {
    inProgress: false,
    price: {},
  },
  getters: {
    getField,
  },
  mutations: {
    updateField,

    inProgress(state, yesOrNo) {
      state.inProgress = yesOrNo;
    },
    setPrice(state, price) {
      state.price = price;
    },
  },
  actions: {
    fetchPriceBundle: async ({ commit }, { skuList, availableAt }) => {
      try {
        commit("inProgress", true);
        let bundle = await doFetchPriceBundle({ skuList, availableAt });
        commit("inProgress", false);
        return bundle;
      } catch (err) {
        commit("inProgress", false);
        throw err;
      }
    },
  },
};
