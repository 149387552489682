import { doFetchProductInvoice,doFetchOrderProduct } from '@/services/product.service';
import { getField, updateField } from 'vuex-map-fields';
import { snakeToCamel } from '@/services/util.service';

export const meProductOne = {
    namespaced: true,
    state: {
        fetchInProgress: true,
        productInvoice: {},
        orderProduct:{}
    },
    getters: {
        getField
    },
    mutations: {
        updateField,

        fetchInProgress(state, yesOrNo) {
            state.fetchInProgress = yesOrNo
        },
        setProductInvoice(state, list) {
            state.productInvoice = list
        },
        setOrderProduct(state, data) {
            state.orderProduct = data
        },
    },
    actions: {
        fetchProductInvoice: async ({ commit }, itemId) => {
            let productInvoice = await doFetchProductInvoice(itemId);
            if (productInvoice) {
                commit('setProductInvoice', snakeToCamel(productInvoice));
            }
        },
        fetchOrderProduct: async ({ commit },orderId) => {
            try{
                commit('fetchInProgress', true);    
            let orderProduct = await doFetchOrderProduct(orderId);
            if (orderProduct) {
                commit('fetchInProgress', false);    
                commit('setOrderProduct', snakeToCamel(orderProduct));
                return snakeToCamel(orderProduct);
            }
        }
        catch(error)
        {
            commit('fetchInProgress', false);    
            throw error
        }
        },
    },

}
