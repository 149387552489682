import {
    doPostCartItem,
    doFetchQuote,
    doFetchCartProducts,
    doFetchTotalAmt,
    doDeleteCartProduct,
    doPutCartItem,
    doFetchCoupon,
    doApplyDiscountCode,
    doRemoveDiscountCode,
   
    doPostGiftCartItem,
    doPutGiftCartItem,
    doPostConfiguredCartItem,
    doPutConfiguredCartItem,
    doFetchGiftMessage,
    doPutCConectionCartItem
} from '@/services/user_cart.service';
import {
    doFetchList
} from '@/services/builder_session_item.service';
import { getCookieByName, removeCookieByName } from '@/services/auth';
import { getField, updateField } from 'vuex-map-fields';
import { getAuthUser } from '@/services/auth';
import { criteoViewBasket, listrakCartList, listrakCartListClear, snakeToCamel } from "@/services/util.service";
 
const crGuestQuoteId = import.meta.env.VITE_GUEST_QUOTE_ID;

export const userCartProduct = {
    namespaced: true,
    state: {
        cartDrawer: false,
        inProgress: false,
        fetchInProgress: false,
        cartProduct: [],
        cartProductList: [],
        productTotalAmt: [],
        productMedia: {},
        discountCodeList: [],
        errorMessage: null,
        cartProductsProgress: false,
        cartTotalAmtProgress: false,
        cartProductDetails: [],
        giftCartDetails: {
             
            "recipientName": "",
            "recipientEmail": "",
            "senderMessage": "",
            
            "giftCardAmt": ""
        },
        autoLogoutFlag: false,

    },
    getters: {
        getField
    },
    mutations: {
        updateField,

        inProgress(state, yesOrNo) {
            state.inProgress = yesOrNo
        },
        cartProductsProgress(state, yesOrNo) {
            state.cartProductsProgress = yesOrNo
        },
        cartTotalAmtProgress(state, yesOrNo) {
            state.cartTotalAmtProgress = yesOrNo
        },
        setCartDrawer(state, yesOrNo) {
            state.cartDrawer = yesOrNo
        },
        fetchInProgress(state, yesOrNo) {
            state.fetchInProgress = yesOrNo
        },
        setCartProduct(state, product) {
            state.cartProduct = product
        },
        setCartProductList(state, list) {
            state.cartProductList = list
          
        },
        setCriteoViewBasket(state) {
            criteoViewBasket(state.cartProductList);
        },
        setListrakCartList(state) {
            listrakCartList(state.cartProductList);
        },
        setListrakCartListClear() {
            listrakCartListClear()
        },
        setCartProductDetails(state, details) {
            state.cartProductDetails = details
        },
        setProductAmt(state, amt) {
            state.productTotalAmt = amt;
        },
        setCouponCodeList(state, code) {
             state.discountCodeList=(code);
        },
        removeCouponCodeList(state, index) {
            state.discountCodeList.splice(index, 1);
       },
        setErrorMessage(state, err) {
            state.errorMessage = err
        },
        setProductsMedia(state, media) {
            state.productMedia = media
        },
        setAutoLogoutFlag(state, flag) {
            state.autoLogoutFlag = flag
        },
        setGiftMessageData(state, giftMessage) {
            if (giftMessage[0].productOption) {
                state.giftCartDetails.recipientName = giftMessage[0].productOption.infoBuyRequest.giftcardRecipientName,
                    state.giftCartDetails.recipientEmail = giftMessage[0].productOption.infoBuyRequest.giftcardRecipientEmail,
                    state.giftCartDetails.senderMessage = giftMessage[0].productOption.infoBuyRequest.giftcardMessage,
                    state.giftCartDetails.giftCardAmt = (giftMessage[0].productOption.infoBuyRequest.giftcardAmount)
            }
        },
    },
    actions: {
        addToCart: async ({ commit, state }, data) => {
            try {
                let result;
                commit('inProgress', true);
                if (!state.cartProductDetails.length > 0) {
                    const userQuote = await doFetchQuote();
                    localStorage.setItem("cr_userQuoteId", userQuote); //no use

                    result = await doPostCartItem(data.sku, data.qty, data.ccid, data.price, data.personalizeDetails, data.builderId ? data.builderId : null, userQuote, data.optionId, data.optionValue);
                    if (data.categoryLinkLocal) {
                        let quoteItemId = localStorage.getItem("quote_item_id" + data.categoryLinkLocal);
                        localStorage.setItem('quote_item_id' + result.item_id, quoteItemId);

                    }
                } else {

                    result = await doPostCartItem(data.sku, data.qty, data.ccid, data.price, data.personalizeDetails, data.builderId ? data.builderId : null, state.cartProductDetails[0].quoteId, data.optionId, data.optionValue);

                    if (data.categoryLinkLocal) {
                        let quoteItemId = localStorage.getItem("quote_item_id" + data.categoryLinkLocal);
                        localStorage.setItem('quote_item_id' + result.item_id, quoteItemId);

                    }
                }
                commit('inProgress', false);
                return result;
            } catch (err) {
                commit('inProgress', false);
                throw err;
            }
        },
        addToConfiguredCart: async ({ commit, state }, data) => {
            try {
                commit('inProgress', true);
                let result;
                if (!state.cartProductDetails.length > 0) {
                    const userQuote = await doFetchQuote();
                    localStorage.setItem("cr_userQuoteId", userQuote); //no use
                    result = await doPostConfiguredCartItem(data.sku, data.qty, data.typeId, data.optionId, data.optionValue, userQuote);
                    if (data.categoryLinkLocal) {
                        let quoteItemId = localStorage.getItem("quote_item_id" + data.categoryLinkLocal);
                        localStorage.setItem('quote_item_id' + result.item_id, quoteItemId);

                    }
                } else {
                    result = await doPostConfiguredCartItem(data.sku, data.qty, data.typeId, data.optionId, data.optionValue, state.cartProductDetails[0].quoteId);
                    if (data.categoryLinkLocal) {
                        let quoteItemId = localStorage.getItem("quote_item_id" + data.categoryLinkLocal);
                        localStorage.setItem('quote_item_id' + result.item_id, quoteItemId);

                    }
                }
                commit('inProgress', false);
                commit('setCartDrawer', true);
                return result;
            } catch (err) {
                commit('inProgress', false);
                throw err;
            }
        },
        addToGiftCart: async ({ commit, state }, data) => {
            try {
                let result;
                commit('inProgress', true);
                if (!state.cartProductDetails.length > 0) {
                    const userQuote = await doFetchQuote();
                    localStorage.setItem("cr_userQuoteId", userQuote); //no use
                    if (data.giftCartDetails) {
                        result = await doPostGiftCartItem(data.sku, data.qty, userQuote, data.senderName, data.senderEmail, data.giftCartDetails);
                    } else {
                        result = await doPostGiftCartItem(data.sku, data.qty, userQuote, data.senderName, data.senderEmail, state.giftCartDetails);
                    }
                    if (data.categoryLinkLocal) {
                        let quoteItemId = localStorage.getItem("quote_item_id" + data.categoryLinkLocal);
                        localStorage.setItem('quote_item_id' + result.item_id, quoteItemId);

                    }
                } else {
                    if (data.giftCartDetails) {
                        result = await doPostGiftCartItem(data.sku, data.qty, state.cartProductDetails[0].quoteId, data.senderName, data.senderEmail, data.giftCartDetails);
                    } else {
                        result = await doPostGiftCartItem(data.sku, data.qty, state.cartProductDetails[0].quoteId, data.senderName, data.senderEmail, state.giftCartDetails);
                    }
                    if (data.categoryLinkLocal) {
                        let quoteItemId = localStorage.getItem("quote_item_id" + data.categoryLinkLocal);
                        localStorage.setItem('quote_item_id' + result.item_id, quoteItemId);

                    }
                }
                commit('inProgress', false);
                commit('setCartDrawer', true);
                return result;
            } catch (err) {
                commit('inProgress', false);
                throw err;
            }
        },
        productTotalAmt: async ({ commit }) => {
            commit('cartTotalAmtProgress', true);
            doFetchTotalAmt().then((productTotalAmt) => {
                commit('cartTotalAmtProgress', false)
                commit('setProductAmt', snakeToCamel(productTotalAmt))
            })
                .catch(() => commit('cartTotalAmtProgress', false))
        },
        cartProductList: async ({ commit, rootState, dispatch }, builderSessionId) => {
            commit('setCartProductList', []);
            commit('cartProductsProgress', true);
            try {
                const quoteId = await doFetchQuote();
                let guestQuoteId = await getCookieByName(crGuestQuoteId);
                if (guestQuoteId) {
                    await dispatch('cartProduct/cartProductList', guestQuoteId, { root: true })
                    let guestCartProduct = rootState.cartProduct.cartProductList;
                    if (guestCartProduct.length > 0) {
                        for (let i = 0; i < guestCartProduct.length; i++) {
                            if (guestCartProduct[i].productType == 'configurable') {
                                await dispatch("addToConfiguredCart", {
                                    sku: guestCartProduct[i].parentSku,
                                    qty: guestCartProduct[i].qty,
                                    typeId: guestCartProduct[i].productType,
                                    optionId: guestCartProduct[i].productOption.attributesInfo[0].optionId,
                                    optionValue: guestCartProduct[i].productOption.attributesInfo[0].optionValue,
                                    categoryLinkLocal: guestCartProduct[i].itemId

                                })
                            } else if (guestCartProduct[i].productType == 'giftcard') {
                                await dispatch("addToGiftCart", {
                                    sku: guestCartProduct[i].sku,
                                    qty: guestCartProduct[i].qty,
                                    senderName: guestCartProduct[i].productOption.infoBuyRequest.giftcardSenderName,
                                    senderEmail: guestCartProduct[i].productOption.infoBuyRequest.giftcardSenderEmail,
                                    categoryLinkLocal: guestCartProduct[i].itemId,
                                    giftCartDetails: {
                                        recipientName: guestCartProduct[i].productOption.infoBuyRequest.giftcardRecipientName,
                                        recipientEmail: guestCartProduct[i].productOption.infoBuyRequest.giftcardRecipientEmail,
                                        senderMessage: guestCartProduct[i].productOption.infoBuyRequest.giftcardMessage,
                                        giftCardAmt: guestCartProduct[i].productOption.infoBuyRequest.giftcardAmount

                                    }
                                })
                            } else {
                                let params = { optionId: null, optionValue: null }
                                let newOptions = guestCartProduct[i].options && guestCartProduct[i].options.options && guestCartProduct[i].options.options.length ? guestCartProduct[i].options.options : null
                                let builderSessionObj = null
                                if (newOptions) {

                                    builderSessionObj = newOptions.find((item) => item.label == 'BuilderSessionId')
                                    if (builderSessionObj) {
                                        params.optionId = builderSessionObj.optionId
                                        params.optionValue = builderSessionObj.optionValue
                                    }

                                }
                                console.log('builderSessionObj', builderSessionObj);

                                if (!(builderSessionObj != null && builderSessionId == builderSessionObj.optionValue)) {
                                    let output = await dispatch("addToCart", {
                                        sku: guestCartProduct[i].sku,
                                        qty: guestCartProduct[i].qty,
                                        ccid: guestCartProduct[i].ccid ? guestCartProduct[i].ccid : null,
                                        price: builderSessionObj ? guestCartProduct[i].rowTotal : null,
                                        personalizeDetails: guestCartProduct[i].personalizeDetails ? guestCartProduct[i].personalizeDetails : null,
                                        builderId: guestCartProduct[i].builderId ? guestCartProduct[i].builderId : null,
                                        categoryLinkLocal: guestCartProduct[i].itemId,
                                        ...params
                                    })

                                    if (output && rootState.accountOne.one && rootState.accountOne.one.id && params && params.optionValue) {

                                        await dispatch("sessionOne/updateOne", {
                                            sessionId: params.optionValue,
                                            property: {
                                                quoteData: output,
                                                userData: {
                                                    'customer_id': rootState.accountOne.one.id,
                                                    'customer_name': rootState.accountOne.one.firstname
                                                }
                                            }
                                        }, { root: true });
                                    }
                                }

                            }
                        
                        }
                    }

                    removeCookieByName(crGuestQuoteId)
                    const cartProductList = await doFetchCartProducts(quoteId);

                    commit('setCartProductDetails', snakeToCamel(cartProductList))
                    commit('setCartProductList', snakeToCamel(cartProductList))
                    commit('cartProductsProgress', false);
                    dispatch('productTotalAmt');
                    return snakeToCamel(cartProductList)

                } else {
                    let cartProductList = await doFetchCartProducts(quoteId);
 
                    if (cartProductList.length > 0) {
                        for (let i = 0; i < cartProductList.length; i++) {
                            if (cartProductList[i] && cartProductList[i].options && cartProductList[i].options.options) {
                                let optionsList = cartProductList[i].options.options;
                                let builderSessionOne = null
                                builderSessionOne = optionsList.find(
                                    (x) => x.label == "BuilderSessionId"
                                );

                                if (builderSessionOne) {
                                    let sessionId = builderSessionOne.option_value;
                                    if (sessionId) {
                                        const sessionData = await doFetchList({ sessionId });
                                        if (sessionData && sessionData.length > 0) {
                                            let thumbnailUrl = sessionData.map(a => a.thumbnail_url);
                                            cartProductList[i]['thumbnail_url'] = thumbnailUrl;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    commit('setCartProductDetails', snakeToCamel(cartProductList))
                    commit('setCartProductList', snakeToCamel(cartProductList))
                    commit('cartProductsProgress', false);
                    dispatch('productTotalAmt');
                    return snakeToCamel(cartProductList)
                }
                return true
            }
            catch (err) {
                commit('cartProductsProgress', false);
                throw err;
            }
        },
        removeCartProduct: async ({ commit, dispatch }, { productId }) => {
            try {
                commit('cartProductsProgress', true);
                let trueOrFalse = await doDeleteCartProduct(productId);
                if (trueOrFalse) {
                    commit('cartProductsProgress', false);
                    commit('cartTotalAmtProgress', false);
                    commit('setCartDrawer', false);
                    await dispatch('cartProductList');
                    dispatch('setListrakCartListClear')

                }
            } catch (err) {
                commit('cartProductsProgress', false);
                throw err;
            }
        },
        updateToCart: async ({ commit, dispatch, state }, data) => {
            try {
                let userQuote = state.cartProductDetails[0].quoteId;
                commit('cartProductsProgress', true);
                const cartProduct = await doPutCartItem(data.sku, data.qty, userQuote, data.itemId, data.ccid, data.price, data.personalizeDetails);
                if (cartProduct) {
                    commit('cartProductsProgress', false);
                    if (data.routeName != 'CartProducts') {
                        commit('setCartDrawer', true);
                    }
                    commit('setCartProduct', (cartProduct));
                    await dispatch('cartProductList');
                    return (cartProduct);
                }
            } catch (err) {
                await dispatch('cartProductList');
                commit('cartProductsProgress', false);
                throw err;
            }

        },
        updateToCConectionCart: async ({ commit }, data) => {
            try {
                commit('cartProductsProgress', true);
                const response = await doPutCConectionCartItem(data.sku, data.qty, data.ccid);
                commit('cartProductsProgress', false);
                return (response);

            } catch (err) {
                commit('cartProductsProgress', false);
                throw err;
            }
        },
        updateToConfiguredCart: async ({ commit, dispatch, state }, data) => {
            try {
                commit('cartProductsProgress', true);
                let userQuote = state.cartProductDetails[0].quoteId;
                const cartProduct = await doPutConfiguredCartItem(data.sku, data.qty, userQuote, data.itemId, data.optionId, data.optionValue);
                if (cartProduct) {
                    commit('cartProductsProgress', false);
                    if (data.routeName != 'CartProducts') {
                        commit('setCartDrawer', true);
                    }
                    commit('setCartProduct', (cartProduct));
                    dispatch('cartProductList');
                    return cartProduct
                }
            } catch (err) {
                await dispatch('cartProductList');
                commit('cartProductsProgress', false);
                throw err;
            }

        },
        updateToGiftCart: async ({ commit, dispatch, state }, data) => {
            try {
                commit('inProgress', true);
                data.userQuote = state.cartProductDetails[0].quoteId;
                const cartProduct = await doPutGiftCartItem(data, state.giftCartDetails);
                if (cartProduct) {
                    commit('inProgress', false);
                    if (data.routeName != 'CartProducts') {
                        commit('setCartDrawer', true);
                    }
                    commit('setCartProduct', (cartProduct));
                    dispatch('cartProductList');
                    return cartProduct
                }
            } catch (err) {
                await dispatch('cartProductList');
                commit('inProgress', false);
                throw err;
            }

        },
        cartDrawer: async ({ commit }, flag) => {
            commit('setCartDrawer', flag)
        },
        fetchCartCouponList: async ({ commit }) => {
            commit('fetchInProgress', true);
            try {
                let coupon = await doFetchCoupon();
                if (coupon && coupon.length) {
                    commit('fetchInProgress', false);
                  commit('setCouponCodeList', coupon);
                }
                else{
                    commit('fetchInProgress', false);
                    commit('setCouponCodeList', []);
                }
            } catch (err) {
                commit('fetchInProgress', false);
                throw err;
            }
        },
        applyDiscountCode: async ({ commit,dispatch }, code) => {
          
           commit('fetchInProgress', true);
            try {
                let coupon = await doApplyDiscountCode(code);
                commit('fetchInProgress', false);
                if (coupon) {
                    dispatch('fetchCartCouponList');
                }
            } catch (err) {
                commit('fetchInProgress', false);
                throw err;
            } 
        },
       
        removeDiscountCode: async ({ commit, dispatch }, code) => {
            commit('fetchInProgress', true);
            try {
                let coupon = await doRemoveDiscountCode(code);
                if (coupon) {
                    commit('fetchInProgress', false);
                    dispatch('fetchCartCouponList');

                }
            } catch (err) {
                commit('fetchInProgress', false);
                throw err;
            }
        },
        fetchGiftMessage: async ({ commit, state }, data) => {
            try {
                commit('fetchInProgress', true);
                let userQuote = state.cartProductDetails[0].quoteId;
                const giftMessageData = await doFetchGiftMessage(userQuote, data.itemId);
                if (giftMessageData) {
                    commit('fetchInProgress', false);
                    commit('setGiftMessageData', snakeToCamel(giftMessageData));
                }
            } catch (err) {
                commit('fetchInProgress', false);
                throw err;
            }
        },
       
        checkUserToken: ({ commit, dispatch }, obj) => {
            let timeInterval = null;
            timeInterval = setInterval(() => {
                if (!getAuthUser()) {
                    console.log('setInterval', timeInterval, obj);
                    commit('setCartProductList', []);
                    commit('setProductAmt', {});
                    commit('setAutoLogoutFlag', true);
                    dispatch('auth/logout', {}, { root: true });
                    clearInterval(timeInterval);
                }
            }, 3000);
        },
        setCriteoViewBasket: async ({ commit, state }) => {
            if (state.cartProductList.length > 0) {
                commit('setCriteoViewBasket');
            }
        },
        setListrakCartList: async ({ commit, state }) => {
            if (state.cartProductList.length > 0) {
                commit('setListrakCartList');
            }
        },
        setListrakCartListClear: async ({ commit, state }) => {
            if (state.cartProductList.length <= 0) {
                commit('setListrakCartListClear');
            }
        },
    }
}