 import { authHeader } from './auth';
 import { wrapRequest } from './nav';

 const vueAppMagenotApiUrl = import.meta.env.VITE_MAGENTO_API_URL;

 export const doFetchOne = wrapRequest((blockId) => {
     const requestOptions = {
         method: 'GET',
         headers: {
             'Content-type': 'application/json',
             ...authHeader()
         },
     };
     return fetch(`${vueAppMagenotApiUrl}/rest/all/V1/crcmsBlock/${blockId}`, requestOptions)
 });

 export const doFetchList = wrapRequest(() => {
     const requestOptions = {
         method: 'GET',
         headers: {
             'Content-type': 'application/json',
             'Authorization': 'Bearer ' + 'q6u1q4thvtw03ajuo5v6q3cb8vlq623v'
         },
     };
     return fetch(`${vueAppMagenotApiUrl}/rest/all/V1/cmsBlock/search?searchCriteria[filter_groups][0][filters][0][field]=id&searchCriteria[filter_groups][0][filters][0][value]=10&searchCriteria[filter_groups][0][filters][0][condition_type]=eq`, requestOptions)

     
 });
 export const doFetchPageContent = wrapRequest((pageUrlKey) => {
     const requestOptions = {
         method: 'GET',
         headers: {
             'Content-type': 'application/json',
             ...authHeader()
         },
     };
     return fetch(`${vueAppMagenotApiUrl}/rest/V1/crcmsPage/search?searchCriteria[filter_groups][0][filters][0][field]=identifier&searchCriteria[filter_groups][0][filters][0][value]=${pageUrlKey}&searchCriteria[filter_groups][0][filters][0][condition_type]=eq`, requestOptions)
 });