import { doFetchCountries, doFetchStates, doFetchBasicInfo, doFetchChangePassword, doValidateAddress, doValidateAddressCheckout, doUpdateUserAddress, doUpdateSubscription, doChangeBasicInfo, doFetchEstimatedShippingMethods, doShippingInformation, doProductsOrder, doChangePassword, doProductsOrderOnCC, doFetchProductPdfs, doZinreloToken ,dosetFingerprintWithUserId} from '@/services/user.service';
import { doPlaceCraneOrder } from '@/services/product.service';
import { invalidPoBoxAddress, snakeToCamel } from '@/services/util.service';
import { doFetchQuote } from '@/services/user_cart.service';
import { getField, updateField } from 'vuex-map-fields';
import { criteoUpdateUserEmail, listrakEmailCapture } from "@/services/util.service";
const partnerId = import.meta.env.VITE_ZINRELO_PARTNER_ID;

const addresses = {
    "regionId": "",
    "region": [],
    "countryId": "",
    "postcode": "",
    "city": "",
    "telephone": "",
    "firstname": "",
    "lastname": "",
    "company": "",
    "defaultShipping": false,
    "defaultBilling": false
}
const defaultAddress = {
    "defaultShipping": 0,
    "defaultBilling": 0
}
const streetNew = {
    "streetNoOne": "",
    "streetNoTwo": "",
}
export const accountOne = {
    namespaced: true,
    state: {
        one: {},
        address: {},
        addresses: [],
        oneAddress: {},
        addressIndex: null,
        shippingAddressDetails: {},
        billingAddressDetails: {},
        shippingMethods: null,
        changedPasswordFlag: true,
        creditBalance: "$0.00",
        allCountries: [],
        countryWiseStates: [],
        newsLetterSubscription: null,
        errorMessage: null,
        estimatedShipping: [],
        shippingInformation: null,
        inProgress: false,
        paymentInProgress: false,
        userLocalAddress: "",
        userLocalAddressFlag: "",
        userSelectedAddressIndex: 0,
        userLocalBillAddress: "",
        lastAddedAddressBookIndex: -1,
        fetchInProgress: false,
        wrongPasswordMessage: "",
        shippingInformationsInProgress: false,
        addressContainPOBox: null,
        suggestedAddress: {},
        customerNumber: '',
        zinreloToken: null,
        zinreloTokenCheck: false,
    },
    getters: {
        getField,
    },
    mutations: {
        updateField,
        setAllCountries(state, allCountries) {
            state.allCountries = allCountries
        },
        setCountryWiseStates(state, countryWiseStates) {
            state.countryWiseStates = countryWiseStates
        },
        setOne(state, one) {
            criteoUpdateUserEmail(one.email)
            state.one = Object.assign({}, state.one, defaultAddress, one);
            state.one.defaultShipping = Number(state.one.defaultShipping);
            state.one.defaultBilling = Number(state.one.defaultBilling);
            let customerNumber = state.one.customAttributes.filter((customAttribute) => customAttribute.attributeCode === "customer_number")[0]
            if (customerNumber) {
                state.customerNumber = customerNumber.value
            }

        },
        setAddress(state, address) {
            if (address) {
                state.address = Object.assign({}, state.address, address, streetNew);
                state.address.streetNoOne = address.street[0];
                state.address.streetNoTwo = address.street[1];
            } else {
                state.address = Object.assign({}, addresses, streetNew);
                state.address.firstname = state.one.firstname;
                state.address.lastname = state.one.lastname;
            }
        },
        setSuggestedAddress(state, addressNew) {
            if (addressNew) {
                state.suggestedAddress = Object.assign({}, state.suggestedAddress, addressNew, streetNew);
                state.suggestedAddress.streetNoOne = addressNew.street[0];
                state.suggestedAddress.streetNoTwo = addressNew.street[1];
            } else {
                state.suggestedAddress = Object.assign({}, addresses, streetNew);
            }
        },
        setAddresses(state, { addresses, addressId }) {
            let newAddressArray = [];
            state.shippingAddressDetails = { index: null };
            state.billingAddressDetails = { index: null };
            addresses.forEach(function (address, index) {
                let addr = {};
                addr = Object.assign({}, state.address, address, streetNew);
                addr.streetNoOne = address.street[0];
                addr.streetNoTwo = address.street[1];

                if (address.defaultShipping) {
                    state.shippingAddressDetails.address = address;
                    state.shippingAddressDetails.index = index;
                }

                if (address.defaultBilling) {
                    state.billingAddressDetails.address = address;
                    state.billingAddressDetails.index = index;
                }

                if (addressId == addr.id) {
                    state.address = addr;
                    state.addressIndex = index;
                }

                newAddressArray.push(addr);
            });
            state.addresses = newAddressArray;
        },
        setLocalShippAddress(state, address) {
            state.address = Object.assign({}, address);
            state.userLocalAddress = address;
        },
        resetLocalShippAddressVar(state, val) {
            state.userLocalAddress = val;
        },
        setLocalBillAddress(state, address) {
            state.address = Object.assign({}, address);
            state.userLocalBillAddress = address;
        },
        setLocalShippAddressFlag(state, flag) {
            state.userLocalAddressFlag = flag;
        },
        setSelectedShippAddressIndex(state, index) {
            state.userSelectedAddressIndex = index;
        },
        lastAddedAddressBookIndex(state, index) {
            state.lastAddedAddressBookIndex = index;
        },
        deleteAddress(state, address) {
            const index = state.addresses.indexOf(address);
            if (index > -1) {
                state.addresses.splice(index, 1);
            }
        },
        updateAddresses(state, address) {
            if (state.addressIndex !== null) {
                state.addresses[state.addressIndex] = address;
            } else {
                state.addresses.push(address);
                state.addressIndex = state.addresses.length - 1
            }
            state.addresses = state.addresses.map((addr, index) => {
                if (state.addressIndex !== index) {
                    if (address.defaultBilling)
                        addr.defaultBilling = false;
                    if (address.defaultShipping)
                        addr.defaultShipping = false;
                }
                return addr
            });
        },
        formatAddresses(state) {
            let addrs = [];
            let streetAddress = [];
            let streetArray = [];
            state.addresses.forEach(function (address) {
                if (address.streetNoTwo == " " || address.streetNoTwo == undefined) {
                    streetAddress[0] = address.streetNoOne;
                    streetArray = streetAddress.filter(v => v != '');
                } else {
                    streetAddress[0] = address.streetNoOne
                    streetAddress[1] = address.streetNoTwo;
                    streetArray = streetAddress.filter(v => v != '');
                }
                let formatedAddress = {
                    "id": address.id,
                    "region_id": address.regionId,
                    "country_id": address.countryId,
                    "street": streetArray,
                    "postcode": address.postcode,
                    "city": address.city,
                    "telephone": address.telephone,
                    "firstname": address.firstname,
                    "lastname": address.lastname,
                    "company": address.company,
                    "default_billing": address.defaultBilling,
                    "default_shipping": address.defaultShipping,
                };
                addrs.push(formatedAddress)
            })
            state.addresses = addrs;

        },
        setChangePasswordFlag(state, changedPasswordFlag) {
            state.changedPasswordFlag = changedPasswordFlag;
        },
        setEstimatedShipping(state, shipping) {
            state.estimatedShipping = shipping;
        },
        /*successMessage(state, successMessage) {
            state.successMessage = successMessage;
        },*/
        inProgress(state, yesOrNo) {
            state.inProgress = yesOrNo
        },
        shippingInformationsInProgress(state, yesOrNo) {
            state.shippingInformationsInProgress = yesOrNo
        },
        fetchInProgress(state, yesOrNo) {
            state.fetchInProgress = yesOrNo
        },
        wrongPasswordMessage(state, message) {
            state.wrongPasswordMessage = message
        },
        errorMessage(state, error) {
            state.errorMessage = error
        },
        setShippingInformation(state, shippingInformation) {
            state.shippingInformation = shippingInformation;
        },
        formatOneAddress(state) {
            let streetAddress = [];
            let streetArray = [];
            if (state.address.streetNoTwo == " " || state.address.streetNoTwo == undefined) {
                streetAddress[0] = state.address.streetNoOne;
                streetArray = streetAddress.filter(v => v != '');
            } else {
                streetAddress[0] = state.address.streetNoOne
                streetAddress[1] = state.address.streetNoTwo;
                streetArray = streetAddress.filter(v => v != '');
            }
            let formatedAddress = {
                "region_id": state.address.regionId,
                "country_id": state.address.countryId,
                "street": streetArray,
                "postcode": state.address.postcode,
                "city": state.address.city,
                "telephone": state.address.telephone,
                "firstname": state.address.firstname,
                "lastname": state.address.lastname,
            }
            state.oneAddress = formatedAddress;
        },
        paymentInProgress(state, yesOrNo) {
            state.paymentInProgress = yesOrNo
        },
        checkPOBoxAddress(state, address) {
            if (address && typeof (address) == "object") {
                let streetAddress = address.street ? address.street : [];
                if (!streetAddress.length && address.streetNoOne) {
                    streetAddress[0] = address.streetNoOne;
                    streetAddress[1] = address.streetNoTwo;
                }
                if (streetAddress && streetAddress.length) {
                    if (streetAddress[0]) {
                        if (invalidPoBoxAddress(streetAddress[0])) {
                            state.addressContainPOBox = true;
                        } else {
                            state.addressContainPOBox = false;
                        }
                    }
                    if (streetAddress[1]) {
                        if (invalidPoBoxAddress(streetAddress[1])) {
                            state.addressContainPOBox = true;
                        } else {
                            state.addressContainPOBox = false;
                        }
                    }
                }
            } else if (address && typeof (address) == "string") {
                if (invalidPoBoxAddress(address)) {
                    state.addressContainPOBox = true;
                } else {
                    state.addressContainPOBox = false;
                }
            }
        },

        setZinreloToken(state, token) {
            state.zinreloToken = token
        },
        setZinreloTokenCheck(state, flag) {
            state.zinreloTokenCheck = flag
        }
    },
    actions: {
        fetchStates: async ({ commit }) => {
            try {
                const countryAllStates = await doFetchStates();
                if (countryAllStates) {
                    commit('setCountryWiseStates', snakeToCamel(countryAllStates));
                }
            } catch (err) {
                commit('errorMessage', err.message);
            }
        },
        fetchCountries: async ({ commit }) => {
            try {
                const allCountries = await doFetchCountries();
                if (allCountries) {
                    commit('setAllCountries', snakeToCamel(allCountries));
                }
            } catch (err) {
                commit('errorMessage', err.message);
            }
        },
        basicInfo: async ({ state, commit, dispatch, rootState }, addressId) => {
            try {
                commit('inProgress', true);
                commit('fetchInProgress', true);
                const basicInfo = await doFetchBasicInfo();
                if (basicInfo) {
                    if (basicInfo.custom_attributes) {
                        if (rootState.layout.showZinreloRewards == "off") {
                            let featureRewards = basicInfo.custom_attributes.filter
                                ((attribute) => attribute.attribute_code == "feature_rewards")[0];

                            if (featureRewards && featureRewards.value == "1")
                                dispatch("layout/setShowZinreloRewards", "on", { root: true })
                            else
                                dispatch("layout/setShowZinreloRewards", "off", { root: true })

                        }
                    }

                    commit('setOne', snakeToCamel(basicInfo));

                    commit('setAddresses', { addresses: snakeToCamel(basicInfo.addresses), addressId: addressId });
                    listrakEmailCapture(basicInfo.email);
                    await dispatch('getZinreloToken');
                    await dispatch('layout/zinreloInit', { token: state.zinreloToken }, { root: true });

                }
                commit('inProgress', false);
                commit('fetchInProgress', false);

            } catch (err) {
                commit('inProgress', false);
                commit('fetchInProgress', false);

                commit('errorMessage', err.message);
            }
        },
        changePassword: async ({ commit }, { currentPassword, newPassword }) => {
            try {
                const changedPassword = await doFetchChangePassword(currentPassword, newPassword);
                commit('setChangePasswordFlag', changedPassword);
            } catch (err) {
                commit('errorMessage', err.message);
            }
        },
        updateAddress: async ({ state, commit }) => {
            try {
                commit('inProgress', true);
                commit('updateAddresses', state.address);
                commit('formatAddresses');
                const basicInfo = await doUpdateUserAddress(state.one, state.addresses, state.customerNumber);
                if (basicInfo) {

                    commit('inProgress', false);
                    commit('setOne', snakeToCamel(basicInfo));
                    commit('setAddresses', { addresses: snakeToCamel(basicInfo.addresses) });
                }
            } catch (err) {
                commit('errorMessage', err.message);
                commit('inProgress', false);
            }
        },
        updateSubscription: async ({ state, commit }) => {
            commit('inProgress', true);
            try {
                const basicInfo = await doUpdateSubscription(state.one, state.customerNumber);
                if (basicInfo) {
                    commit('inProgress', false);
                }
            } catch (err) {
                commit('inProgress', false);
                throw err;
            }
        },
        updatePassword: async ({ state, commit }, passwords) => {
            commit('inProgress', true);
            try {
                let customer = {
                    "firstname": one.firstname,
                    "lastname": one.lastname,
                    "email": one.email,
                    "website_id": one.websiteId,
                    "custom_attributes": []
                }
                if (passwords == undefined) {
                    customer.custom_attributes = [
                        {
                            "attribute_code": "customer_number",
                            "value": state.customerNumber
                        }]
                    const basicInfo = await doChangeBasicInfo(customer);
                    if (basicInfo) {
                        commit('inProgress', false);

                        commit('setOne', snakeToCamel(basicInfo));
                    }
                } else {
                    const changedPassword = await doChangePassword(passwords.current, passwords.new);
                    if (changedPassword) {
                        customer.custom_attributes = [
                            {
                                "attribute_code": "customer_number",
                                "value": state.customerNumber
                            }]
                        const basicInfo = await doChangeBasicInfo(customer);
                        commit('inProgress', false);
                        commit('setOne', snakeToCamel(basicInfo));
                        return ({ error: false });
                    } else {
                        commit('inProgress', false);
                        return ({ error: true });
                    }
                }
            } catch (err) {
                commit('errorMessage', err.message);
                commit('inProgress', false);
            }
        },
        changeBasicInfo: async ({ state, commit }, data) => {
            commit('inProgress', true);
            try {
                const basicInfo = await doChangeBasicInfo(data);
                commit('inProgress', false);

                commit('setOne', snakeToCamel(basicInfo));
            } catch (err) {
                commit('errorMessage', err.message);
                commit('inProgress', false);
            }
        },


        validateAddress: async ({ commit }, address) => {
            try {
                const validatedAddress = await doValidateAddress(address);
                return validatedAddress;
            } catch (err) {
                commit('errorMessage', err.message);
            }
        },
        validateAddressCheckout: async ({ commit }, { address, stateName }) => {
            commit('setSuggestedAddress', null);
            try {
                const validatedAddress = await doValidateAddressCheckout(address, stateName);
                return validatedAddress;
            } catch (err) {
                commit('errorMessage', err.message);
            }
        },
        setEstimatedShipping: async ({ state, commit }) => {
            commit('inProgress', true);
            try {
                const shippMethods = await doFetchEstimatedShippingMethods(state.one, state.address);
                commit('setEstimatedShipping', snakeToCamel(shippMethods));
                commit('inProgress', false);
            } catch (err) {
                throw err;
            }
        },
        setShippingInformation: async ({ state, commit }, method) => {
            commit('shippingInformationsInProgress', true);
            try {
                commit('checkPOBoxAddress', state.address);
                const shippingInformation = await doShippingInformation(state.one, state.address, method, state.suggestedAddress);
                commit('shippingInformationsInProgress', false);
                commit('setShippingInformation', snakeToCamel(shippingInformation));
                localStorage.setItem("cr_checkOutTab", 'reviewTab');
            } catch (err) {
                commit('shippingInformationsInProgress', false);
                throw err;
            }
        },
        setShippingAddress: async ({ state, commit }) => {
            commit('checkPOBoxAddress', state.address);
            localStorage.setItem("cr_" + state.one.id + "_userNewShippAddress", JSON.stringify(state.address));
            localStorage.setItem("cr_" + state.one.id + "_userNewShippAddressFlag", true);
            commit('setLocalShippAddress', state.address);
        },
        setLocalShippAddress: async ({ commit }, address) => {
            commit('setLocalShippAddress', address);
            commit('checkPOBoxAddress', address);
        },
        resetLocalShippAddressVar: async ({ commit }, val) => {
            commit('resetLocalShippAddressVar', val);
        },
        setSelectedShippAddressIndex: async ({ state, commit }, index) => {
            localStorage.setItem("cr_" + state.one.id + "_userSelectedShippAddressIndex", index);
            commit('setSelectedShippAddressIndex', index);
        },
        lastAddedAddressBookIndex: async ({ state, commit }, index) => {
            localStorage.setItem("cr_" + state.one.id + "_lastAddedAddressBookIndex", index);
            commit('lastAddedAddressBookIndex', index);
        },
        setLocalShippAddressFlag: async ({ state, commit }, flag) => {
            localStorage.setItem("cr_" + state.one.id + "_userNewShippAddressFlag", flag);
            commit('setLocalShippAddressFlag', flag);
        },
        setLocalBillAddress: async ({ state, commit }, address) => {
            localStorage.setItem("cr_" + state.one.id + "_userNewBillAddress", JSON.stringify(address));
            commit('setLocalBillAddress', address);
            commit('checkPOBoxAddress', address);
        },
        setAddress: async ({ commit }, address) => {
            commit('setAddress', address);
            commit('checkPOBoxAddress', address);
        },
        checkPOBoxAddress: async ({ commit }, address) => {
            commit('checkPOBoxAddress', address);
        },
        deleteAddress: async ({ state, commit }, address) => {
            commit('fetchInProgress', true);
            try {
                commit('deleteAddress', address);
                commit('formatAddresses');
                const basicInfo = await doUpdateUserAddress(state.one, state.addresses, state.customerNumber);
                if (basicInfo) {
                    commit('fetchInProgress', false);
                    commit('setOne', snakeToCamel(basicInfo));
                    commit('setAddresses', { addresses: snakeToCamel(basicInfo.addresses) });
                }
            } catch (err) {
                commit('fetchInProgress', false);
            }
        },
        setOrder: async ({ state, commit }, paymentMethod) => {
            try {
                commit('paymentInProgress', true);
                commit('checkPOBoxAddress', state.oneAddress);
                commit('formatOneAddress');
                const userQuote = await doFetchQuote();
                if (userQuote) {
                    let res = await doProductsOrder(userQuote, paymentMethod, state.oneAddress);
                    if (res) {
                        await doFetchQuote();
                    }
                    commit('paymentInProgress', false);
                    return res;
                }

            } catch (error) {
                commit('errorMessage', error.message);
                commit('paymentInProgress', false);
                throw error;
            }
        },
        setPaymentInProgress: async ({ commit }, flag) => {
            commit('paymentInProgress', flag);
        },
        setCCOrder: async ({ commit }, { orderNumber, shippingAddress, webOrderNumber, estimatedShipping }) => {
            try {
                await doProductsOrderOnCC(orderNumber, shippingAddress, webOrderNumber, estimatedShipping);
            } catch (error) {
                commit('errorMessage', error.message);
            }
        },
        placeCraneOrder: async ({ commit }, order) => {
            try {
                await doPlaceCraneOrder(order);
            } catch (error) {
                commit('errorMessage', error.message);
            }
        },
        fetchProductPdfs: async ({ commit }, { orderNumber, itemId }) => {
            try {
                let pdfArray = await doFetchProductPdfs(orderNumber, itemId);
                return snakeToCamel(pdfArray);
            } catch (error) {
                commit('errorMessage', error.message);
            }
        },

        setUserAddress: async ({ commit }, address) => {
            commit('setSuggestedAddress', address);
        },
        getZinreloToken: async ({ commit, state }) => {
            try {
                commit('setZinreloTokenCheck', false);
                let token = await doZinreloToken(state.one);
                commit('setZinreloToken', token.token);

                return token && token.token ? token.token : null
            } catch (error) {
                commit('setZinreloToken', null);
                commit('setZinreloTokenCheck', true);
                return null;
            }
        },
        getCustomerInfo: async ({ state, commit }) => {
            try {
                commit('inProgress', true);
                commit('fetchInProgress', true);
                const info = await doFetchBasicInfo();
                return info
            }catch(error){
                console.log("error in getting customer info",error)
                return null
            }
        },
        setFingerprintWithUserId: async ({ state, commit },basicData) => {
            try {
                
                const info = await dosetFingerprintWithUserId(basicData);
                 
                return info
            }catch(error){
                console.log("error in getting setFingerprintWithUserId info",error)
                return null
            }
        }
    }
}