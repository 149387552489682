import { doFetchOne, doPostOne, doPatchOne, doPatchItemOrderData } from '@/services/builder_session_item.service';
import { snakeToCamel, camelToSnake } from "@/services/util.service";

export const sessionItemOne = {
    namespaced: true,
    state: {
        one: {},
        inProgress: false
    },
    mutations: {
        setSessionItem(state, one) {
            state.one = one;
        },
        inProgress(state, yesOrNo) {
            state.inProgress = yesOrNo
        }
    },
    actions: {
        fetchOne: async ({ commit }, { itemId, sessionId, surface, surfaceType }) => {
            commit('inProgress', true);
            try {
                let response = await doFetchOne({ itemId, sessionId, surface, surfaceType });
                if(response && response.length){
                    commit('setSessionItem', snakeToCamel(response[0]));
                    return ({ response: snakeToCamel(response[0]) });
                } else if(response && response.length==0) {
                    return ({ response: null })
                } 
                commit('inProgress', false);
                return ({ response: null })
            }
            catch (err) {
                commit('inProgress', false);
                throw err;
            }
        },
        createItem: async ({ commit }, { item }) => {
            commit('inProgress', true);
            try {
                let response = await doPostOne({ item: camelToSnake(item) });
                commit('setSessionItem', snakeToCamel(response));
                commit('inProgress', false);
                return ({ response: snakeToCamel(response) });
            }
            catch (err) {
                commit('inProgress', false);
                throw err;
            }
        },
        updateItem: async ({ commit }, { sessionItemId, property }) => {
            commit('inProgress', true);
            try {
                let response = await doPatchOne({ sessionItemId, property: camelToSnake(property) });
                commit('setSessionItem', snakeToCamel(response));
                commit('inProgress', false);
                return ({ response: snakeToCamel(response) });
            }
            catch (err) {
                commit('inProgress', false);
                throw err;
            }
        },
        updateItemOrderData: async ({ commit }, { sessionId, productPartId, surfaceType,surfaceId, property }) => {
             
            commit('inProgress', true);
            try {
                let response = await doPatchItemOrderData({ sessionId, productPartId, surfaceType,surfaceId,  property: camelToSnake(property) });
                commit('setSessionItem', snakeToCamel(response));
                commit('inProgress', false);
                return ({ response: snakeToCamel(response) });
            }
            catch (err) {
                commit('inProgress', false);
                throw err;
            }
        },
    },

}
