import { doFetchList } from '@/services/builder_session_item.service';
import { snakeToCamel } from "@/services/util.service";

export const sessionItemList = {
    namespaced: true,
    state: {
        list: [],
        inProgress: false
    },
    mutations: {
        setSessionItemList(state, list) {
            state.list = list;
        },
        inProgress(state, yesOrNo) {
            state.inProgress = yesOrNo
        }
    },
    actions: {
        fetchList: async ({ commit }, { sessionId }) => {
            commit('inProgress', true);
            try {
                let response = await doFetchList({ sessionId });
                if(response && response.length){
                    commit('setSessionItemList', snakeToCamel(response));
                    return ({ response: snakeToCamel(response) });
                } else if(response && response.length==0) {
                    return ({ response: null })
                } 
                commit('inProgress', false);
                return ({ response: null })
            }
            catch (err) {
                commit('inProgress', false);
                throw err;
            }
        }
    },

}
