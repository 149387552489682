import { doFetchHeroContent } from '@/services/hero_content.service';
import { doFetchCmsList, doFetchPageList } from '@/services/cache.service';
import { getField, updateField } from 'vuex-map-fields';
import config from '@/config/index'
const { product } = config
import { snakeToCamel } from "@/services/util.service";
const partnerId = import.meta.env.VITE_ZINRELO_PARTNER_ID;
const zinreloRewards = import.meta.env.VITE_ZINRELO_REWARDS;
const zinreloRewardsShow = import.meta.env.VITE_ZINRELO_REWARDS_SHOW;

export const layout = {
    namespaced: true,
    state: {
        user: {
            userVisits: localStorage.getItem('cr_userVisitCount') ? localStorage.getItem('cr_userVisitCount') : 0
        },
        categoryQueryParam: {
            pageSize: '',
            pageNo: '',
            sortSelect: '',
            categoryId: '',
            search: '',
            sideFilter: ''
        },
        product,
        inProgress: false,
        heroContent: {},
        cmsBlockContent: {},
        snackbar: {
            show: false,
            message: null,
            color: null,
            timeout: 0
        },
        paymentCount: 1,
        pageContent: {},
        zinreloRewardsSet:localStorage.getItem(zinreloRewardsShow),
        showZinreloRewards:(zinreloRewards=="true")?(localStorage.getItem(zinreloRewardsShow) ? localStorage.getItem(zinreloRewardsShow) : "off"):"disabled"
    },
    getters: {
        getField,
    },
    mutations: {
        updateField,
        inProgress(state, yesOrNo) {
            state.inProgress = yesOrNo
        },
        setCategoryQueryParam(state, data) {
            state.categoryQueryParam = data;
        },
        setHeroContent(state, content) {
            state.heroContent = content
        },
        setCmsBlockContent(state, content) {
            state.cmsBlockContent = content
        },
        setPageContent(state, content) {
            state.pageContent = content
        },
        setSnackbar(state, obj) {
            state.snackbar = obj;
        },
        setPaymentCount(state, count) {
            state.paymentCount = count;
        },
        setShowZinreloRewards(state, value) {
            state.showZinreloRewards = value;
        },
    },
    actions: {
        updateCategoryQueryParam({ commit }, values) {
            commit('setCategoryQueryParam', values);
        },
        fetchHeroContent: async ({ commit }, categoryId) => {
            try {
                commit('inProgress', true);
                const heroContent = await doFetchHeroContent(categoryId);
                if (heroContent) {
                    commit('inProgress', false);
                    commit('setHeroContent', snakeToCamel(heroContent));
                }
                return snakeToCamel(heroContent);
            } catch (error) {
                commit('inProgress', false);
                throw error
            }
        },
        showSnackbar({ commit }, obj) {
            commit('setSnackbar', obj);
        },
        setHeroContent({ commit }, one) {
            commit('setHeroContent', snakeToCamel(one));
        },
        fetchCmsBlock: async ({ commit }) => {
            try {
                commit('inProgress', true);
                const blockContent = await doFetchCmsList();
                if (blockContent) {
                    commit('inProgress', false);
                    commit('setCmsBlockContent', snakeToCamel(blockContent));
                }
            } catch (error) {
                commit('inProgress', false);
                throw error
            }
        },
        fetchPageContent: async ({ commit }) => {
            try {
                commit('inProgress', true);
                const pageContent = await doFetchPageList();
                if (pageContent) {
                    commit('inProgress', false);
                    commit('setPageContent', snakeToCamel(pageContent));
                }
            } catch (error) {
                commit('inProgress', false);
                throw error
            }
        },
        setPaymentCount({ commit }, count) {
            commit('setPaymentCount', count);
        },
        zinreloInit({ state }, data) {
            if (state.showZinreloRewards && state.showZinreloRewards == "on") {
                window._zrl = window._zrl || [];
                let init_data = {
                    partner_id: partnerId,
                    jwt_token: data.token,
                    version: "v2",
                    server: "https://app.zinrelo.com",
                };
                if (window.zrl_mi) window.zrl_mi.is_tab_db_added = false;
                if (!window.zrl_mi.partner_id)
                    _zrl.push(["init", init_data]);
                else if (window.zrl_mi.zrl_conf_complete)
                    _zrl.push(["init", init_data]);
            }
        },
        setShowZinreloRewards({ commit }, value) {
            commit('setShowZinreloRewards', value);
        },
    }

};