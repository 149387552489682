import { authHeader } from './auth';
import { wrapRequest } from './nav';

const vueAppMagenotApiUrl=import.meta.env.VITE_MAGENTO_API_URL;

export const doFetchHeroContent= wrapRequest((blockId) => {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-type': 'application/json',...authHeader()
        },
    };
    return fetch(`${vueAppMagenotApiUrl}/rest/all/V1/crcategories/${blockId}`, requestOptions)
});
