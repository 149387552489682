import { doAddToSavedForLater } from '@/services/saved_for_later.service';
import { getField, updateField } from 'vuex-map-fields';
import { snakeToCamel } from "@/services/util.service";
export const savedForLaterOne = {
    namespaced: true,
    state: {
        inProgress: true,
        one: {},
    },
    getters: {
        getField
    },
    mutations: {
        updateField,

        inProgress(state, yesOrNo) {
            state.inProgress = yesOrNo
        },
        setSavedForLaterOne(state, one) {
            state.one = one
        },
    },
    actions: {
        addToSavedForLater: async ({ commit }, { sku }) => {
            try {
                commit('inProgress', true);
                let one = await doAddToSavedForLater({ sku });
                 
                if (one) {
                    commit('inProgress', false);
                    commit('setSavedForLaterOne', snakeToCamel(one));
                    return one
                }
            }
            catch (error) {
                commit('inProgress', false);
                throw error
            }
        },
    },

}
