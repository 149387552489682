import { doFetchProductDetails, doFetchProductsAttributes, doFetchConfiguredProductDetails, doFetchPartPrice, doPatchOne ,doFetchRewardsPoints} from '@/services/product.service';
import { getField, updateField } from 'vuex-map-fields';
import { snakeToCamel } from '@/services/util.service';


export const productOne = {
    namespaced: true,
    state: {
        fetchInProgress: true,
        configuredProductProgress: true,
        productDetails: {},
        productMedia: {},
        errorMessage: null,
        productAttributes: {},
        configuredProductDetails: {},
        fetchPriceInProgress: false,
        staticDataOnProduct: null,
        fetchRewardsPointsInProgress:false,
        rewardsPoints:0
    },
    getters: {
        getField
    },
    mutations: {
        updateField,
        fetchInProgress(state, yesOrNo) {
            state.fetchInProgress = yesOrNo
        },
        setProductsDetails(state, details) {
            state.productDetails = details;
        },
        setStaticDataOnProduct(state, details) {
            state.staticDataOnProduct = details
        },
        setProductsAttributes(state, list) {
            state.productAttributes = list
        },
        setErrorMessage(state, err) {
            state.errorMessage = err
        },
        setConfiguredProductDetails(state, details) {
            state.configuredProductDetails = details
        },
        configuredProductProgress(state, yesOrNo) {
            state.configuredProductProgress = yesOrNo
        },
        fetchPriceInProgress(state, yesOrNo) {
            state.fetchPriceInProgress = yesOrNo
        },
        rewardsPointsInProgress(state, yesOrNo) {
            state.fetchRewardsPointsInProgress = yesOrNo
        },
        setRewardsPoints(state, details) {

            if(details && details.data.potentialPoints)
            {
                state.rewardsPoints = details.data.potentialPoints
            }
            else{
                state.rewardsPoints = 0
            }
        },
        setResetReward(state, val) {
            state.rewardsPoints = val
        },
    },
    actions: {
        fetchProductDetails: async ({ commit, dispatch }, { sku, productId }) => {
            try {

                commit('setErrorMessage', null);
                if (productId) {

                    let fullCatalog = window.craneCatalog;
                    const product = fullCatalog.products[productId];

                    if (product) {
                        commit('setProductsDetails',
                            {
                                id: product.productId,
                                name: product.n,
                                sku: product.sku,
                                typeId: product.t,
                                description: product.d,
                                price: product.p,
                                mediaGalleryEntries: [{ mediaType: "image", file: product.i }],
                                customAttribute: [],
                            });
                        if (product.t == 'configurable') {

                            commit('setConfiguredProductDetails', {
                                'items': [{
                                    mediaGalleryEntries: [{ mediaType: "image", file: product.i }],
                                    customAttributes: []
                                }]
                            });
                        }
                    }

                }
                else {
                    commit('setStaticDataOnProduct', null);
                }
                if (sku) {

                    commit('fetchInProgress', true);
                    const productDetails = await doFetchProductDetails(sku);

                    if (productDetails) {
                        commit('setProductsDetails', snakeToCamel(productDetails[0]));
                        let productDetailsNew = snakeToCamel(productDetails[0]);
                        if (productDetailsNew && productDetailsNew.typeIid == 'configurable') {
                            dispatch('fetchConfiguredProductDetails', productDetailsNew.configurableProductLinks[0]);

                        }
                        commit('fetchInProgress', false);
                        return snakeToCamel(productDetails[0]);
                    }
                }
            } catch (err) {

                commit('fetchInProgress', false);
                commit('setErrorMessage', err.message);
            }
        },
        fetchProductAttribute: async ({ commit }, skuId) => {
            const productAttributes = await doFetchProductsAttributes(skuId);
            if (productAttributes) {
                commit('setProductsAttributes', snakeToCamel(productAttributes));
                return ({ productAttributes })
            }
        },
        fetchConfiguredProductDetails: async ({ commit }, skuId) => {
            try {

                commit('configuredProductProgress', true);
                const configuredProductDetails = await doFetchConfiguredProductDetails(skuId);
                if (configuredProductDetails) {

                    commit('setConfiguredProductDetails', snakeToCamel(configuredProductDetails));
                    commit('configuredProductProgress', false);
                }
            } catch (err) {
                commit('configuredProductProgress', false);
                commit('setErrorMessage', err.message);

            }
        },
        fetchPartPrice: async ({ commit }, orderObject) => {
            try {
                commit('fetchPriceInProgress', true);
                let price = await doFetchPartPrice({ order: orderObject });
                commit('fetchPriceInProgress', false);
                return price;
            } catch (err) {
                commit('fetchPriceInProgress', false);
                throw err;
            }
        },
        updateCatlog: async () => {
            try {
                await doPatchOne({ "element": "catalog", "requested_at": "" });
            }
            catch (err) {
                throw err;
            }
        },
        fetchRewardsPoints: async ({ state,commit,rootState }, productInfo) => {
            try {
                state.rewardsPoints = null
                if (rootState.layout.showZinreloRewards && rootState.layout.showZinreloRewards == "on") {
                    commit('rewardsPointsInProgress', true);
                    let rewardsPoints = await doFetchRewardsPoints(productInfo);
                    commit('setRewardsPoints', snakeToCamel(rewardsPoints));
                    commit('rewardsPointsInProgress', false);
                    return rewardsPoints;
                }

            } catch (err) {
                commit('rewardsPointsInProgress', false);
                commit('setRewardsPoints', null);
                throw err;
            }
        },
        resetReward: async ({ commit },) => {
            try {
                commit('setResetReward',0);

            } catch (err) {

                throw err;
            }
        },
    },

}