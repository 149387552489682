const vueAppMagenotApiUrl = import.meta.env.VITE_MAGENTO_API_URL;
import { wrapRequest } from './nav';
export const doEmailSubscriber = wrapRequest((email) => {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-type': 'application/json'
        },
    };
    return fetch(`${vueAppMagenotApiUrl}/rest/V1/newsletterapi/subscriber/${email}`, requestOptions)
});