import { doLogin } from '@/services/gate.service';
import { redirectToInterruptedPage } from '@/services/nav';
import { getAuthUser, setAuthUser, resetAuthUser } from '@/services/auth';
import { doUpdateSubscription } from '@/services/registration.service';
import { doFetchBasicInfo } from '@/services/user.service';
import { getField, updateField } from 'vuex-map-fields';
import { doPatchOne, doFetchUserImages } from '@/services/image.service';
import { doPatchOneBuilder, doFetchUserBuilderItemImages } from '@/services/builder_item.service';
const crStudioGuestId = import.meta.env.VITE_STUDIO_QUOTE_ID;
const partnerId = import.meta.env.VITE_ZINRELO_PARTNER_ID;

export const auth = {
    namespaced: true,
    state: {
        one: {},
        inProgress: false,
        userAuth: getAuthUser(),
        signInMessage: null,
        errorMessage: null,
        isRedirect: true
    },
    getters: {
        getField,
    },
    mutations: {
        updateField,
        setOne(state, one) {
            state.one = one;
        },
        inProgress(state, yesOrNo) {
            state.inProgress = yesOrNo;
        },
        loginSuccess(state, obj) {
            state.inProgress = false;
            state.errorMessage = null;
            state.userAuth = obj.token;
            setAuthUser(obj.token, obj.logged);
        },
        loginFailure(state, errorMessage) {
            state.inProgress = false;
            state.userAuth = null;
            state.errorMessage = errorMessage;
        },
        prepareWithMessage(state, message) {
            state.inProgress = false;
            state.userAuth = null;
            state.signInMessage = message;
        },
        logout(state) {
            state.inProgress = false;
            state.userAuth = null;
            resetAuthUser();
        },
        resetState(state) {
            state.inProgress = false;
            state.userAuth = getAuthUser();
            state.errorMessage = null;
            state.signInMessage = null;
        },
        changeRedirectFlag(state, flag) {
            state.isRedirect = flag;
        }
    },
    actions: {
        newUserLogin({ commit }) {
            commit('setOne', {
                "email": "",
                "password": "",
                "newsletter": "",
                "logged": false
            });
        },
        login: async ({ commit, state, dispatch, rootState }) => {
            try {
                commit('inProgress', true);
                const token = await doLogin(state.one);
                if (token) {
                    commit('loginSuccess', { token: token, logged: state.one.logged });
                    dispatch('userCartProduct/checkUserToken', {}, { root: true });
                    dispatch('getUserImages', state.one.email);

                    try {
                        let zinreloRewardsSet = rootState.layout.zinreloRewardsSet;
                        if (zinreloRewardsSet && zinreloRewardsSet == 'on') {
                            const basicInfo = await dispatch("accountOne/getCustomerInfo", {}, { root: true })
                            let customer = {
                                "email": basicInfo.email,
                                "firstname": basicInfo.firstname,
                                "lastname": basicInfo.lastname,
                                "custom_attributes": [
                                    {
                                        "attribute_code": "feature_rewards",
                                        "value": "1"
                                    }]

                            }
                            await dispatch("accountOne/changeBasicInfo", customer, { root: true })
                        }
                    }
                    catch (error) {
                        console.error("error setting feature_rewards", error)
                    }


                    console.log(dispatch);
                    if (state.one.newsletter) {
                        await doUpdateSubscription(state.one.result);
                    }
                    if (state.isRedirect)
                        redirectToInterruptedPage();
                    return true;
                }
            } catch (err) {
                commit('inProgress', false);
                commit('prepareWithMessage');
                commit('loginFailure', err.message);
                setTimeout(() => {
                    commit('loginFailure', null);
                }, 3000);
            }
        },
        logout({ commit, dispatch }) {
            commit('logout');
            dispatch('layout/zinreloInit', {}, { root: true });
        },
        prepare({ commit }, message) {
            commit('loginFailure');
            commit('prepareWithMessage', message);
        },
        resetState({ commit }) {
            commit('resetState');
        },
        changeRedirectFlag({ commit }, flag) {
            commit('changeRedirectFlag', flag);
        },
        setOne({ commit }, one) {
            commit('setOne', one);
        },
        getUserImages: async () => {
            let guestQuoteId = localStorage.getItem(crStudioGuestId);
            if (guestQuoteId) {
                let userInfo = await doFetchBasicInfo();
                let userId = userInfo.id;
                let guestImages = await doFetchUserImages(guestQuoteId);
                if (guestImages.length) {
                    for (let i = 0; i < guestImages.length; i++) {
                        let imgObj = guestImages[i];
                        imgObj.owner_id = userId;
                        await doPatchOne(imgObj);
                    }
                }
                let guestBuilderImages = await doFetchUserBuilderItemImages(guestQuoteId);
                if (guestBuilderImages.length) {
                    for (let i = 0; i < guestBuilderImages.length; i++) {
                        let imgObj = guestBuilderImages[i];
                        imgObj.owner_id = userId;
                        await doPatchOneBuilder(imgObj);
                    }
                }
                localStorage.removeItem(crStudioGuestId);
            }
        },
    },
};