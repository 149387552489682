import { createApp } from 'vue'
import VueCookies from 'vue3-cookies';
import VueSmoothScroll from 'vue3-smooth-scroll'
import App from './App.vue'
import vuetify from './plugins'

import router from './multipages'
import store from './stores';
const gaId = import.meta.env.VITE_GA_ID;
const fingerprintAPIKey = import.meta.env.VITE_FINGERPRINT_API_KEY;

import * as VueGoogleMaps from "vue3-google-map"
import { createMetaManager } from "vue-meta";
import { plugin as vueMetaPlugin } from "vue-meta";
import {
    fpjsPlugin
} from '@fingerprintjs/fingerprintjs-pro-vue-v3';

const app = createApp(App)
app.use(vuetify)
app.use(VueSmoothScroll)
app.use(VueCookies);
app.use(VueGoogleMaps, {
    load: { key: 'AIzaSyDmHp0PPuKHD1KGHCDmLq1qQKr_03XKEHo', libraries: ['places', 'geometry'] }
});

app.use(fpjsPlugin, {
    loadOptions: {
        apiKey: fingerprintAPIKey
    },
})

app.config.productionTip = false
app.use(store)
app.use(router)
app.use(vueMetaPlugin);
app.use(createMetaManager(false, {
    description: {
        tag: "meta",
    },
    titleTemplate: {
        tag: "title",
    },
    title: {
        tag: "meta",
    },
    robots: {
        tag: "meta",
    },
    keywords: {
        tag: "meta",
    }
})
)
app.mount('#app')