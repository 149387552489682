import {
    doFetchQuote,
    doFetchCartProducts,
    doDeleteCartProduct,
    doPutCartItem,
    doPostGiftCartItem
} from '@/services/gift_cart.service';
import { getField, updateField } from 'vuex-map-fields';
import { getCookieByName, setCookieByName } from '@/services/auth';
import { listrakCartListClear, snakeToCamel } from "@/services/util.service";
const crGuestQuoteId = import.meta.env.VITE_GUEST_QUOTE_ID;

export const giftCartProducts = {
    namespaced: true,
    state: {
        inProgress: false,
        fetchInProgress: false,
        cartProduct: [],
        cartProductList: [],
        productTotalAmt: [],
        giftCartDetails: {
            "senderName": "",
            "senderEmail": "",
            "recipientName": "",
            "recipientEmail": "",
            "senderMessage": "",
            "giftCardQty": "",
            "giftCardAmt": ""
        }
    },
    getters: {
        getField
    },
    mutations: {
        updateField,

        inProgress(state, yesOrNo) {
            state.inProgress = yesOrNo
        },
        fetchInProgress(state, yesOrNo) {
            state.fetchInProgress = yesOrNo
        },
        setCartProduct(state, product) {
            state.cartProduct = product
        },
        setCartProductList(state, list) {
            state.cartProductList = list
        },
        setListrakCartListClear(){
            listrakCartListClear()
        },
    },
    actions: {
        addToGiftCart: async({ commit, dispatch, state}, data) => {
            /* eslint-disable */
            
            try {
                commit('inProgress', true);
                let guestQuoteId = getCookieByName(crGuestQuoteId);
                if (!guestQuoteId) {
                    const guestQuote = await doFetchQuote();
                    setCookieByName(crGuestQuoteId, guestQuote)
                    const cartProduct = await doPostGiftCartItem(data, guestQuote,state.giftCartDetails);
                    if (cartProduct) {
                        commit('setCartProduct', (cartProduct));
                        dispatch('cartProductList', guestQuote);
                        dispatch('productTotalAmt', guestQuote);
                    }
                } else {
                    const cartProduct = await doPostGiftCartItem(data, guestQuoteId, state.giftCartDetails);
                    if (cartProduct) {
                        commit('setCartProduct', (cartProduct));
                        dispatch('cartProductList', guestQuoteId);
                        dispatch('productTotalAmt', guestQuoteId);
                    }
                }
                commit('inProgress', false);
                commit('setCartDrawer', true);
            } catch (err) {
                commit('inProgress', false);
                throw err;
            }
        },
    
        cartProductList: async({ commit },guestQuoteId) => {
            doFetchCartProducts(guestQuoteId).then((cartProductList) => commit('setCartProductList', snakeToCamel(cartProductList)))
                .catch((error) => error)

        },
        removeCartProduct: async({ commit, dispatch }, { productId }) => {
            try {
                commit('fetchInProgress', true);

                let trueOrFalse = await doDeleteCartProduct(productId);

                if (trueOrFalse) {
                   await dispatch('cartProductList');
                }
                commit('fetchInProgress', false);
            } catch (err) {
                commit('fetchInProgress', false);
                throw err;
            }
        },
        updateToCart: async({ commit, dispatch }, data) => {

            try {
                commit('inProgress', true);

                const cartProduct = await doPutCartItem(data.sku, data.qty, data.userQuote, data.itemId);

                if (cartProduct) {
                    commit('setCartProduct', (cartProduct));
                    dispatch('cartProductList');
                }
                commit('inProgress', false);
            } catch (err) {
                commit('inProgress', false);
                throw err;
            }

        },
        setListrakCartListClear: async({ commit,state }) => {
            if(state.cartProductList.length <= 0)
            {
                commit('setListrakCartListClear');
            }
        },
    }
}