<template>
  <v-container class="py-0 pa-sm-3 background-white px-0">
    <v-row no-gutters justify="center" class="pa-0 mt-md-12 mt-sm-10 mb-5">
      <div class="hr-line-100px"></div>
    </v-row>
    <!-----------  mobile view -------->
    <v-content align="center" class="pa-0 mt-md-5 mt-5 mb-4 d-sm-none ls-n01">
      <p
        class="text-uppercase fontsize-28 regular-font mx-2 px-2 mb-0 text-center line-height-36"
      >Related</p>
      <p
        class="text-uppercase fontsize-28 regular-font mx-2 px-2 mb-0 text-center line-height-36"
      >Products</p>
    </v-content>
    <!-----------  mobile view end -------->
    <v-content align="center" class="pa-0 mt-md-5 mt-5 d-none d-sm-block ls-n01">
      <p
        class="text-uppercase fontsize-28 mt-5 mb-9 mx-2 px-2 text-center line-height-36 regular-font"
      >Related Products</p>
    </v-content>
    <v-row no-gutters>
       <v-container class="pa-0 pa-sm-3 pt-10">
        <v-col lg="12" md="12" cols="12" sm="12" class="pa-0 pa-sm-3">
          <vueper-slides
             class="no-shadow mb-0  related-products-silder"
            :visible-slides="perSlides"
            :dragging-distance="1"
            :fixed-height="slideHeight"
            :touchable="false"
          >
            <vueper-slide v-for="(products, index) in relatedProducts" v-bind:key="index">
              <template v-slot:content>
                <div class="vueperslide__content-wrapper vueperslide__title">
                  <v-card
                    flat
                    class=" 
                      vueperslide__title
                      px-sm-4
                      py-3
                      cursor-pointer"
                    @click="OpenProductDetails(products.name)"
                    rounded="0" 
                  >
                    <picture>
                        <source :media="`(min-width:100px)`" :srcset="mediaImageUrl+'/' +fetchProductImageName(products.image[0].file) +'_360.webp'"  style="width:100%">
                        <img :src="mediaImageUrl+'/' +fetchProductImageName(products.image[0].file) +'.webp'"   style="width:100%"
                        :alt="products.name"
                        :title="products.name" onerror="this.onerror=null;this.parentNode.children[0].srcset=this.parentNode.children[1].srcset=this.parentNode.children[2].srcset='https://cdn.crane.com/stg-images/cc12011-1.webp';">
                    </picture>
                  
                    <v-row no-gutters class="pt-0 pb-5">
                      <v-col class="pb-0" cols="6">
                        <h1
                          class="font-weight-300 text-left mb-3 fontsize-14 regular-font relatedproduct-fcolor-black-darken-2 ls-n009 word_break"
                        >{{products.name}}</h1>
                      </v-col>
                   
                      <v-col class="py-0" cols="12">
                        <p
                          class="mb-3 text-left fontsize-14 body-font ls-n009 word_break fontcolor-grey-darken"
                        >{{products.unitDiscription}}</p>
                      </v-col>
                     
                    </v-row>
                  </v-card>
                </div>
              </template>
            </vueper-slide>
          </vueper-slides>
        </v-col>
      </v-container>
    </v-row>
  </v-container>
</template>
 <script>
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
const mediaImageUrl = import.meta.env.VITE_APP_MEDIA_IMAGES_API_URL;

 export default {
  name: "RelatedProductsComponent",
  props: ["relatedProducts"],
  components: {
    VueperSlides,
    VueperSlide
  },
  computed: {
    imageBasePathUrl() {
     return import.meta.env.VITE_IMAGE_PATH_URL;
    },
      perSlides: function () {
      if (this.$vuetify.display.xs) return 1;
      else if (this.$vuetify.display.sm) return 3;
      else return this.$vuetify.display.md ? 4 : 4;
    },
    slideHeight: function () {
      return this.$vuetify.display.xsOnly ? "400px" : "350px";
    },
    showArrows: function () {
      if (this.$vuetify.display.smAndDown) return false;
      else return true;
    },
    imageMaxHeight: function () {
      if (this.$vuetify.display.xsOnly) return "250px";
      else return "300px";
    },
  },

  data() {
    return {
      mediaImageUrl
    };
  },
  methods: {
    fetchProductImageName(imageFullName) {
      let fileExtention = imageFullName.split("/");
      let filename = fileExtention[fileExtention.length - 1].split(".")[0];
      return filename;
    },
    OpenProductDetails(productsName) {
      this.$router.push({
        name: productsName,
      })
        .catch(error => {
          error;
        });
    }
  }
};
</script>
<style src="./RelatedProductsComponent.scss" lang="scss" scopped/>